export const FRAGILE_ZIP_CODES = new Set([
	'33001',
	'33002',
	'33004',
	'33008',
	'33009',
	'33010',
	'33011',
	'33012',
	'33013',
	'33014',
	'33015',
	'33016',
	'33017',
	'33018',
	'33019',
	'33020',
	'33021',
	'33022',
	'33023',
	'33024',
	'33025',
	'33026',
	'33027',
	'33028',
	'33029',
	'33030',
	'33031',
	'33032',
	'33033',
	'33034',
	'33035',
	'33036',
	'33037',
	'33039',
	'33040',
	'33041',
	'33042',
	'33043',
	'33045',
	'33050',
	'33051',
	'33052',
	'33054',
	'33055',
	'33056',
	'33060',
	'33061',
	'33062',
	'33063',
	'33064',
	'33065',
	'33066',
	'33067',
	'33068',
	'33069',
	'33070',
	'33071',
	'33072',
	'33073',
	'33074',
	'33075',
	'33076',
	'33077',
	'33081',
	'33082',
	'33083',
	'33084',
	'33090',
	'33092',
	'33093',
	'33097',
	'33101',
	'33102',
	'33107',
	'33109',
	'33110',
	'33111',
	'33112',
	'33114',
	'33116',
	'33119',
	'33121',
	'33122',
	'33124',
	'33125',
	'33126',
	'33127',
	'33128',
	'33129',
	'33130',
	'33131',
	'33132',
	'33133',
	'33134',
	'33135',
	'33136',
	'33137',
	'33138',
	'33139',
	'33140',
	'33141',
	'33142',
	'33143',
	'33144',
	'33145',
	'33146',
	'33147',
	'33148',
	'33149',
	'33150',
	'33151',
	'33152',
	'33153',
	'33154',
	'33155',
	'33156',
	'33157',
	'33158',
	'33159',
	'33160',
	'33161',
	'33162',
	'33163',
	'33164',
	'33165',
	'33166',
	'33167',
	'33168',
	'33169',
	'33170',
	'33172',
	'33173',
	'33174',
	'33175',
	'33176',
	'33177',
	'33178',
	'33179',
	'33180',
	'33181',
	'33182',
	'33183',
	'33184',
	'33185',
	'33186',
	'33187',
	'33188',
	'33189',
	'33190',
	'33193',
	'33194',
	'33195',
	'33196',
	'33197',
	'33199',
	'33222',
	'33231',
	'33233',
	'33234',
	'33238',
	'33239',
	'33242',
	'33243',
	'33245',
	'33247',
	'33255',
	'33256',
	'33257',
	'33261',
	'33265',
	'33266',
	'33269',
	'33280',
	'33283',
	'33296',
	'33299',
	'33301',
	'33302',
	'33303',
	'33304',
	'33305',
	'33306',
	'33307',
	'33308',
	'33309',
	'33310',
	'33311',
	'33312',
	'33313',
	'33314',
	'33315',
	'33316',
	'33317',
	'33318',
	'33319',
	'33320',
	'33321',
	'33322',
	'33323',
	'33324',
	'33325',
	'33326',
	'33327',
	'33328',
	'33329',
	'33330',
	'33331',
	'33332',
	'33334',
	'33335',
	'33336',
	'33337',
	'33338',
	'33339',
	'33340',
	'33345',
	'33346',
	'33348',
	'33349',
	'33351',
	'33355',
	'33359',
	'33388',
	'33394',
	'33441',
	'33442',
	'33443',
	'34141',
	'73301',
	'73344',
	'76527',
	'76530',
	'76537',
	'76573',
	'76574',
	'76578',
	'76820',
	'76831',
	'76842',
	'76856',
	'76869',
	'76885',
	'77853',
	'78602',
	'78605',
	'78606',
	'78607',
	'78608',
	'78609',
	'78610',
	'78611',
	'78612',
	'78613',
	'78615',
	'78616',
	'78617',
	'78618',
	'78619',
	'78620',
	'78621',
	'78622',
	'78624',
	'78626',
	'78627',
	'78628',
	'78630',
	'78631',
	'78633',
	'78634',
	'78635',
	'78636',
	'78639',
	'78640',
	'78641',
	'78642',
	'78643',
	'78644',
	'78645',
	'78646',
	'78648',
	'78650',
	'78651',
	'78652',
	'78653',
	'78654',
	'78655',
	'78656',
	'78657',
	'78659',
	'78660',
	'78661',
	'78662',
	'78663',
	'78664',
	'78665',
	'78666',
	'78667',
	'78669',
	'78671',
	'78672',
	'78673',
	'78674',
	'78675',
	'78676',
	'78680',
	'78681',
	'78682',
	'78683',
	'78691',
	'78701',
	'78702',
	'78703',
	'78704',
	'78705',
	'78708',
	'78709',
	'78710',
	'78711',
	'78712',
	'78713',
	'78714',
	'78715',
	'78716',
	'78717',
	'78718',
	'78719',
	'78720',
	'78721',
	'78722',
	'78723',
	'78724',
	'78725',
	'78726',
	'78727',
	'78728',
	'78729',
	'78730',
	'78731',
	'78732',
	'78733',
	'78734',
	'78735',
	'78736',
	'78737',
	'78738',
	'78739',
	'78741',
	'78742',
	'78744',
	'78745',
	'78746',
	'78747',
	'78748',
	'78749',
	'78750',
	'78751',
	'78752',
	'78753',
	'78754',
	'78755',
	'78756',
	'78757',
	'78758',
	'78759',
	'78760',
	'78761',
	'78762',
	'78763',
	'78764',
	'78765',
	'78766',
	'78767',
	'78768',
	'78772',
	'78773',
	'78774',
	'78778',
	'78779',
	'78780',
	'78781',
	'78783',
	'78785',
	'78786',
	'78788',
	'78789',
	'78798',
	'78799',
	'78932',
	'78938',
	'78940',
	'78941',
	'78942',
	'78945',
	'78946',
	'78947',
	'78948',
	'78949',
	'78952',
	'78953',
	'78954',
	'78956',
	'78957',
	'78960',
	'78961',
	'78963',
	'46301',
	'46302',
	'46303',
	'46304',
	'46307',
	'46308',
	'46310',
	'46311',
	'46312',
	'46319',
	'46320',
	'46321',
	'46322',
	'46323',
	'46324',
	'46325',
	'46327',
	'46340',
	'46341',
	'46342',
	'46345',
	'46346',
	'46347',
	'46348',
	'46349',
	'46350',
	'46352',
	'46355',
	'46356',
	'46360',
	'46361',
	'46365',
	'46368',
	'46371',
	'46372',
	'46373',
	'46375',
	'46376',
	'46377',
	'46379',
	'46380',
	'46381',
	'46382',
	'46383',
	'46384',
	'46385',
	'46390',
	'46391',
	'46392',
	'46393',
	'46394',
	'46401',
	'46402',
	'46403',
	'46404',
	'46405',
	'46406',
	'46407',
	'46408',
	'46409',
	'46410',
	'46411',
	'47922',
	'47943',
	'47948',
	'47951',
	'47963',
	'47964',
	'47977',
	'47978',
	'60001',
	'60002',
	'60004',
	'60005',
	'60006',
	'60007',
	'60008',
	'60009',
	'60010',
	'60011',
	'60012',
	'60013',
	'60014',
	'60015',
	'60016',
	'60017',
	'60018',
	'60019',
	'60020',
	'60021',
	'60022',
	'60025',
	'60026',
	'60029',
	'60030',
	'60031',
	'60033',
	'60034',
	'60035',
	'60037',
	'60038',
	'60039',
	'60040',
	'60041',
	'60042',
	'60043',
	'60044',
	'60045',
	'60046',
	'60047',
	'60048',
	'60049',
	'60050',
	'60051',
	'60053',
	'60055',
	'60056',
	'60060',
	'60061',
	'60062',
	'60064',
	'60065',
	'60067',
	'60068',
	'60069',
	'60070',
	'60071',
	'60072',
	'60073',
	'60074',
	'60075',
	'60076',
	'60077',
	'60078',
	'60079',
	'60081',
	'60082',
	'60083',
	'60084',
	'60085',
	'60086',
	'60087',
	'60088',
	'60089',
	'60090',
	'60091',
	'60093',
	'60094',
	'60095',
	'60096',
	'60097',
	'60098',
	'60099',
	'60101',
	'60102',
	'60103',
	'60104',
	'60105',
	'60106',
	'60107',
	'60108',
	'60109',
	'60110',
	'60111',
	'60112',
	'60115',
	'60116',
	'60117',
	'60118',
	'60119',
	'60120',
	'60121',
	'60122',
	'60123',
	'60124',
	'60125',
	'60126',
	'60128',
	'60129',
	'60130',
	'60131',
	'60132',
	'60133',
	'60134',
	'60135',
	'60136',
	'60137',
	'60138',
	'60139',
	'60140',
	'60141',
	'60142',
	'60143',
	'60144',
	'60145',
	'60146',
	'60147',
	'60148',
	'60150',
	'60151',
	'60152',
	'60153',
	'60154',
	'60155',
	'60156',
	'60157',
	'60159',
	'60160',
	'60161',
	'60162',
	'60163',
	'60164',
	'60165',
	'60168',
	'60169',
	'60170',
	'60171',
	'60172',
	'60173',
	'60174',
	'60175',
	'60176',
	'60177',
	'60178',
	'60179',
	'60180',
	'60181',
	'60183',
	'60184',
	'60185',
	'60186',
	'60187',
	'60188',
	'60189',
	'60190',
	'60191',
	'60192',
	'60193',
	'60194',
	'60195',
	'60196',
	'60197',
	'60199',
	'60201',
	'60202',
	'60203',
	'60204',
	'60208',
	'60209',
	'60290',
	'60301',
	'60302',
	'60303',
	'60304',
	'60305',
	'60399',
	'60401',
	'60402',
	'60403',
	'60404',
	'60406',
	'60407',
	'60408',
	'60409',
	'60410',
	'60411',
	'60412',
	'60415',
	'60416',
	'60417',
	'60419',
	'60421',
	'60422',
	'60423',
	'60424',
	'60425',
	'60426',
	'60428',
	'60429',
	'60430',
	'60431',
	'60432',
	'60433',
	'60434',
	'60435',
	'60436',
	'60437',
	'60438',
	'60439',
	'60440',
	'60441',
	'60442',
	'60443',
	'60444',
	'60445',
	'60446',
	'60447',
	'60448',
	'60449',
	'60450',
	'60451',
	'60452',
	'60453',
	'60454',
	'60455',
	'60456',
	'60457',
	'60458',
	'60459',
	'60461',
	'60462',
	'60463',
	'60464',
	'60465',
	'60466',
	'60467',
	'60468',
	'60469',
	'60470',
	'60471',
	'60472',
	'60473',
	'60474',
	'60475',
	'60476',
	'60477',
	'60478',
	'60479',
	'60480',
	'60481',
	'60482',
	'60484',
	'60487',
	'60490',
	'60491',
	'60499',
	'60501',
	'60502',
	'60503',
	'60504',
	'60505',
	'60506',
	'60507',
	'60510',
	'60511',
	'60512',
	'60513',
	'60514',
	'60515',
	'60516',
	'60517',
	'60518',
	'60519',
	'60520',
	'60521',
	'60522',
	'60523',
	'60525',
	'60526',
	'60527',
	'60531',
	'60532',
	'60534',
	'60536',
	'60537',
	'60538',
	'60539',
	'60540',
	'60541',
	'60542',
	'60543',
	'60544',
	'60545',
	'60546',
	'60548',
	'60549',
	'60550',
	'60551',
	'60552',
	'60554',
	'60555',
	'60556',
	'60557',
	'60558',
	'60559',
	'60560',
	'60561',
	'60563',
	'60564',
	'60565',
	'60566',
	'60567',
	'60568',
	'60570',
	'60572',
	'60585',
	'60586',
	'60597',
	'60598',
	'60601',
	'60602',
	'60603',
	'60604',
	'60605',
	'60606',
	'60607',
	'60608',
	'60609',
	'60610',
	'60611',
	'60612',
	'60613',
	'60614',
	'60615',
	'60616',
	'60617',
	'60618',
	'60619',
	'60620',
	'60621',
	'60622',
	'60623',
	'60624',
	'60625',
	'60626',
	'60628',
	'60629',
	'60630',
	'60631',
	'60632',
	'60633',
	'60634',
	'60636',
	'60637',
	'60638',
	'60639',
	'60640',
	'60641',
	'60642',
	'60643',
	'60644',
	'60645',
	'60646',
	'60647',
	'60649',
	'60651',
	'60652',
	'60653',
	'60654',
	'60655',
	'60656',
	'60657',
	'60659',
	'60660',
	'60661',
	'60663',
	'60664',
	'60666',
	'60668',
	'60669',
	'60670',
	'60673',
	'60674',
	'60675',
	'60677',
	'60678',
	'60679',
	'60680',
	'60681',
	'60682',
	'60684',
	'60685',
	'60686',
	'60687',
	'60688',
	'60689',
	'60690',
	'60691',
	'60693',
	'60694',
	'60695',
	'60696',
	'60697',
	'60699',
	'60701',
	'60706',
	'60707',
	'60712',
	'60714',
	'60803',
	'60804',
	'60805',
	'60827',
	'60901',
	'60910',
	'60913',
	'60914',
	'60915',
	'60917',
	'60935',
	'60940',
	'60941',
	'60944',
	'60950',
	'60954',
	'60958',
	'60961',
	'60964',
	'60969',
	'61301',
	'61316',
	'61321',
	'61325',
	'61332',
	'61334',
	'61341',
	'61342',
	'61348',
	'61350',
	'61354',
	'61358',
	'61360',
	'61364',
	'61370',
	'61371',
	'61372',
	'61373',
	'85001',
	'85002',
	'85003',
	'85004',
	'85005',
	'85006',
	'85007',
	'85008',
	'85009',
	'85010',
	'85011',
	'85012',
	'85013',
	'85014',
	'85015',
	'85016',
	'85017',
	'85018',
	'85019',
	'85020',
	'85021',
	'85022',
	'85023',
	'85024',
	'85025',
	'85026',
	'85027',
	'85028',
	'85029',
	'85030',
	'85031',
	'85032',
	'85033',
	'85034',
	'85035',
	'85036',
	'85037',
	'85038',
	'85039',
	'85040',
	'85041',
	'85042',
	'85043',
	'85044',
	'85045',
	'85046',
	'85048',
	'85050',
	'85051',
	'85053',
	'85054',
	'85060',
	'85061',
	'85062',
	'85063',
	'85064',
	'85066',
	'85067',
	'85068',
	'85069',
	'85070',
	'85071',
	'85072',
	'85074',
	'85075',
	'85076',
	'85077',
	'85078',
	'85079',
	'85080',
	'85082',
	'85083',
	'85085',
	'85086',
	'85087',
	'85097',
	'85098',
	'85099',
	'85117',
	'85118',
	'85119',
	'85120',
	'85121',
	'85122',
	'85123',
	'85127',
	'85128',
	'85130',
	'85131',
	'85132',
	'85135',
	'85137',
	'85138',
	'85139',
	'85140',
	'85141',
	'85142',
	'85143',
	'85145',
	'85147',
	'85172',
	'85173',
	'85178',
	'85190',
	'85191',
	'85192',
	'85193',
	'85194',
	'85201',
	'85202',
	'85203',
	'85204',
	'85205',
	'85206',
	'85207',
	'85208',
	'85209',
	'85210',
	'85211',
	'85212',
	'85213',
	'85214',
	'85215',
	'85216',
	'85217',
	'85218',
	'85219',
	'85220',
	'85221',
	'85222',
	'85223',
	'85224',
	'85225',
	'85226',
	'85227',
	'85228',
	'85230',
	'85231',
	'85232',
	'85233',
	'85234',
	'85235',
	'85236',
	'85237',
	'85238',
	'85239',
	'85240',
	'85241',
	'85242',
	'85243',
	'85244',
	'85245',
	'85246',
	'85247',
	'85248',
	'85249',
	'85250',
	'85251',
	'85252',
	'85253',
	'85254',
	'85255',
	'85256',
	'85257',
	'85258',
	'85259',
	'85260',
	'85261',
	'85262',
	'85263',
	'85264',
	'85266',
	'85267',
	'85268',
	'85269',
	'85271',
	'85272',
	'85273',
	'85274',
	'85275',
	'85277',
	'85278',
	'85280',
	'85281',
	'85282',
	'85283',
	'85284',
	'85285',
	'85286',
	'85287',
	'85289',
	'85290',
	'85291',
	'85292',
	'85293',
	'85294',
	'85295',
	'85296',
	'85297',
	'85298',
	'85299',
	'85301',
	'85302',
	'85303',
	'85304',
	'85305',
	'85306',
	'85307',
	'85308',
	'85309',
	'85310',
	'85311',
	'85312',
	'85313',
	'85318',
	'85320',
	'85322',
	'85323',
	'85324',
	'85325',
	'85326',
	'85327',
	'85328',
	'85329',
	'85331',
	'85332',
	'85334',
	'85335',
	'85337',
	'85338',
	'85339',
	'85340',
	'85342',
	'85343',
	'85344',
	'85345',
	'85346',
	'85348',
	'85351',
	'85353',
	'85354',
	'85355',
	'85357',
	'85358',
	'85359',
	'85360',
	'85361',
	'85362',
	'85363',
	'85371',
	'85372',
	'85373',
	'85374',
	'85375',
	'85376',
	'85377',
	'85378',
	'85379',
	'85380',
	'85381',
	'85382',
	'85383',
	'85385',
	'85387',
	'85388',
	'85390',
	'85392',
	'85395',
	'85396',
	'85501',
	'85502',
	'85530',
	'85531',
	'85532',
	'85533',
	'85534',
	'85535',
	'85536',
	'85539',
	'85540',
	'85541',
	'85542',
	'85543',
	'85544',
	'85545',
	'85546',
	'85547',
	'85548',
	'85550',
	'85551',
	'85552',
	'85553',
	'85554',
	'85618',
	'85623',
	'85631',
	'85901',
	'85902',
	'85911',
	'85912',
	'85922',
	'85923',
	'85926',
	'85928',
	'85929',
	'85931',
	'85933',
	'85934',
	'85935',
	'85937',
	'85939',
	'85941',
	'85942',
	'86001',
	'86002',
	'86003',
	'86004',
	'86011',
	'86015',
	'86016',
	'86017',
	'86018',
	'86020',
	'86021',
	'86022',
	'86023',
	'86024',
	'86025',
	'86029',
	'86030',
	'86031',
	'86032',
	'86033',
	'86034',
	'86035',
	'86036',
	'86038',
	'86039',
	'86040',
	'86042',
	'86043',
	'86044',
	'86045',
	'86046',
	'86047',
	'86052',
	'86053',
	'86054',
	'86301',
	'86302',
	'86303',
	'86304',
	'86305',
	'86312',
	'86313',
	'86314',
	'86315',
	'86320',
	'86321',
	'86322',
	'86323',
	'86324',
	'86325',
	'86326',
	'86327',
	'86329',
	'86330',
	'86331',
	'86332',
	'86333',
	'86334',
	'86335',
	'86336',
	'86337',
	'86338',
	'86339',
	'86340',
	'86341',
	'86342',
	'86343',
	'86351',
	'86401',
	'86402',
	'86403',
	'86404',
	'86405',
	'86406',
	'86409',
	'86411',
	'86412',
	'86413',
	'86426',
	'86427',
	'86429',
	'86430',
	'86431',
	'86432',
	'86433',
	'86434',
	'86435',
	'86436',
	'86437',
	'86438',
	'86439',
	'86440',
	'86441',
	'86442',
	'86443',
	'86444',
	'86445',
	'86446',
	'86510',
	'86520',
	'08001',
	'08002',
	'08003',
	'08004',
	'08007',
	'08009',
	'08010',
	'08011',
	'08012',
	'08014',
	'08015',
	'08016',
	'08018',
	'08019',
	'08020',
	'08021',
	'08022',
	'08023',
	'08025',
	'08026',
	'08027',
	'08028',
	'08029',
	'08030',
	'08031',
	'08032',
	'08033',
	'08034',
	'08035',
	'08036',
	'08037',
	'08038',
	'08039',
	'08041',
	'08042',
	'08043',
	'08045',
	'08046',
	'08048',
	'08049',
	'08051',
	'08052',
	'08053',
	'08054',
	'08055',
	'08056',
	'08057',
	'08059',
	'08060',
	'08061',
	'08062',
	'08063',
	'08064',
	'08065',
	'08066',
	'08067',
	'08068',
	'08069',
	'08070',
	'08071',
	'08072',
	'08073',
	'08074',
	'08075',
	'08076',
	'08077',
	'08078',
	'08079',
	'08080',
	'08081',
	'08083',
	'08084',
	'08085',
	'08086',
	'08088',
	'08089',
	'08090',
	'08091',
	'08093',
	'08094',
	'08095',
	'08096',
	'08097',
	'08098',
	'08099',
	'08101',
	'08102',
	'08103',
	'08104',
	'08105',
	'08106',
	'08107',
	'08108',
	'08109',
	'08110',
	'08201',
	'08202',
	'08203',
	'08204',
	'08205',
	'08210',
	'08212',
	'08213',
	'08214',
	'08215',
	'08217',
	'08218',
	'08219',
	'08220',
	'08221',
	'08223',
	'08224',
	'08225',
	'08226',
	'08230',
	'08231',
	'08232',
	'08234',
	'08240',
	'08241',
	'08242',
	'08243',
	'08244',
	'08245',
	'08246',
	'08247',
	'08248',
	'08250',
	'08251',
	'08252',
	'08260',
	'08270',
	'08302',
	'08310',
	'08311',
	'08312',
	'08313',
	'08314',
	'08315',
	'08316',
	'08317',
	'08318',
	'08319',
	'08320',
	'08321',
	'08322',
	'08323',
	'08324',
	'08326',
	'08327',
	'08328',
	'08329',
	'08330',
	'08332',
	'08340',
	'08341',
	'08342',
	'08343',
	'08344',
	'08345',
	'08346',
	'08347',
	'08348',
	'08349',
	'08350',
	'08352',
	'08353',
	'08360',
	'08361',
	'08362',
	'08401',
	'08402',
	'08403',
	'08404',
	'08405',
	'08406',
	'08505',
	'08511',
	'08515',
	'08518',
	'08520',
	'08525',
	'08534',
	'08540',
	'08541',
	'08542',
	'08543',
	'08544',
	'08550',
	'08554',
	'08560',
	'08561',
	'08562',
	'08601',
	'08602',
	'08603',
	'08604',
	'08605',
	'08606',
	'08607',
	'08608',
	'08609',
	'08610',
	'08611',
	'08618',
	'08619',
	'08620',
	'08625',
	'08628',
	'08629',
	'08638',
	'08640',
	'08641',
	'08645',
	'08646',
	'08647',
	'08648',
	'08650',
	'08666',
	'08690',
	'08691',
	'08695',
	'18001',
	'18002',
	'18003',
	'18010',
	'18011',
	'18013',
	'18014',
	'18015',
	'18016',
	'18017',
	'18018',
	'18020',
	'18025',
	'18031',
	'18032',
	'18034',
	'18035',
	'18036',
	'18037',
	'18038',
	'18039',
	'18040',
	'18041',
	'18042',
	'18043',
	'18044',
	'18045',
	'18046',
	'18049',
	'18050',
	'18051',
	'18052',
	'18053',
	'18054',
	'18055',
	'18056',
	'18059',
	'18060',
	'18062',
	'18063',
	'18064',
	'18065',
	'18066',
	'18067',
	'18068',
	'18069',
	'18070',
	'18072',
	'18073',
	'18074',
	'18076',
	'18077',
	'18078',
	'18079',
	'18080',
	'18081',
	'18083',
	'18084',
	'18085',
	'18086',
	'18087',
	'18088',
	'18091',
	'18092',
	'18098',
	'18099',
	'18101',
	'18102',
	'18103',
	'18104',
	'18105',
	'18106',
	'18109',
	'18195',
	'18343',
	'18351',
	'18901',
	'18902',
	'18910',
	'18911',
	'18912',
	'18913',
	'18914',
	'18915',
	'18916',
	'18917',
	'18918',
	'18920',
	'18921',
	'18922',
	'18923',
	'18924',
	'18925',
	'18926',
	'18927',
	'18928',
	'18929',
	'18930',
	'18931',
	'18932',
	'18933',
	'18934',
	'18935',
	'18936',
	'18938',
	'18940',
	'18942',
	'18943',
	'18944',
	'18946',
	'18947',
	'18949',
	'18950',
	'18951',
	'18953',
	'18954',
	'18955',
	'18956',
	'18957',
	'18958',
	'18960',
	'18962',
	'18963',
	'18964',
	'18966',
	'18968',
	'18969',
	'18970',
	'18971',
	'18972',
	'18974',
	'18976',
	'18977',
	'18979',
	'18980',
	'18981',
	'18991',
	'19001',
	'19002',
	'19003',
	'19004',
	'19006',
	'19007',
	'19008',
	'19009',
	'19010',
	'19012',
	'19013',
	'19014',
	'19015',
	'19016',
	'19017',
	'19018',
	'19019',
	'19020',
	'19021',
	'19022',
	'19023',
	'19025',
	'19026',
	'19027',
	'19028',
	'19029',
	'19030',
	'19031',
	'19032',
	'19033',
	'19034',
	'19035',
	'19036',
	'19037',
	'19038',
	'19039',
	'19040',
	'19041',
	'19043',
	'19044',
	'19046',
	'19047',
	'19048',
	'19049',
	'19050',
	'19052',
	'19053',
	'19054',
	'19055',
	'19056',
	'19057',
	'19058',
	'19060',
	'19061',
	'19063',
	'19064',
	'19065',
	'19066',
	'19067',
	'19070',
	'19072',
	'19073',
	'19074',
	'19075',
	'19076',
	'19078',
	'19079',
	'19080',
	'19081',
	'19082',
	'19083',
	'19085',
	'19086',
	'19087',
	'19088',
	'19089',
	'19090',
	'19091',
	'19092',
	'19093',
	'19094',
	'19095',
	'19096',
	'19098',
	'19099',
	'19101',
	'19102',
	'19103',
	'19104',
	'19105',
	'19106',
	'19107',
	'19108',
	'19109',
	'19110',
	'19111',
	'19112',
	'19113',
	'19114',
	'19115',
	'19116',
	'19118',
	'19119',
	'19120',
	'19121',
	'19122',
	'19123',
	'19124',
	'19125',
	'19126',
	'19127',
	'19128',
	'19129',
	'19130',
	'19131',
	'19132',
	'19133',
	'19134',
	'19135',
	'19136',
	'19137',
	'19138',
	'19139',
	'19140',
	'19141',
	'19142',
	'19143',
	'19144',
	'19145',
	'19146',
	'19147',
	'19148',
	'19149',
	'19150',
	'19151',
	'19152',
	'19153',
	'19154',
	'19155',
	'19160',
	'19161',
	'19162',
	'19170',
	'19171',
	'19172',
	'19173',
	'19175',
	'19176',
	'19177',
	'19178',
	'19179',
	'19181',
	'19182',
	'19183',
	'19184',
	'19185',
	'19187',
	'19188',
	'19191',
	'19192',
	'19193',
	'19194',
	'19195',
	'19196',
	'19197',
	'19244',
	'19255',
	'19301',
	'19310',
	'19311',
	'19312',
	'19316',
	'19317',
	'19318',
	'19319',
	'19320',
	'19330',
	'19331',
	'19333',
	'19335',
	'19339',
	'19340',
	'19341',
	'19342',
	'19343',
	'19344',
	'19345',
	'19346',
	'19347',
	'19348',
	'19350',
	'19351',
	'19352',
	'19353',
	'19354',
	'19355',
	'19357',
	'19358',
	'19360',
	'19362',
	'19363',
	'19365',
	'19366',
	'19367',
	'19369',
	'19371',
	'19372',
	'19373',
	'19374',
	'19375',
	'19376',
	'19380',
	'19381',
	'19382',
	'19383',
	'19388',
	'19390',
	'19395',
	'19399',
	'19401',
	'19403',
	'19404',
	'19405',
	'19406',
	'19407',
	'19408',
	'19409',
	'19415',
	'19420',
	'19421',
	'19422',
	'19423',
	'19424',
	'19425',
	'19426',
	'19428',
	'19429',
	'19430',
	'19432',
	'19435',
	'19436',
	'19437',
	'19438',
	'19440',
	'19441',
	'19442',
	'19443',
	'19444',
	'19446',
	'19450',
	'19451',
	'19453',
	'19454',
	'19455',
	'19456',
	'19457',
	'19460',
	'19462',
	'19464',
	'19465',
	'19468',
	'19470',
	'19472',
	'19473',
	'19474',
	'19475',
	'19477',
	'19478',
	'19480',
	'19481',
	'19482',
	'19483',
	'19484',
	'19486',
	'19487',
	'19488',
	'19489',
	'19490',
	'19492',
	'19494',
	'19495',
	'19496',
	'19503',
	'19504',
	'19505',
	'19506',
	'19507',
	'19508',
	'19510',
	'19511',
	'19512',
	'19516',
	'19518',
	'19519',
	'19520',
	'19522',
	'19523',
	'19525',
	'19526',
	'19529',
	'19530',
	'19533',
	'19534',
	'19535',
	'19536',
	'19538',
	'19539',
	'19540',
	'19541',
	'19542',
	'19543',
	'19544',
	'19545',
	'19547',
	'19548',
	'19550',
	'19551',
	'19554',
	'19555',
	'19559',
	'19560',
	'19562',
	'19564',
	'19565',
	'19567',
	'19601',
	'19602',
	'19603',
	'19604',
	'19605',
	'19606',
	'19607',
	'19608',
	'19609',
	'19610',
	'19611',
	'19612',
	'19640',
	'19701',
	'19702',
	'19703',
	'19706',
	'19707',
	'19708',
	'19709',
	'19710',
	'19711',
	'19712',
	'19713',
	'19714',
	'19715',
	'19716',
	'19717',
	'19718',
	'19720',
	'19721',
	'19725',
	'19726',
	'19730',
	'19731',
	'19732',
	'19733',
	'19734',
	'19735',
	'19736',
	'19801',
	'19802',
	'19803',
	'19804',
	'19805',
	'19806',
	'19807',
	'19808',
	'19809',
	'19810',
	'19850',
	'19880',
	'19884',
	'19885',
	'19886',
	'19887',
	'19889',
	'19890',
	'19893',
	'19894',
	'19895',
	'19897',
	'19898',
	'19899',
	'19901',
	'19902',
	'19903',
	'19904',
	'19905',
	'19906',
	'19934',
	'19936',
	'19938',
	'19943',
	'19946',
	'19952',
	'19953',
	'19954',
	'19955',
	'19961',
	'19962',
	'19964',
	'19977',
	'19979',
	'19980',
	'82322',
	'82336',
	'82901',
	'82902',
	'82922',
	'82923',
	'82925',
	'82929',
	'82930',
	'82931',
	'82932',
	'82933',
	'82934',
	'82935',
	'82936',
	'82937',
	'82938',
	'82939',
	'82941',
	'82942',
	'82943',
	'82944',
	'82945',
	'83101',
	'83110',
	'83111',
	'83112',
	'83113',
	'83114',
	'83115',
	'83116',
	'83118',
	'83119',
	'83120',
	'83121',
	'83122',
	'83123',
	'83124',
	'83126',
	'83127',
	'83128',
	'83220',
	'83223',
	'83228',
	'83232',
	'83233',
	'83237',
	'83238',
	'83239',
	'83243',
	'83252',
	'83254',
	'83261',
	'83263',
	'83272',
	'83283',
	'83286',
	'83287',
	'84001',
	'84002',
	'84003',
	'84004',
	'84005',
	'84006',
	'84007',
	'84008',
	'84010',
	'84011',
	'84013',
	'84014',
	'84015',
	'84016',
	'84017',
	'84018',
	'84020',
	'84021',
	'84022',
	'84023',
	'84024',
	'84025',
	'84026',
	'84027',
	'84028',
	'84029',
	'84031',
	'84032',
	'84033',
	'84034',
	'84035',
	'84036',
	'84037',
	'84038',
	'84039',
	'84040',
	'84041',
	'84042',
	'84043',
	'84044',
	'84045',
	'84046',
	'84047',
	'84049',
	'84050',
	'84051',
	'84052',
	'84053',
	'84054',
	'84055',
	'84056',
	'84057',
	'84058',
	'84059',
	'84060',
	'84061',
	'84062',
	'84063',
	'84064',
	'84065',
	'84066',
	'84067',
	'84068',
	'84069',
	'84070',
	'84071',
	'84072',
	'84073',
	'84074',
	'84075',
	'84076',
	'84078',
	'84079',
	'84080',
	'84081',
	'84082',
	'84083',
	'84084',
	'84085',
	'84086',
	'84087',
	'84088',
	'84089',
	'84090',
	'84091',
	'84092',
	'84093',
	'84094',
	'84095',
	'84096',
	'84097',
	'84098',
	'84101',
	'84102',
	'84103',
	'84104',
	'84105',
	'84106',
	'84107',
	'84108',
	'84109',
	'84110',
	'84111',
	'84112',
	'84113',
	'84114',
	'84115',
	'84116',
	'84117',
	'84118',
	'84119',
	'84120',
	'84121',
	'84122',
	'84123',
	'84124',
	'84125',
	'84126',
	'84127',
	'84128',
	'84130',
	'84131',
	'84132',
	'84133',
	'84134',
	'84136',
	'84138',
	'84139',
	'84141',
	'84143',
	'84144',
	'84145',
	'84147',
	'84148',
	'84150',
	'84151',
	'84152',
	'84157',
	'84158',
	'84165',
	'84170',
	'84171',
	'84180',
	'84184',
	'84189',
	'84190',
	'84199',
	'84201',
	'84244',
	'84301',
	'84302',
	'84304',
	'84305',
	'84306',
	'84307',
	'84308',
	'84309',
	'84310',
	'84311',
	'84312',
	'84313',
	'84314',
	'84315',
	'84316',
	'84317',
	'84318',
	'84319',
	'84320',
	'84321',
	'84322',
	'84323',
	'84324',
	'84325',
	'84326',
	'84327',
	'84328',
	'84329',
	'84330',
	'84331',
	'84332',
	'84333',
	'84334',
	'84335',
	'84336',
	'84337',
	'84338',
	'84339',
	'84340',
	'84341',
	'84401',
	'84402',
	'84403',
	'84404',
	'84405',
	'84407',
	'84408',
	'84409',
	'84412',
	'84414',
	'84415',
	'84501',
	'84510',
	'84511',
	'84512',
	'84513',
	'84515',
	'84516',
	'84518',
	'84520',
	'84521',
	'84522',
	'84523',
	'84525',
	'84526',
	'84528',
	'84529',
	'84530',
	'84531',
	'84532',
	'84533',
	'84534',
	'84535',
	'84536',
	'84537',
	'84539',
	'84540',
	'84542',
	'84601',
	'84602',
	'84603',
	'84604',
	'84605',
	'84606',
	'84620',
	'84621',
	'84622',
	'84623',
	'84624',
	'84626',
	'84627',
	'84628',
	'84629',
	'84630',
	'84631',
	'84632',
	'84633',
	'84634',
	'84635',
	'84636',
	'84637',
	'84638',
	'84639',
	'84640',
	'84642',
	'84643',
	'84644',
	'84645',
	'84646',
	'84647',
	'84648',
	'84649',
	'84651',
	'84652',
	'84653',
	'84654',
	'84655',
	'84656',
	'84657',
	'84660',
	'84662',
	'84663',
	'84664',
	'84665',
	'84667',
	'84701',
	'84710',
	'84711',
	'84712',
	'84713',
	'84714',
	'84715',
	'84716',
	'84718',
	'84719',
	'84720',
	'84721',
	'84722',
	'84723',
	'84724',
	'84725',
	'84726',
	'84728',
	'84729',
	'84730',
	'84731',
	'84732',
	'84733',
	'84734',
	'84735',
	'84736',
	'84737',
	'84738',
	'84739',
	'84740',
	'84741',
	'84742',
	'84743',
	'84744',
	'84745',
	'84746',
	'84747',
	'84749',
	'84750',
	'84751',
	'84752',
	'84753',
	'84754',
	'84755',
	'84756',
	'84757',
	'84758',
	'84759',
	'84760',
	'84761',
	'84762',
	'84763',
	'84764',
	'84765',
	'84766',
	'84767',
	'84770',
	'84771',
	'84772',
	'84773',
	'84774',
	'84775',
	'84776',
	'84779',
	'84780',
	'84781',
	'84782',
	'84783',
	'84784',
	'84790',
	'84791',
	'89301',
	'89311',
	'89314',
	'89315',
	'89316',
	'89317',
	'89318',
	'89319',
	'89801',
	'89802',
	'89803',
	'89815',
	'89821',
	'89822',
	'89823',
	'89824',
	'89825',
	'89826',
	'89828',
	'89830',
	'89831',
	'89832',
	'89833',
	'89834',
	'89835',
	'89883',
	'32102',
	'32105',
	'32110',
	'32111',
	'32113',
	'32114',
	'32115',
	'32116',
	'32117',
	'32118',
	'32119',
	'32120',
	'32121',
	'32122',
	'32123',
	'32124',
	'32125',
	'32126',
	'32127',
	'32128',
	'32129',
	'32130',
	'32132',
	'32133',
	'32134',
	'32135',
	'32136',
	'32137',
	'32141',
	'32142',
	'32158',
	'32159',
	'32162',
	'32164',
	'32168',
	'32169',
	'32170',
	'32173',
	'32174',
	'32175',
	'32176',
	'32179',
	'32180',
	'32182',
	'32183',
	'32190',
	'32192',
	'32195',
	'32198',
	'32617',
	'32634',
	'32663',
	'32664',
	'32681',
	'32686',
	'32701',
	'32702',
	'32703',
	'32704',
	'32706',
	'32707',
	'32708',
	'32709',
	'32710',
	'32712',
	'32713',
	'32714',
	'32715',
	'32716',
	'32718',
	'32719',
	'32720',
	'32721',
	'32722',
	'32723',
	'32724',
	'32725',
	'32726',
	'32727',
	'32728',
	'32730',
	'32732',
	'32733',
	'32735',
	'32736',
	'32738',
	'32739',
	'32744',
	'32746',
	'32747',
	'32750',
	'32751',
	'32752',
	'32753',
	'32754',
	'32756',
	'32757',
	'32759',
	'32762',
	'32763',
	'32764',
	'32765',
	'32766',
	'32767',
	'32768',
	'32771',
	'32772',
	'32773',
	'32774',
	'32775',
	'32776',
	'32777',
	'32778',
	'32779',
	'32780',
	'32781',
	'32782',
	'32783',
	'32784',
	'32789',
	'32790',
	'32791',
	'32792',
	'32793',
	'32794',
	'32795',
	'32796',
	'32798',
	'32801',
	'32802',
	'32803',
	'32804',
	'32805',
	'32806',
	'32807',
	'32808',
	'32809',
	'32810',
	'32811',
	'32812',
	'32814',
	'32815',
	'32816',
	'32817',
	'32818',
	'32819',
	'32820',
	'32821',
	'32822',
	'32824',
	'32825',
	'32826',
	'32827',
	'32828',
	'32829',
	'32830',
	'32831',
	'32832',
	'32833',
	'32835',
	'32836',
	'32837',
	'32839',
	'32853',
	'32854',
	'32855',
	'32856',
	'32857',
	'32858',
	'32859',
	'32860',
	'32861',
	'32862',
	'32867',
	'32868',
	'32869',
	'32872',
	'32877',
	'32878',
	'32885',
	'32886',
	'32887',
	'32890',
	'32891',
	'32893',
	'32897',
	'32898',
	'32899',
	'32901',
	'32902',
	'32903',
	'32904',
	'32905',
	'32906',
	'32907',
	'32908',
	'32909',
	'32910',
	'32911',
	'32912',
	'32919',
	'32920',
	'32922',
	'32923',
	'32924',
	'32925',
	'32926',
	'32927',
	'32931',
	'32932',
	'32934',
	'32935',
	'32936',
	'32937',
	'32940',
	'32941',
	'32949',
	'32950',
	'32951',
	'32952',
	'32953',
	'32954',
	'32955',
	'32956',
	'32959',
	'32976',
	'33513',
	'33514',
	'33521',
	'33538',
	'33585',
	'33597',
	'33848',
	'34420',
	'34421',
	'34430',
	'34431',
	'34432',
	'34470',
	'34471',
	'34472',
	'34473',
	'34474',
	'34475',
	'34476',
	'34477',
	'34478',
	'34479',
	'34480',
	'34481',
	'34482',
	'34483',
	'34484',
	'34488',
	'34489',
	'34491',
	'34492',
	'34705',
	'34711',
	'34712',
	'34713',
	'34714',
	'34715',
	'34729',
	'34731',
	'34734',
	'34736',
	'34737',
	'34739',
	'34740',
	'34741',
	'34742',
	'34743',
	'34744',
	'34745',
	'34746',
	'34747',
	'34748',
	'34749',
	'34753',
	'34755',
	'34756',
	'34758',
	'34760',
	'34761',
	'34762',
	'34769',
	'34770',
	'34771',
	'34772',
	'34773',
	'34777',
	'34778',
	'34785',
	'34786',
	'34787',
	'34788',
	'34789',
	'34797',
	'94203',
	'94230',
	'94252',
	'94274',
	'94280',
	'94295',
	'94296',
	'94299',
	'94510',
	'94512',
	'94533',
	'94534',
	'94535',
	'94571',
	'94585',
	'94589',
	'94590',
	'94591',
	'94592',
	'95201',
	'95202',
	'95203',
	'95204',
	'95205',
	'95206',
	'95207',
	'95208',
	'95209',
	'95210',
	'95211',
	'95212',
	'95213',
	'95215',
	'95219',
	'95220',
	'95221',
	'95222',
	'95223',
	'95224',
	'95225',
	'95226',
	'95227',
	'95228',
	'95229',
	'95230',
	'95231',
	'95232',
	'95233',
	'95234',
	'95236',
	'95237',
	'95240',
	'95241',
	'95242',
	'95245',
	'95246',
	'95247',
	'95248',
	'95249',
	'95250',
	'95251',
	'95252',
	'95253',
	'95254',
	'95255',
	'95257',
	'95258',
	'95267',
	'95269',
	'95296',
	'95297',
	'95304',
	'95305',
	'95307',
	'95309',
	'95310',
	'95313',
	'95314',
	'95316',
	'95319',
	'95320',
	'95321',
	'95323',
	'95326',
	'95327',
	'95328',
	'95329',
	'95330',
	'95335',
	'95336',
	'95337',
	'95346',
	'95347',
	'95350',
	'95351',
	'95352',
	'95353',
	'95354',
	'95355',
	'95356',
	'95357',
	'95358',
	'95360',
	'95361',
	'95363',
	'95364',
	'95366',
	'95367',
	'95368',
	'95370',
	'95372',
	'95373',
	'95375',
	'95376',
	'95377',
	'95378',
	'95379',
	'95380',
	'95381',
	'95382',
	'95383',
	'95385',
	'95386',
	'95387',
	'95391',
	'95397',
	'95601',
	'95602',
	'95603',
	'95604',
	'95605',
	'95606',
	'95607',
	'95608',
	'95609',
	'95610',
	'95611',
	'95612',
	'95613',
	'95614',
	'95615',
	'95616',
	'95617',
	'95618',
	'95619',
	'95620',
	'95621',
	'95623',
	'95624',
	'95625',
	'95626',
	'95627',
	'95628',
	'95629',
	'95630',
	'95631',
	'95632',
	'95633',
	'95634',
	'95635',
	'95636',
	'95637',
	'95638',
	'95639',
	'95640',
	'95641',
	'95642',
	'95644',
	'95645',
	'95648',
	'95650',
	'95651',
	'95652',
	'95653',
	'95654',
	'95655',
	'95656',
	'95658',
	'95659',
	'95660',
	'95661',
	'95662',
	'95663',
	'95664',
	'95665',
	'95666',
	'95667',
	'95668',
	'95669',
	'95670',
	'95671',
	'95672',
	'95673',
	'95674',
	'95675',
	'95676',
	'95677',
	'95678',
	'95679',
	'95680',
	'95681',
	'95682',
	'95683',
	'95684',
	'95685',
	'95686',
	'95687',
	'95688',
	'95689',
	'95690',
	'95691',
	'95692',
	'95693',
	'95694',
	'95695',
	'95696',
	'95697',
	'95698',
	'95699',
	'95701',
	'95703',
	'95709',
	'95712',
	'95713',
	'95714',
	'95715',
	'95717',
	'95720',
	'95721',
	'95722',
	'95724',
	'95726',
	'95728',
	'95735',
	'95736',
	'95741',
	'95742',
	'95746',
	'95747',
	'95757',
	'95758',
	'95759',
	'95762',
	'95763',
	'95765',
	'95776',
	'95798',
	'95811',
	'95812',
	'95813',
	'95814',
	'95815',
	'95816',
	'95817',
	'95818',
	'95819',
	'95820',
	'95821',
	'95822',
	'95823',
	'95824',
	'95825',
	'95826',
	'95827',
	'95828',
	'95829',
	'95830',
	'95831',
	'95832',
	'95833',
	'95834',
	'95835',
	'95836',
	'95837',
	'95838',
	'95840',
	'95841',
	'95842',
	'95843',
	'95851',
	'95852',
	'95853',
	'95860',
	'95864',
	'95865',
	'95866',
	'95867',
	'95887',
	'95899',
	'95901',
	'95903',
	'95910',
	'95912',
	'95915',
	'95918',
	'95919',
	'95922',
	'95923',
	'95924',
	'95925',
	'95932',
	'95934',
	'95935',
	'95936',
	'95937',
	'95944',
	'95945',
	'95946',
	'95947',
	'95949',
	'95950',
	'95953',
	'95955',
	'95956',
	'95957',
	'95959',
	'95960',
	'95961',
	'95962',
	'95970',
	'95971',
	'95972',
	'95975',
	'95977',
	'95979',
	'95980',
	'95981',
	'95982',
	'95983',
	'95984',
	'95986',
	'95987',
	'95991',
	'95992',
	'95993',
	'96020',
	'96103',
	'96105',
	'96106',
	'96111',
	'96118',
	'96122',
	'96124',
	'96125',
	'96126',
	'96129',
	'96135',
	'96140',
	'96141',
	'96142',
	'96143',
	'96145',
	'96146',
	'96148',
	'96150',
	'96151',
	'96152',
	'96154',
	'96155',
	'96156',
	'96157',
	'96158',
	'96160',
	'96161',
	'96162',
	'48001',
	'48002',
	'48003',
	'48004',
	'48005',
	'48006',
	'48007',
	'48009',
	'48012',
	'48014',
	'48015',
	'48017',
	'48021',
	'48022',
	'48023',
	'48025',
	'48026',
	'48027',
	'48028',
	'48030',
	'48032',
	'48033',
	'48034',
	'48035',
	'48036',
	'48037',
	'48038',
	'48039',
	'48040',
	'48041',
	'48042',
	'48043',
	'48044',
	'48045',
	'48046',
	'48047',
	'48048',
	'48049',
	'48050',
	'48051',
	'48054',
	'48059',
	'48060',
	'48061',
	'48062',
	'48063',
	'48064',
	'48065',
	'48066',
	'48067',
	'48068',
	'48069',
	'48070',
	'48071',
	'48072',
	'48073',
	'48074',
	'48075',
	'48076',
	'48079',
	'48080',
	'48081',
	'48082',
	'48083',
	'48084',
	'48085',
	'48086',
	'48088',
	'48089',
	'48090',
	'48091',
	'48092',
	'48093',
	'48094',
	'48095',
	'48096',
	'48097',
	'48098',
	'48099',
	'48101',
	'48103',
	'48104',
	'48105',
	'48106',
	'48107',
	'48108',
	'48109',
	'48110',
	'48111',
	'48112',
	'48113',
	'48114',
	'48115',
	'48116',
	'48117',
	'48118',
	'48120',
	'48121',
	'48122',
	'48123',
	'48124',
	'48125',
	'48126',
	'48127',
	'48128',
	'48130',
	'48131',
	'48133',
	'48134',
	'48135',
	'48136',
	'48137',
	'48138',
	'48139',
	'48140',
	'48141',
	'48143',
	'48144',
	'48145',
	'48146',
	'48150',
	'48151',
	'48152',
	'48153',
	'48154',
	'48157',
	'48158',
	'48159',
	'48160',
	'48161',
	'48162',
	'48164',
	'48165',
	'48166',
	'48167',
	'48168',
	'48169',
	'48170',
	'48173',
	'48174',
	'48175',
	'48176',
	'48177',
	'48178',
	'48179',
	'48180',
	'48182',
	'48183',
	'48184',
	'48185',
	'48186',
	'48187',
	'48188',
	'48189',
	'48190',
	'48191',
	'48192',
	'48193',
	'48195',
	'48197',
	'48198',
	'48201',
	'48202',
	'48203',
	'48204',
	'48205',
	'48206',
	'48207',
	'48208',
	'48209',
	'48210',
	'48211',
	'48212',
	'48213',
	'48214',
	'48215',
	'48216',
	'48217',
	'48218',
	'48219',
	'48220',
	'48221',
	'48222',
	'48223',
	'48224',
	'48225',
	'48226',
	'48227',
	'48228',
	'48229',
	'48230',
	'48231',
	'48232',
	'48233',
	'48234',
	'48235',
	'48236',
	'48237',
	'48238',
	'48239',
	'48240',
	'48242',
	'48243',
	'48244',
	'48255',
	'48260',
	'48264',
	'48265',
	'48266',
	'48267',
	'48268',
	'48269',
	'48272',
	'48275',
	'48277',
	'48278',
	'48279',
	'48288',
	'48301',
	'48302',
	'48303',
	'48304',
	'48306',
	'48307',
	'48308',
	'48309',
	'48310',
	'48311',
	'48312',
	'48313',
	'48314',
	'48315',
	'48316',
	'48317',
	'48318',
	'48320',
	'48321',
	'48322',
	'48323',
	'48324',
	'48325',
	'48326',
	'48327',
	'48328',
	'48329',
	'48330',
	'48331',
	'48332',
	'48333',
	'48334',
	'48335',
	'48336',
	'48340',
	'48341',
	'48342',
	'48343',
	'48346',
	'48347',
	'48348',
	'48350',
	'48353',
	'48356',
	'48357',
	'48359',
	'48360',
	'48361',
	'48362',
	'48363',
	'48366',
	'48367',
	'48370',
	'48371',
	'48374',
	'48375',
	'48376',
	'48377',
	'48380',
	'48381',
	'48382',
	'48383',
	'48386',
	'48387',
	'48390',
	'48393',
	'48397',
	'48401',
	'48410',
	'48412',
	'48416',
	'48419',
	'48421',
	'48422',
	'48426',
	'48427',
	'48428',
	'48434',
	'48440',
	'48442',
	'48444',
	'48446',
	'48450',
	'48453',
	'48454',
	'48455',
	'48456',
	'48461',
	'48462',
	'48464',
	'48465',
	'48466',
	'48469',
	'48471',
	'48472',
	'48727',
	'48760',
	'48816',
	'48836',
	'48843',
	'48844',
	'48855',
	'48863',
	'49267',
	'49270',
	'20701',
	'20711',
	'20723',
	'20724',
	'20733',
	'20751',
	'20755',
	'20758',
	'20759',
	'20763',
	'20764',
	'20765',
	'20776',
	'20777',
	'20778',
	'20779',
	'20794',
	'21001',
	'21005',
	'21009',
	'21010',
	'21012',
	'21013',
	'21014',
	'21015',
	'21017',
	'21018',
	'21020',
	'21022',
	'21023',
	'21027',
	'21028',
	'21029',
	'21030',
	'21031',
	'21032',
	'21034',
	'21035',
	'21036',
	'21037',
	'21040',
	'21041',
	'21042',
	'21043',
	'21044',
	'21045',
	'21046',
	'21047',
	'21048',
	'21050',
	'21051',
	'21052',
	'21053',
	'21054',
	'21056',
	'21057',
	'21060',
	'21061',
	'21062',
	'21065',
	'21071',
	'21074',
	'21075',
	'21076',
	'21077',
	'21078',
	'21082',
	'21084',
	'21085',
	'21087',
	'21088',
	'21090',
	'21092',
	'21093',
	'21094',
	'21098',
	'21102',
	'21104',
	'21105',
	'21106',
	'21108',
	'21111',
	'21113',
	'21114',
	'21117',
	'21120',
	'21122',
	'21123',
	'21128',
	'21130',
	'21131',
	'21132',
	'21133',
	'21136',
	'21139',
	'21140',
	'21144',
	'21146',
	'21150',
	'21152',
	'21153',
	'21154',
	'21155',
	'21156',
	'21157',
	'21158',
	'21160',
	'21161',
	'21162',
	'21163',
	'21201',
	'21202',
	'21203',
	'21204',
	'21205',
	'21206',
	'21207',
	'21208',
	'21209',
	'21210',
	'21211',
	'21212',
	'21213',
	'21214',
	'21215',
	'21216',
	'21217',
	'21218',
	'21219',
	'21220',
	'21221',
	'21222',
	'21223',
	'21224',
	'21225',
	'21226',
	'21227',
	'21228',
	'21229',
	'21230',
	'21231',
	'21233',
	'21234',
	'21235',
	'21236',
	'21237',
	'21239',
	'21240',
	'21241',
	'21244',
	'21250',
	'21251',
	'21252',
	'21260',
	'21261',
	'21263',
	'21264',
	'21265',
	'21268',
	'21270',
	'21273',
	'21274',
	'21275',
	'21278',
	'21279',
	'21280',
	'21281',
	'21282',
	'21283',
	'21284',
	'21285',
	'21286',
	'21287',
	'21288',
	'21289',
	'21290',
	'21297',
	'21298',
	'21401',
	'21402',
	'21403',
	'21404',
	'21405',
	'21409',
	'21411',
	'21412',
	'21601',
	'21606',
	'21607',
	'21609',
	'21610',
	'21612',
	'21617',
	'21619',
	'21620',
	'21623',
	'21624',
	'21625',
	'21628',
	'21629',
	'21632',
	'21635',
	'21636',
	'21638',
	'21639',
	'21640',
	'21641',
	'21644',
	'21645',
	'21647',
	'21649',
	'21650',
	'21651',
	'21652',
	'21653',
	'21654',
	'21655',
	'21656',
	'21657',
	'21658',
	'21660',
	'21661',
	'21662',
	'21663',
	'21665',
	'21666',
	'21667',
	'21668',
	'21670',
	'21671',
	'21673',
	'21676',
	'21678',
	'21679',
	'21681',
	'21682',
	'21683',
	'21685',
	'21687',
	'21688',
	'21690',
	'21723',
	'21737',
	'21738',
	'21757',
	'21765',
	'21776',
	'21784',
	'21787',
	'21791',
	'21794',
	'21797',
	'21901',
	'21902',
	'21903',
	'21904',
	'21911',
	'21912',
	'21913',
	'21914',
	'21915',
	'21916',
	'21917',
	'21918',
	'21919',
	'21920',
	'21921',
	'21922',
	'21930',
	'43001',
	'43002',
	'43003',
	'43004',
	'43005',
	'43006',
	'43007',
	'43008',
	'43011',
	'43013',
	'43014',
	'43015',
	'43016',
	'43017',
	'43018',
	'43019',
	'43021',
	'43022',
	'43023',
	'43025',
	'43026',
	'43027',
	'43028',
	'43029',
	'43030',
	'43031',
	'43032',
	'43033',
	'43035',
	'43036',
	'43037',
	'43040',
	'43041',
	'43045',
	'43046',
	'43048',
	'43050',
	'43054',
	'43055',
	'43056',
	'43058',
	'43061',
	'43062',
	'43064',
	'43065',
	'43066',
	'43067',
	'43068',
	'43069',
	'43071',
	'43073',
	'43074',
	'43076',
	'43077',
	'43080',
	'43081',
	'43082',
	'43085',
	'43086',
	'43093',
	'43098',
	'43101',
	'43102',
	'43103',
	'43105',
	'43106',
	'43107',
	'43109',
	'43110',
	'43111',
	'43112',
	'43113',
	'43115',
	'43116',
	'43117',
	'43119',
	'43123',
	'43125',
	'43126',
	'43127',
	'43128',
	'43130',
	'43135',
	'43136',
	'43137',
	'43138',
	'43140',
	'43142',
	'43143',
	'43144',
	'43145',
	'43146',
	'43147',
	'43148',
	'43149',
	'43150',
	'43151',
	'43152',
	'43153',
	'43154',
	'43155',
	'43156',
	'43157',
	'43158',
	'43160',
	'43162',
	'43163',
	'43164',
	'43195',
	'43196',
	'43198',
	'43199',
	'43201',
	'43202',
	'43203',
	'43204',
	'43205',
	'43206',
	'43207',
	'43209',
	'43210',
	'43211',
	'43212',
	'43213',
	'43214',
	'43215',
	'43216',
	'43217',
	'43218',
	'43219',
	'43220',
	'43221',
	'43222',
	'43223',
	'43224',
	'43226',
	'43227',
	'43228',
	'43229',
	'43230',
	'43231',
	'43232',
	'43234',
	'43235',
	'43236',
	'43240',
	'43251',
	'43260',
	'43265',
	'43266',
	'43268',
	'43270',
	'43271',
	'43272',
	'43279',
	'43287',
	'43291',
	'43299',
	'43301',
	'43302',
	'43306',
	'43307',
	'43314',
	'43315',
	'43317',
	'43320',
	'43321',
	'43322',
	'43325',
	'43326',
	'43332',
	'43334',
	'43335',
	'43337',
	'43338',
	'43340',
	'43341',
	'43342',
	'43344',
	'43345',
	'43346',
	'43349',
	'43350',
	'43356',
	'43711',
	'43717',
	'43721',
	'43722',
	'43723',
	'43724',
	'43725',
	'43728',
	'43730',
	'43731',
	'43732',
	'43733',
	'43736',
	'43739',
	'43740',
	'43748',
	'43749',
	'43750',
	'43755',
	'43756',
	'43758',
	'43760',
	'43761',
	'43764',
	'43766',
	'43768',
	'43772',
	'43773',
	'43778',
	'43779',
	'43780',
	'43782',
	'43783',
	'43787',
	'43788',
	'43803',
	'43805',
	'43811',
	'43812',
	'43824',
	'43828',
	'43836',
	'43843',
	'43844',
	'43845',
	'44820',
	'44825',
	'44827',
	'44833',
	'44854',
	'44856',
	'44860',
	'44881',
	'44887',
	'45601',
	'45612',
	'45613',
	'45617',
	'45624',
	'45628',
	'45633',
	'45642',
	'45644',
	'45646',
	'45647',
	'45661',
	'45673',
	'45681',
	'45683',
	'45687',
	'45690',
	'45701',
	'45710',
	'45711',
	'45716',
	'45717',
	'45719',
	'45723',
	'45727',
	'45732',
	'45735',
	'45739',
	'45740',
	'45761',
	'45764',
	'45766',
	'45776',
	'45777',
	'45778',
	'45780',
	'45782',
	'45810',
	'45812',
	'45835',
	'45836',
	'45843',
	'45859',
	'31501',
	'31502',
	'31503',
	'31516',
	'31518',
	'31520',
	'31521',
	'31522',
	'31523',
	'31524',
	'31525',
	'31527',
	'31537',
	'31542',
	'31543',
	'31547',
	'31548',
	'31550',
	'31551',
	'31552',
	'31553',
	'31556',
	'31557',
	'31558',
	'31561',
	'31562',
	'31564',
	'31565',
	'31566',
	'31568',
	'31569',
	'32003',
	'32004',
	'32006',
	'32007',
	'32009',
	'32011',
	'32024',
	'32025',
	'32026',
	'32030',
	'32033',
	'32034',
	'32035',
	'32038',
	'32040',
	'32041',
	'32042',
	'32043',
	'32044',
	'32046',
	'32050',
	'32054',
	'32055',
	'32056',
	'32058',
	'32061',
	'32063',
	'32065',
	'32067',
	'32068',
	'32072',
	'32073',
	'32079',
	'32080',
	'32081',
	'32082',
	'32083',
	'32084',
	'32085',
	'32086',
	'32087',
	'32091',
	'32092',
	'32095',
	'32097',
	'32099',
	'32112',
	'32131',
	'32138',
	'32139',
	'32140',
	'32145',
	'32147',
	'32148',
	'32149',
	'32157',
	'32160',
	'32177',
	'32178',
	'32181',
	'32185',
	'32187',
	'32189',
	'32193',
	'32201',
	'32202',
	'32203',
	'32204',
	'32205',
	'32206',
	'32207',
	'32208',
	'32209',
	'32210',
	'32211',
	'32212',
	'32214',
	'32215',
	'32216',
	'32217',
	'32218',
	'32219',
	'32220',
	'32221',
	'32222',
	'32223',
	'32224',
	'32225',
	'32226',
	'32227',
	'32228',
	'32229',
	'32230',
	'32231',
	'32232',
	'32233',
	'32234',
	'32235',
	'32236',
	'32237',
	'32238',
	'32239',
	'32240',
	'32241',
	'32244',
	'32245',
	'32246',
	'32247',
	'32250',
	'32254',
	'32255',
	'32256',
	'32257',
	'32258',
	'32259',
	'32260',
	'32266',
	'32267',
	'32277',
	'32290',
	'32622',
	'32656',
	'32666',
	'32697',
	'91752',
	'92201',
	'92202',
	'92203',
	'92210',
	'92211',
	'92220',
	'92223',
	'92225',
	'92226',
	'92230',
	'92234',
	'92235',
	'92236',
	'92239',
	'92240',
	'92241',
	'92247',
	'92248',
	'92253',
	'92254',
	'92255',
	'92258',
	'92260',
	'92261',
	'92262',
	'92263',
	'92264',
	'92270',
	'92274',
	'92276',
	'92282',
	'92320',
	'92501',
	'92502',
	'92503',
	'92504',
	'92505',
	'92506',
	'92507',
	'92508',
	'92509',
	'92513',
	'92514',
	'92515',
	'92516',
	'92517',
	'92518',
	'92519',
	'92521',
	'92522',
	'92530',
	'92531',
	'92532',
	'92536',
	'92539',
	'92543',
	'92544',
	'92545',
	'92546',
	'92548',
	'92549',
	'92551',
	'92552',
	'92553',
	'92554',
	'92555',
	'92556',
	'92557',
	'92561',
	'92562',
	'92563',
	'92564',
	'92567',
	'92570',
	'92571',
	'92572',
	'92581',
	'92582',
	'92583',
	'92584',
	'92585',
	'92586',
	'92587',
	'92589',
	'92590',
	'92591',
	'92592',
	'92593',
	'92595',
	'92596',
	'92599',
	'92860',
	'92877',
	'92878',
	'92879',
	'92880',
	'92881',
	'92882',
	'92883',
	'28018',
	'28019',
	'28024',
	'28040',
	'28043',
	'28074',
	'28076',
	'28139',
	'28160',
	'28167',
	'28701',
	'28702',
	'28704',
	'28705',
	'28707',
	'28708',
	'28709',
	'28710',
	'28711',
	'28712',
	'28713',
	'28714',
	'28715',
	'28716',
	'28717',
	'28718',
	'28719',
	'28720',
	'28721',
	'28722',
	'28723',
	'28724',
	'28725',
	'28726',
	'28727',
	'28728',
	'28729',
	'28730',
	'28731',
	'28732',
	'28733',
	'28734',
	'28735',
	'28736',
	'28737',
	'28738',
	'28739',
	'28740',
	'28741',
	'28742',
	'28743',
	'28744',
	'28745',
	'28746',
	'28747',
	'28748',
	'28749',
	'28750',
	'28751',
	'28752',
	'28753',
	'28754',
	'28755',
	'28756',
	'28757',
	'28758',
	'28759',
	'28760',
	'28761',
	'28762',
	'28763',
	'28765',
	'28766',
	'28768',
	'28770',
	'28771',
	'28772',
	'28773',
	'28774',
	'28775',
	'28776',
	'28777',
	'28778',
	'28779',
	'28782',
	'28783',
	'28784',
	'28785',
	'28786',
	'28787',
	'28788',
	'28789',
	'28790',
	'28791',
	'28792',
	'28793',
	'28801',
	'28802',
	'28803',
	'28804',
	'28805',
	'28806',
	'28810',
	'28813',
	'28814',
	'28815',
	'28816',
	'29031',
	'29301',
	'29302',
	'29303',
	'29304',
	'29305',
	'29306',
	'29307',
	'29316',
	'29318',
	'29319',
	'29320',
	'29321',
	'29322',
	'29323',
	'29324',
	'29325',
	'29329',
	'29330',
	'29331',
	'29332',
	'29333',
	'29334',
	'29335',
	'29336',
	'29338',
	'29340',
	'29341',
	'29342',
	'29346',
	'29348',
	'29349',
	'29351',
	'29353',
	'29356',
	'29360',
	'29364',
	'29365',
	'29368',
	'29369',
	'29370',
	'29372',
	'29373',
	'29374',
	'29375',
	'29376',
	'29377',
	'29378',
	'29379',
	'29384',
	'29385',
	'29386',
	'29388',
	'29390',
	'29391',
	'29395',
	'29601',
	'29602',
	'29603',
	'29604',
	'29605',
	'29606',
	'29607',
	'29608',
	'29609',
	'29610',
	'29611',
	'29612',
	'29613',
	'29614',
	'29615',
	'29616',
	'29617',
	'29620',
	'29621',
	'29622',
	'29623',
	'29624',
	'29625',
	'29626',
	'29627',
	'29628',
	'29630',
	'29631',
	'29632',
	'29633',
	'29634',
	'29635',
	'29636',
	'29638',
	'29639',
	'29640',
	'29641',
	'29642',
	'29643',
	'29644',
	'29645',
	'29646',
	'29647',
	'29648',
	'29649',
	'29650',
	'29651',
	'29652',
	'29653',
	'29654',
	'29655',
	'29656',
	'29657',
	'29658',
	'29659',
	'29661',
	'29662',
	'29664',
	'29665',
	'29666',
	'29667',
	'29669',
	'29670',
	'29671',
	'29672',
	'29673',
	'29675',
	'29676',
	'29677',
	'29678',
	'29679',
	'29680',
	'29681',
	'29682',
	'29683',
	'29684',
	'29685',
	'29686',
	'29687',
	'29688',
	'29689',
	'29690',
	'29691',
	'29692',
	'29693',
	'29695',
	'29696',
	'29697',
	'29698',
	'29702',
	'29819',
	'29848',
	'30516',
	'30520',
	'30521',
	'30538',
	'30553',
	'30557',
	'30577',
	'30598',
	'30624',
	'30634',
	'30635',
	'30639',
	'30643',
	'30662',
	'23001',
	'23003',
	'23018',
	'23021',
	'23025',
	'23035',
	'23045',
	'23050',
	'23056',
	'23061',
	'23062',
	'23064',
	'23066',
	'23068',
	'23072',
	'23076',
	'23081',
	'23090',
	'23107',
	'23109',
	'23119',
	'23125',
	'23127',
	'23128',
	'23130',
	'23131',
	'23138',
	'23154',
	'23155',
	'23163',
	'23168',
	'23178',
	'23183',
	'23184',
	'23185',
	'23186',
	'23187',
	'23188',
	'23190',
	'23301',
	'23302',
	'23303',
	'23304',
	'23306',
	'23307',
	'23308',
	'23310',
	'23313',
	'23314',
	'23315',
	'23316',
	'23320',
	'23321',
	'23322',
	'23323',
	'23324',
	'23325',
	'23326',
	'23327',
	'23328',
	'23336',
	'23337',
	'23341',
	'23345',
	'23347',
	'23350',
	'23354',
	'23356',
	'23357',
	'23358',
	'23359',
	'23389',
	'23395',
	'23396',
	'23397',
	'23398',
	'23399',
	'23401',
	'23404',
	'23405',
	'23407',
	'23408',
	'23409',
	'23410',
	'23412',
	'23413',
	'23414',
	'23415',
	'23416',
	'23417',
	'23418',
	'23419',
	'23420',
	'23421',
	'23422',
	'23423',
	'23424',
	'23426',
	'23427',
	'23429',
	'23430',
	'23431',
	'23432',
	'23433',
	'23434',
	'23435',
	'23436',
	'23437',
	'23438',
	'23439',
	'23440',
	'23441',
	'23442',
	'23443',
	'23450',
	'23451',
	'23452',
	'23453',
	'23454',
	'23455',
	'23456',
	'23457',
	'23458',
	'23459',
	'23460',
	'23461',
	'23462',
	'23463',
	'23464',
	'23465',
	'23466',
	'23467',
	'23471',
	'23479',
	'23480',
	'23482',
	'23483',
	'23486',
	'23487',
	'23488',
	'23501',
	'23502',
	'23503',
	'23504',
	'23505',
	'23506',
	'23507',
	'23508',
	'23509',
	'23510',
	'23511',
	'23512',
	'23513',
	'23514',
	'23515',
	'23517',
	'23518',
	'23519',
	'23521',
	'23523',
	'23529',
	'23541',
	'23551',
	'23601',
	'23602',
	'23603',
	'23604',
	'23605',
	'23606',
	'23607',
	'23608',
	'23609',
	'23612',
	'23628',
	'23630',
	'23651',
	'23661',
	'23662',
	'23663',
	'23664',
	'23665',
	'23666',
	'23667',
	'23668',
	'23669',
	'23670',
	'23681',
	'23690',
	'23691',
	'23692',
	'23693',
	'23694',
	'23696',
	'23701',
	'23702',
	'23703',
	'23704',
	'23705',
	'23707',
	'23708',
	'23709',
	'23827',
	'23828',
	'23829',
	'23837',
	'23839',
	'23844',
	'23846',
	'23851',
	'23866',
	'23874',
	'23878',
	'23881',
	'23883',
	'23898',
	'23899',
	'27818',
	'27820',
	'27831',
	'27832',
	'27842',
	'27845',
	'27853',
	'27854',
	'27855',
	'27862',
	'27866',
	'27867',
	'27869',
	'27876',
	'27877',
	'27897',
	'27906',
	'27907',
	'27909',
	'27910',
	'27915',
	'27916',
	'27917',
	'27919',
	'27920',
	'27921',
	'27922',
	'27923',
	'27926',
	'27927',
	'27929',
	'27930',
	'27932',
	'27935',
	'27936',
	'27937',
	'27938',
	'27939',
	'27941',
	'27942',
	'27943',
	'27944',
	'27946',
	'27947',
	'27948',
	'27949',
	'27950',
	'27953',
	'27954',
	'27956',
	'27958',
	'27959',
	'27964',
	'27965',
	'27966',
	'27968',
	'27969',
	'27972',
	'27973',
	'27974',
	'27976',
	'27978',
	'27979',
	'27980',
	'27981',
	'27982',
	'27985',
	'27986',
	'06001',
	'06002',
	'06006',
	'06010',
	'06011',
	'06013',
	'06016',
	'06018',
	'06019',
	'06020',
	'06021',
	'06022',
	'06023',
	'06024',
	'06025',
	'06026',
	'06027',
	'06028',
	'06029',
	'06030',
	'06031',
	'06032',
	'06033',
	'06034',
	'06035',
	'06037',
	'06039',
	'06040',
	'06041',
	'06042',
	'06043',
	'06045',
	'06050',
	'06051',
	'06052',
	'06053',
	'06057',
	'06058',
	'06059',
	'06060',
	'06061',
	'06062',
	'06063',
	'06064',
	'06065',
	'06066',
	'06067',
	'06068',
	'06069',
	'06070',
	'06071',
	'06072',
	'06073',
	'06074',
	'06075',
	'06076',
	'06077',
	'06078',
	'06079',
	'06080',
	'06081',
	'06082',
	'06083',
	'06084',
	'06085',
	'06087',
	'06088',
	'06089',
	'06090',
	'06091',
	'06092',
	'06093',
	'06094',
	'06095',
	'06096',
	'06098',
	'06101',
	'06102',
	'06103',
	'06105',
	'06106',
	'06107',
	'06108',
	'06109',
	'06110',
	'06111',
	'06112',
	'06114',
	'06115',
	'06117',
	'06118',
	'06119',
	'06120',
	'06123',
	'06126',
	'06127',
	'06128',
	'06129',
	'06131',
	'06132',
	'06133',
	'06134',
	'06137',
	'06138',
	'06140',
	'06141',
	'06142',
	'06143',
	'06144',
	'06145',
	'06146',
	'06147',
	'06150',
	'06151',
	'06152',
	'06153',
	'06154',
	'06155',
	'06156',
	'06160',
	'06161',
	'06167',
	'06176',
	'06180',
	'06183',
	'06226',
	'06230',
	'06231',
	'06232',
	'06233',
	'06234',
	'06235',
	'06237',
	'06238',
	'06239',
	'06241',
	'06242',
	'06243',
	'06244',
	'06245',
	'06246',
	'06247',
	'06248',
	'06249',
	'06250',
	'06251',
	'06254',
	'06255',
	'06256',
	'06258',
	'06259',
	'06260',
	'06262',
	'06263',
	'06264',
	'06265',
	'06266',
	'06267',
	'06268',
	'06269',
	'06277',
	'06278',
	'06279',
	'06280',
	'06281',
	'06282',
	'06320',
	'06330',
	'06331',
	'06332',
	'06333',
	'06334',
	'06335',
	'06336',
	'06338',
	'06339',
	'06340',
	'06349',
	'06350',
	'06351',
	'06353',
	'06354',
	'06355',
	'06357',
	'06359',
	'06360',
	'06365',
	'06370',
	'06371',
	'06372',
	'06373',
	'06374',
	'06375',
	'06376',
	'06377',
	'06378',
	'06379',
	'06380',
	'06382',
	'06383',
	'06384',
	'06385',
	'06386',
	'06387',
	'06388',
	'06389',
	'06401',
	'06403',
	'06405',
	'06408',
	'06409',
	'06410',
	'06411',
	'06412',
	'06413',
	'06414',
	'06415',
	'06416',
	'06417',
	'06418',
	'06419',
	'06420',
	'06422',
	'06423',
	'06424',
	'06426',
	'06437',
	'06438',
	'06439',
	'06441',
	'06442',
	'06443',
	'06444',
	'06447',
	'06450',
	'06451',
	'06454',
	'06455',
	'06456',
	'06457',
	'06459',
	'06460',
	'06461',
	'06467',
	'06469',
	'06471',
	'06472',
	'06473',
	'06474',
	'06475',
	'06477',
	'06478',
	'06479',
	'06480',
	'06481',
	'06483',
	'06487',
	'06488',
	'06489',
	'06492',
	'06493',
	'06494',
	'06495',
	'06498',
	'06501',
	'06502',
	'06503',
	'06504',
	'06505',
	'06506',
	'06507',
	'06508',
	'06509',
	'06510',
	'06511',
	'06512',
	'06513',
	'06514',
	'06515',
	'06516',
	'06517',
	'06518',
	'06519',
	'06520',
	'06521',
	'06524',
	'06525',
	'06530',
	'06531',
	'06532',
	'06533',
	'06534',
	'06535',
	'06536',
	'06537',
	'06540',
	'06701',
	'06702',
	'06703',
	'06704',
	'06705',
	'06706',
	'06708',
	'06710',
	'06712',
	'06716',
	'06720',
	'06721',
	'06722',
	'06723',
	'06724',
	'06725',
	'06749',
	'06750',
	'06751',
	'06752',
	'06753',
	'06754',
	'06755',
	'06756',
	'06757',
	'06758',
	'06759',
	'06762',
	'06763',
	'06770',
	'06776',
	'06777',
	'06778',
	'06779',
	'06781',
	'06782',
	'06783',
	'06785',
	'06786',
	'06787',
	'06790',
	'06791',
	'06792',
	'06793',
	'06794',
	'06795',
	'06796',
	'06798',
	'81122',
	'81137',
	'81301',
	'81302',
	'81303',
	'81321',
	'81323',
	'81326',
	'81327',
	'81328',
	'81329',
	'81330',
	'81331',
	'81334',
	'81335',
	'85920',
	'85924',
	'85925',
	'85927',
	'85930',
	'85932',
	'85936',
	'85938',
	'85940',
	'86028',
	'86502',
	'86503',
	'86504',
	'86505',
	'86506',
	'86507',
	'86508',
	'86511',
	'86512',
	'86514',
	'86515',
	'86535',
	'86538',
	'86540',
	'86544',
	'86545',
	'86547',
	'86556',
	'87001',
	'87002',
	'87004',
	'87005',
	'87006',
	'87007',
	'87008',
	'87009',
	'87010',
	'87011',
	'87012',
	'87013',
	'87014',
	'87015',
	'87016',
	'87017',
	'87018',
	'87020',
	'87021',
	'87022',
	'87023',
	'87024',
	'87025',
	'87026',
	'87027',
	'87028',
	'87029',
	'87031',
	'87032',
	'87034',
	'87035',
	'87036',
	'87037',
	'87038',
	'87040',
	'87041',
	'87042',
	'87043',
	'87044',
	'87045',
	'87046',
	'87047',
	'87048',
	'87049',
	'87051',
	'87052',
	'87053',
	'87056',
	'87059',
	'87060',
	'87061',
	'87062',
	'87063',
	'87064',
	'87068',
	'87070',
	'87072',
	'87083',
	'87101',
	'87102',
	'87103',
	'87104',
	'87105',
	'87106',
	'87107',
	'87108',
	'87109',
	'87110',
	'87111',
	'87112',
	'87113',
	'87114',
	'87116',
	'87117',
	'87119',
	'87120',
	'87121',
	'87122',
	'87123',
	'87124',
	'87125',
	'87131',
	'87144',
	'87151',
	'87153',
	'87154',
	'87158',
	'87165',
	'87174',
	'87176',
	'87181',
	'87184',
	'87185',
	'87187',
	'87190',
	'87191',
	'87192',
	'87193',
	'87194',
	'87195',
	'87196',
	'87197',
	'87198',
	'87199',
	'87301',
	'87302',
	'87305',
	'87310',
	'87311',
	'87312',
	'87313',
	'87315',
	'87316',
	'87317',
	'87319',
	'87320',
	'87321',
	'87322',
	'87323',
	'87325',
	'87326',
	'87327',
	'87328',
	'87347',
	'87357',
	'87364',
	'87365',
	'87375',
	'87401',
	'87402',
	'87410',
	'87412',
	'87413',
	'87415',
	'87416',
	'87417',
	'87418',
	'87419',
	'87420',
	'87421',
	'87455',
	'87461',
	'87499',
	'87501',
	'87502',
	'87503',
	'87504',
	'87505',
	'87506',
	'87507',
	'87508',
	'87509',
	'87510',
	'87511',
	'87512',
	'87513',
	'87514',
	'87515',
	'87516',
	'87517',
	'87518',
	'87519',
	'87520',
	'87521',
	'87522',
	'87523',
	'87524',
	'87525',
	'87527',
	'87528',
	'87529',
	'87530',
	'87531',
	'87532',
	'87533',
	'87535',
	'87537',
	'87538',
	'87539',
	'87540',
	'87543',
	'87544',
	'87545',
	'87548',
	'87549',
	'87551',
	'87552',
	'87553',
	'87554',
	'87556',
	'87557',
	'87558',
	'87560',
	'87562',
	'87564',
	'87565',
	'87566',
	'87567',
	'87569',
	'87571',
	'87573',
	'87574',
	'87575',
	'87576',
	'87577',
	'87578',
	'87579',
	'87580',
	'87581',
	'87582',
	'87583',
	'87592',
	'87594',
	'87701',
	'87710',
	'87711',
	'87712',
	'87713',
	'87714',
	'87715',
	'87718',
	'87722',
	'87723',
	'87724',
	'87728',
	'87729',
	'87730',
	'87731',
	'87732',
	'87733',
	'87734',
	'87735',
	'87736',
	'87740',
	'87742',
	'87743',
	'87745',
	'87746',
	'87747',
	'87749',
	'87750',
	'87752',
	'87753',
	'87801',
	'87820',
	'87821',
	'87823',
	'87824',
	'87825',
	'87827',
	'87828',
	'87829',
	'87830',
	'87831',
	'87832',
	'87901',
	'87930',
	'87931',
	'87933',
	'87935',
	'87939',
	'87942',
	'87943',
	'88009',
	'88020',
	'88022',
	'88023',
	'88025',
	'88026',
	'88028',
	'88029',
	'88030',
	'88031',
	'88034',
	'88036',
	'88038',
	'88039',
	'88040',
	'88041',
	'88042',
	'88043',
	'88045',
	'88049',
	'88051',
	'88053',
	'88055',
	'88056',
	'88061',
	'88062',
	'88065',
	'88114',
	'88119',
	'88134',
	'88136',
	'88201',
	'88202',
	'88203',
	'88210',
	'88211',
	'88213',
	'88220',
	'88221',
	'88230',
	'88231',
	'88232',
	'88240',
	'88241',
	'88242',
	'88244',
	'88250',
	'88252',
	'88253',
	'88254',
	'88255',
	'88256',
	'88260',
	'88262',
	'88263',
	'88264',
	'88265',
	'88267',
	'88268',
	'88301',
	'88310',
	'88311',
	'88312',
	'88314',
	'88316',
	'88317',
	'88318',
	'88321',
	'88323',
	'88324',
	'88325',
	'88330',
	'88336',
	'88337',
	'88338',
	'88339',
	'88340',
	'88341',
	'88342',
	'88343',
	'88344',
	'88345',
	'88346',
	'88347',
	'88348',
	'88349',
	'88350',
	'88351',
	'88352',
	'88353',
	'88354',
	'88355',
	'88416',
	'88417',
	'88421',
	'88431',
	'88435',
	'88439',
	'39426',
	'39457',
	'39463',
	'39466',
	'39470',
	'39520',
	'39521',
	'39522',
	'39525',
	'39529',
	'39556',
	'39558',
	'39572',
	'39576',
	'70001',
	'70002',
	'70003',
	'70004',
	'70005',
	'70006',
	'70009',
	'70010',
	'70011',
	'70030',
	'70031',
	'70032',
	'70033',
	'70036',
	'70037',
	'70038',
	'70039',
	'70040',
	'70041',
	'70043',
	'70044',
	'70047',
	'70049',
	'70050',
	'70051',
	'70052',
	'70053',
	'70054',
	'70055',
	'70056',
	'70057',
	'70058',
	'70059',
	'70060',
	'70062',
	'70063',
	'70064',
	'70065',
	'70067',
	'70068',
	'70069',
	'70070',
	'70071',
	'70072',
	'70073',
	'70075',
	'70076',
	'70078',
	'70079',
	'70080',
	'70081',
	'70082',
	'70083',
	'70084',
	'70085',
	'70086',
	'70087',
	'70090',
	'70091',
	'70092',
	'70093',
	'70094',
	'70096',
	'70112',
	'70113',
	'70114',
	'70115',
	'70116',
	'70117',
	'70118',
	'70119',
	'70121',
	'70122',
	'70123',
	'70124',
	'70125',
	'70126',
	'70127',
	'70128',
	'70129',
	'70130',
	'70131',
	'70139',
	'70140',
	'70141',
	'70142',
	'70143',
	'70146',
	'70148',
	'70150',
	'70151',
	'70152',
	'70153',
	'70154',
	'70156',
	'70157',
	'70158',
	'70159',
	'70160',
	'70161',
	'70162',
	'70163',
	'70164',
	'70165',
	'70166',
	'70167',
	'70170',
	'70172',
	'70174',
	'70175',
	'70176',
	'70177',
	'70178',
	'70179',
	'70181',
	'70182',
	'70183',
	'70184',
	'70185',
	'70186',
	'70187',
	'70189',
	'70190',
	'70195',
	'70301',
	'70302',
	'70310',
	'70343',
	'70344',
	'70345',
	'70352',
	'70353',
	'70354',
	'70355',
	'70356',
	'70357',
	'70358',
	'70359',
	'70360',
	'70361',
	'70363',
	'70364',
	'70371',
	'70373',
	'70374',
	'70375',
	'70377',
	'70394',
	'70395',
	'70397',
	'70401',
	'70402',
	'70403',
	'70404',
	'70420',
	'70421',
	'70422',
	'70426',
	'70427',
	'70429',
	'70431',
	'70433',
	'70434',
	'70435',
	'70436',
	'70437',
	'70438',
	'70442',
	'70443',
	'70444',
	'70445',
	'70446',
	'70447',
	'70448',
	'70450',
	'70451',
	'70452',
	'70454',
	'70455',
	'70456',
	'70457',
	'70458',
	'70459',
	'70460',
	'70461',
	'70463',
	'70464',
	'70465',
	'70466',
	'70467',
	'70469',
	'70470',
	'70471',
	'70723',
	'70743',
	'70763',
	'48809',
	'48811',
	'48812',
	'48815',
	'48818',
	'48829',
	'48834',
	'48838',
	'48845',
	'48846',
	'48849',
	'48850',
	'48851',
	'48852',
	'48860',
	'48865',
	'48870',
	'48873',
	'48875',
	'48881',
	'48884',
	'48885',
	'48886',
	'48887',
	'48888',
	'48891',
	'48897',
	'49001',
	'49002',
	'49003',
	'49004',
	'49005',
	'49006',
	'49007',
	'49008',
	'49009',
	'49010',
	'49011',
	'49012',
	'49013',
	'49014',
	'49015',
	'49016',
	'49017',
	'49018',
	'49019',
	'49020',
	'49024',
	'49026',
	'49027',
	'49028',
	'49029',
	'49030',
	'49032',
	'49033',
	'49034',
	'49035',
	'49036',
	'49037',
	'49040',
	'49041',
	'49042',
	'49043',
	'49045',
	'49046',
	'49048',
	'49050',
	'49051',
	'49052',
	'49053',
	'49055',
	'49056',
	'49057',
	'49058',
	'49060',
	'49062',
	'49063',
	'49064',
	'49065',
	'49066',
	'49068',
	'49069',
	'49070',
	'49071',
	'49072',
	'49073',
	'49074',
	'49075',
	'49077',
	'49078',
	'49079',
	'49080',
	'49081',
	'49082',
	'49083',
	'49087',
	'49088',
	'49089',
	'49090',
	'49091',
	'49092',
	'49093',
	'49094',
	'49097',
	'49099',
	'49224',
	'49245',
	'49255',
	'49301',
	'49302',
	'49303',
	'49306',
	'49309',
	'49311',
	'49312',
	'49314',
	'49315',
	'49316',
	'49317',
	'49318',
	'49319',
	'49321',
	'49322',
	'49323',
	'49325',
	'49326',
	'49327',
	'49328',
	'49329',
	'49330',
	'49331',
	'49333',
	'49335',
	'49337',
	'49339',
	'49341',
	'49343',
	'49344',
	'49345',
	'49347',
	'49348',
	'49349',
	'49351',
	'49355',
	'49356',
	'49357',
	'49401',
	'49403',
	'49404',
	'49406',
	'49408',
	'49409',
	'49412',
	'49413',
	'49415',
	'49416',
	'49417',
	'49418',
	'49419',
	'49420',
	'49421',
	'49422',
	'49423',
	'49424',
	'49425',
	'49426',
	'49427',
	'49428',
	'49429',
	'49430',
	'49434',
	'49435',
	'49436',
	'49437',
	'49440',
	'49441',
	'49442',
	'49443',
	'49444',
	'49445',
	'49446',
	'49448',
	'49449',
	'49450',
	'49451',
	'49452',
	'49453',
	'49455',
	'49456',
	'49457',
	'49459',
	'49460',
	'49461',
	'49463',
	'49464',
	'49468',
	'49501',
	'49502',
	'49503',
	'49504',
	'49505',
	'49506',
	'49507',
	'49508',
	'49509',
	'49510',
	'49512',
	'49514',
	'49515',
	'49516',
	'49518',
	'49519',
	'49523',
	'49525',
	'49528',
	'49530',
	'49534',
	'49544',
	'49546',
	'49548',
	'49550',
	'49555',
	'49560',
	'49588',
	'83322',
	'83327',
	'83337',
	'83601',
	'83602',
	'83604',
	'83605',
	'83606',
	'83607',
	'83610',
	'83611',
	'83612',
	'83615',
	'83616',
	'83617',
	'83619',
	'83622',
	'83623',
	'83624',
	'83626',
	'83627',
	'83628',
	'83629',
	'83630',
	'83631',
	'83632',
	'83633',
	'83634',
	'83635',
	'83636',
	'83637',
	'83638',
	'83639',
	'83641',
	'83642',
	'83643',
	'83644',
	'83645',
	'83646',
	'83647',
	'83648',
	'83650',
	'83651',
	'83652',
	'83653',
	'83654',
	'83655',
	'83656',
	'83657',
	'83660',
	'83661',
	'83666',
	'83669',
	'83670',
	'83672',
	'83676',
	'83677',
	'83680',
	'83686',
	'83687',
	'83701',
	'83702',
	'83703',
	'83704',
	'83705',
	'83706',
	'83707',
	'83708',
	'83709',
	'83711',
	'83712',
	'83713',
	'83714',
	'83715',
	'83716',
	'83717',
	'83719',
	'83720',
	'83721',
	'83722',
	'83724',
	'83725',
	'83726',
	'83727',
	'83728',
	'83729',
	'83730',
	'83731',
	'83735',
	'83756',
	'83757',
	'97901',
	'97902',
	'97903',
	'97906',
	'97908',
	'97909',
	'97910',
	'97911',
	'97913',
	'97914',
	'97917',
	'97918',
	'97920',
	'89010',
	'89013',
	'89047',
	'89310',
	'89402',
	'89403',
	'89404',
	'89405',
	'89406',
	'89407',
	'89408',
	'89410',
	'89411',
	'89412',
	'89413',
	'89414',
	'89415',
	'89418',
	'89419',
	'89420',
	'89421',
	'89422',
	'89423',
	'89424',
	'89425',
	'89426',
	'89427',
	'89428',
	'89429',
	'89430',
	'89431',
	'89432',
	'89433',
	'89434',
	'89435',
	'89436',
	'89438',
	'89439',
	'89440',
	'89441',
	'89442',
	'89444',
	'89445',
	'89446',
	'89447',
	'89448',
	'89449',
	'89450',
	'89451',
	'89452',
	'89460',
	'89496',
	'89501',
	'89502',
	'89503',
	'89504',
	'89505',
	'89506',
	'89507',
	'89508',
	'89509',
	'89510',
	'89511',
	'89512',
	'89513',
	'89515',
	'89519',
	'89520',
	'89521',
	'89523',
	'89533',
	'89555',
	'89557',
	'89570',
	'89595',
	'89599',
	'89701',
	'89702',
	'89703',
	'89704',
	'89705',
	'89706',
	'89711',
	'89713',
	'89714',
	'89721',
	'89820',
	'93512',
	'93517',
	'93529',
	'93541',
	'93546',
	'95646',
	'96009',
	'96056',
	'96068',
	'96107',
	'96109',
	'96113',
	'96114',
	'96117',
	'96119',
	'96120',
	'96121',
	'96123',
	'96127',
	'96128',
	'96130',
	'96132',
	'96133',
	'96136',
	'96137',
	'35004',
	'35005',
	'35006',
	'35007',
	'35013',
	'35014',
	'35015',
	'35019',
	'35020',
	'35021',
	'35022',
	'35023',
	'35031',
	'35032',
	'35033',
	'35034',
	'35035',
	'35036',
	'35038',
	'35040',
	'35041',
	'35042',
	'35043',
	'35044',
	'35045',
	'35046',
	'35048',
	'35049',
	'35051',
	'35052',
	'35053',
	'35054',
	'35055',
	'35056',
	'35057',
	'35058',
	'35060',
	'35061',
	'35062',
	'35063',
	'35064',
	'35068',
	'35070',
	'35071',
	'35072',
	'35073',
	'35074',
	'35077',
	'35078',
	'35079',
	'35080',
	'35082',
	'35083',
	'35085',
	'35087',
	'35089',
	'35091',
	'35094',
	'35096',
	'35097',
	'35098',
	'35111',
	'35112',
	'35114',
	'35115',
	'35116',
	'35117',
	'35118',
	'35119',
	'35120',
	'35121',
	'35123',
	'35124',
	'35125',
	'35126',
	'35127',
	'35128',
	'35130',
	'35131',
	'35133',
	'35135',
	'35136',
	'35137',
	'35139',
	'35142',
	'35143',
	'35144',
	'35146',
	'35147',
	'35148',
	'35149',
	'35150',
	'35151',
	'35160',
	'35161',
	'35171',
	'35172',
	'35173',
	'35176',
	'35178',
	'35179',
	'35180',
	'35181',
	'35182',
	'35183',
	'35184',
	'35185',
	'35186',
	'35187',
	'35188',
	'35201',
	'35202',
	'35203',
	'35204',
	'35205',
	'35206',
	'35207',
	'35208',
	'35209',
	'35210',
	'35211',
	'35212',
	'35213',
	'35214',
	'35215',
	'35216',
	'35217',
	'35218',
	'35219',
	'35220',
	'35221',
	'35222',
	'35223',
	'35224',
	'35225',
	'35226',
	'35228',
	'35229',
	'35230',
	'35231',
	'35232',
	'35233',
	'35234',
	'35235',
	'35236',
	'35237',
	'35238',
	'35240',
	'35242',
	'35243',
	'35244',
	'35245',
	'35246',
	'35249',
	'35253',
	'35254',
	'35255',
	'35259',
	'35260',
	'35261',
	'35263',
	'35266',
	'35277',
	'35278',
	'35279',
	'35280',
	'35281',
	'35282',
	'35283',
	'35285',
	'35286',
	'35287',
	'35288',
	'35290',
	'35291',
	'35292',
	'35293',
	'35294',
	'35295',
	'35296',
	'35297',
	'35298',
	'35299',
	'35401',
	'35402',
	'35403',
	'35404',
	'35405',
	'35406',
	'35407',
	'35440',
	'35441',
	'35442',
	'35443',
	'35444',
	'35446',
	'35447',
	'35448',
	'35449',
	'35452',
	'35453',
	'35456',
	'35457',
	'35458',
	'35461',
	'35462',
	'35463',
	'35466',
	'35468',
	'35469',
	'35471',
	'35473',
	'35474',
	'35475',
	'35476',
	'35478',
	'35480',
	'35481',
	'35482',
	'35486',
	'35487',
	'35490',
	'35491',
	'35501',
	'35502',
	'35503',
	'35504',
	'35540',
	'35541',
	'35542',
	'35543',
	'35545',
	'35546',
	'35548',
	'35549',
	'35550',
	'35551',
	'35553',
	'35554',
	'35555',
	'35559',
	'35560',
	'35563',
	'35564',
	'35565',
	'35570',
	'35572',
	'35573',
	'35575',
	'35577',
	'35578',
	'35579',
	'35580',
	'35584',
	'35587',
	'35594',
	'35901',
	'35902',
	'35903',
	'35904',
	'35905',
	'35906',
	'35907',
	'35952',
	'35953',
	'35954',
	'35956',
	'35959',
	'35960',
	'35972',
	'35973',
	'35983',
	'35987',
	'35990',
	'36026',
	'36091',
	'36201',
	'36202',
	'36203',
	'36204',
	'36205',
	'36206',
	'36207',
	'36250',
	'36251',
	'36253',
	'36254',
	'36255',
	'36257',
	'36258',
	'36260',
	'36265',
	'36266',
	'36267',
	'36268',
	'36271',
	'36272',
	'36275',
	'36277',
	'36279',
	'36740',
	'36744',
	'36750',
	'36765',
	'36776',
	'36790',
	'36792',
	'36793',
	'32501',
	'32502',
	'32503',
	'32504',
	'32505',
	'32506',
	'32507',
	'32508',
	'32509',
	'32511',
	'32512',
	'32513',
	'32514',
	'32516',
	'32520',
	'32521',
	'32522',
	'32523',
	'32524',
	'32526',
	'32530',
	'32531',
	'32533',
	'32534',
	'32535',
	'32536',
	'32537',
	'32539',
	'32540',
	'32541',
	'32542',
	'32544',
	'32547',
	'32548',
	'32549',
	'32559',
	'32560',
	'32561',
	'32562',
	'32563',
	'32564',
	'32565',
	'32566',
	'32567',
	'32568',
	'32569',
	'32570',
	'32571',
	'32572',
	'32577',
	'32578',
	'32579',
	'32580',
	'32583',
	'32588',
	'32591',
	'36401',
	'36425',
	'36426',
	'36427',
	'36429',
	'36432',
	'36436',
	'36439',
	'36441',
	'36444',
	'36445',
	'36446',
	'36449',
	'36451',
	'36454',
	'36457',
	'36458',
	'36460',
	'36461',
	'36462',
	'36470',
	'36471',
	'36473',
	'36475',
	'36480',
	'36481',
	'36482',
	'36501',
	'36502',
	'36503',
	'36504',
	'36505',
	'36507',
	'36509',
	'36511',
	'36512',
	'36513',
	'36515',
	'36518',
	'36521',
	'36522',
	'36523',
	'36524',
	'36525',
	'36526',
	'36527',
	'36528',
	'36529',
	'36530',
	'36532',
	'36533',
	'36535',
	'36536',
	'36538',
	'36539',
	'36540',
	'36541',
	'36542',
	'36543',
	'36544',
	'36545',
	'36547',
	'36548',
	'36549',
	'36550',
	'36551',
	'36553',
	'36555',
	'36556',
	'36558',
	'36559',
	'36560',
	'36561',
	'36562',
	'36564',
	'36567',
	'36568',
	'36569',
	'36571',
	'36572',
	'36574',
	'36575',
	'36576',
	'36577',
	'36578',
	'36579',
	'36580',
	'36581',
	'36582',
	'36583',
	'36584',
	'36585',
	'36587',
	'36590',
	'36601',
	'36602',
	'36603',
	'36604',
	'36605',
	'36606',
	'36607',
	'36608',
	'36609',
	'36610',
	'36611',
	'36612',
	'36613',
	'36615',
	'36616',
	'36617',
	'36618',
	'36619',
	'36621',
	'36622',
	'36625',
	'36628',
	'36630',
	'36633',
	'36640',
	'36644',
	'36652',
	'36660',
	'36663',
	'36670',
	'36671',
	'36675',
	'36685',
	'36688',
	'36689',
	'36690',
	'36691',
	'36693',
	'36695',
	'36727',
	'36762',
	'36784',
	'39362',
	'39451',
	'39456',
	'39461',
	'50001',
	'50002',
	'50003',
	'50005',
	'50006',
	'50007',
	'50008',
	'50009',
	'50010',
	'50011',
	'50012',
	'50013',
	'50014',
	'50021',
	'50023',
	'50025',
	'50026',
	'50027',
	'50028',
	'50029',
	'50031',
	'50032',
	'50033',
	'50034',
	'50035',
	'50036',
	'50037',
	'50038',
	'50039',
	'50040',
	'50041',
	'50042',
	'50043',
	'50044',
	'50046',
	'50047',
	'50048',
	'50049',
	'50050',
	'50051',
	'50052',
	'50054',
	'50055',
	'50056',
	'50057',
	'50058',
	'50059',
	'50060',
	'50061',
	'50062',
	'50063',
	'50064',
	'50065',
	'50066',
	'50067',
	'50068',
	'50069',
	'50070',
	'50071',
	'50072',
	'50073',
	'50074',
	'50075',
	'50076',
	'50078',
	'50101',
	'50102',
	'50103',
	'50105',
	'50106',
	'50107',
	'50108',
	'50109',
	'50110',
	'50111',
	'50112',
	'50115',
	'50116',
	'50117',
	'50118',
	'50119',
	'50120',
	'50122',
	'50123',
	'50124',
	'50125',
	'50126',
	'50127',
	'50128',
	'50129',
	'50130',
	'50131',
	'50132',
	'50133',
	'50134',
	'50135',
	'50137',
	'50138',
	'50139',
	'50140',
	'50141',
	'50142',
	'50143',
	'50144',
	'50145',
	'50146',
	'50147',
	'50148',
	'50149',
	'50150',
	'50151',
	'50152',
	'50153',
	'50154',
	'50155',
	'50156',
	'50157',
	'50158',
	'50160',
	'50161',
	'50162',
	'50163',
	'50164',
	'50165',
	'50166',
	'50167',
	'50168',
	'50169',
	'50170',
	'50171',
	'50174',
	'50201',
	'50206',
	'50207',
	'50208',
	'50210',
	'50211',
	'50212',
	'50213',
	'50214',
	'50216',
	'50217',
	'50218',
	'50219',
	'50220',
	'50222',
	'50223',
	'50225',
	'50226',
	'50227',
	'50228',
	'50229',
	'50230',
	'50231',
	'50232',
	'50233',
	'50234',
	'50235',
	'50236',
	'50237',
	'50238',
	'50239',
	'50240',
	'50241',
	'50242',
	'50243',
	'50244',
	'50246',
	'50247',
	'50248',
	'50249',
	'50250',
	'50251',
	'50252',
	'50254',
	'50256',
	'50257',
	'50258',
	'50259',
	'50261',
	'50262',
	'50263',
	'50264',
	'50265',
	'50266',
	'50269',
	'50271',
	'50272',
	'50273',
	'50275',
	'50276',
	'50277',
	'50278',
	'50301',
	'50302',
	'50303',
	'50304',
	'50305',
	'50306',
	'50307',
	'50309',
	'50310',
	'50311',
	'50312',
	'50313',
	'50314',
	'50315',
	'50316',
	'50317',
	'50318',
	'50319',
	'50320',
	'50321',
	'50322',
	'50323',
	'50324',
	'50325',
	'50327',
	'50328',
	'50329',
	'50330',
	'50331',
	'50332',
	'50333',
	'50334',
	'50335',
	'50336',
	'50339',
	'50340',
	'50347',
	'50360',
	'50361',
	'50362',
	'50363',
	'50367',
	'50368',
	'50369',
	'50381',
	'50391',
	'50392',
	'50393',
	'50394',
	'50398',
	'50420',
	'50421',
	'50427',
	'50431',
	'50441',
	'50451',
	'50452',
	'50470',
	'50475',
	'50480',
	'50483',
	'50501',
	'50511',
	'50516',
	'50517',
	'50518',
	'50519',
	'50520',
	'50521',
	'50522',
	'50523',
	'50524',
	'50525',
	'50526',
	'50529',
	'50530',
	'50532',
	'50533',
	'50538',
	'50539',
	'50540',
	'50541',
	'50542',
	'50543',
	'50544',
	'50545',
	'50546',
	'50548',
	'50551',
	'50552',
	'50554',
	'50556',
	'50557',
	'50558',
	'50559',
	'50560',
	'50561',
	'50563',
	'50566',
	'50569',
	'50570',
	'50571',
	'50573',
	'50574',
	'50575',
	'50577',
	'50579',
	'50581',
	'50582',
	'50586',
	'50590',
	'50591',
	'50593',
	'50594',
	'50595',
	'50598',
	'50599',
	'50601',
	'50627',
	'50633',
	'50672',
	'50801',
	'50830',
	'50831',
	'50833',
	'50835',
	'50836',
	'50837',
	'50839',
	'50840',
	'50841',
	'50842',
	'50845',
	'50846',
	'50848',
	'50849',
	'50851',
	'50854',
	'50857',
	'50858',
	'50859',
	'50860',
	'50861',
	'50862',
	'50863',
	'50936',
	'50947',
	'50950',
	'50981',
	'51401',
	'51430',
	'51436',
	'51440',
	'51443',
	'51444',
	'51449',
	'51451',
	'51452',
	'51453',
	'51455',
	'51459',
	'51462',
	'51463',
	'51543',
	'51646',
	'52211',
	'52221',
	'52222',
	'52232',
	'52531',
	'52534',
	'52543',
	'52544',
	'52549',
	'52555',
	'52561',
	'52569',
	'52571',
	'52572',
	'52574',
	'52577',
	'52581',
	'52583',
	'52586',
	'52590',
	'52593',
	'52594',
	'52595',
	'22427',
	'22428',
	'22432',
	'22435',
	'22436',
	'22437',
	'22438',
	'22446',
	'22454',
	'22456',
	'22460',
	'22472',
	'22473',
	'22476',
	'22480',
	'22482',
	'22501',
	'22503',
	'22504',
	'22507',
	'22509',
	'22511',
	'22513',
	'22514',
	'22517',
	'22523',
	'22528',
	'22530',
	'22535',
	'22538',
	'22539',
	'22546',
	'22548',
	'22552',
	'22560',
	'22570',
	'22572',
	'22576',
	'22578',
	'22579',
	'22580',
	'23002',
	'23004',
	'23005',
	'23009',
	'23011',
	'23014',
	'23015',
	'23023',
	'23024',
	'23027',
	'23030',
	'23031',
	'23032',
	'23038',
	'23039',
	'23040',
	'23043',
	'23047',
	'23058',
	'23059',
	'23060',
	'23063',
	'23065',
	'23067',
	'23069',
	'23070',
	'23071',
	'23075',
	'23079',
	'23083',
	'23085',
	'23086',
	'23089',
	'23091',
	'23092',
	'23093',
	'23101',
	'23102',
	'23103',
	'23105',
	'23106',
	'23108',
	'23110',
	'23111',
	'23112',
	'23113',
	'23114',
	'23115',
	'23116',
	'23117',
	'23120',
	'23123',
	'23124',
	'23126',
	'23129',
	'23139',
	'23140',
	'23141',
	'23146',
	'23147',
	'23148',
	'23149',
	'23150',
	'23153',
	'23156',
	'23160',
	'23161',
	'23162',
	'23169',
	'23170',
	'23173',
	'23175',
	'23176',
	'23177',
	'23180',
	'23181',
	'23192',
	'23218',
	'23219',
	'23220',
	'23221',
	'23222',
	'23223',
	'23224',
	'23225',
	'23226',
	'23227',
	'23228',
	'23229',
	'23230',
	'23231',
	'23232',
	'23233',
	'23234',
	'23235',
	'23236',
	'23237',
	'23238',
	'23240',
	'23241',
	'23242',
	'23249',
	'23250',
	'23255',
	'23260',
	'23261',
	'23269',
	'23273',
	'23274',
	'23276',
	'23278',
	'23279',
	'23282',
	'23284',
	'23285',
	'23286',
	'23288',
	'23289',
	'23290',
	'23291',
	'23292',
	'23293',
	'23294',
	'23295',
	'23297',
	'23298',
	'23801',
	'23803',
	'23804',
	'23805',
	'23806',
	'23821',
	'23824',
	'23830',
	'23831',
	'23832',
	'23833',
	'23834',
	'23836',
	'23838',
	'23840',
	'23841',
	'23842',
	'23843',
	'23845',
	'23847',
	'23850',
	'23856',
	'23857',
	'23860',
	'23867',
	'23868',
	'23870',
	'23872',
	'23873',
	'23875',
	'23876',
	'23879',
	'23882',
	'23884',
	'23885',
	'23887',
	'23888',
	'23889',
	'23890',
	'23891',
	'23893',
	'23894',
	'23897',
	'23901',
	'23909',
	'23920',
	'23921',
	'23922',
	'23930',
	'23936',
	'23938',
	'23941',
	'23942',
	'23943',
	'23944',
	'23952',
	'23954',
	'23955',
	'23960',
	'23966',
	'23974',
	'24562',
	'24599',
	'53501',
	'53502',
	'53503',
	'53504',
	'53505',
	'53506',
	'53507',
	'53508',
	'53510',
	'53511',
	'53512',
	'53515',
	'53516',
	'53517',
	'53518',
	'53520',
	'53521',
	'53522',
	'53523',
	'53525',
	'53526',
	'53527',
	'53528',
	'53529',
	'53530',
	'53531',
	'53532',
	'53533',
	'53534',
	'53535',
	'53536',
	'53537',
	'53540',
	'53541',
	'53542',
	'53543',
	'53544',
	'53545',
	'53546',
	'53547',
	'53548',
	'53550',
	'53553',
	'53554',
	'53555',
	'53556',
	'53558',
	'53559',
	'53560',
	'53561',
	'53562',
	'53563',
	'53565',
	'53566',
	'53569',
	'53570',
	'53571',
	'53572',
	'53573',
	'53574',
	'53575',
	'53576',
	'53577',
	'53578',
	'53580',
	'53581',
	'53582',
	'53583',
	'53584',
	'53586',
	'53587',
	'53588',
	'53589',
	'53590',
	'53593',
	'53595',
	'53596',
	'53597',
	'53598',
	'53599',
	'53701',
	'53702',
	'53703',
	'53704',
	'53705',
	'53706',
	'53707',
	'53708',
	'53711',
	'53713',
	'53714',
	'53715',
	'53716',
	'53717',
	'53718',
	'53719',
	'53725',
	'53726',
	'53744',
	'53774',
	'53777',
	'53778',
	'53779',
	'53782',
	'53783',
	'53784',
	'53785',
	'53786',
	'53788',
	'53789',
	'53790',
	'53791',
	'53792',
	'53793',
	'53794',
	'53801',
	'53802',
	'53803',
	'53804',
	'53805',
	'53806',
	'53807',
	'53808',
	'53809',
	'53810',
	'53811',
	'53812',
	'53813',
	'53816',
	'53817',
	'53818',
	'53820',
	'53824',
	'53825',
	'53827',
	'53901',
	'53911',
	'53913',
	'53920',
	'53923',
	'53924',
	'53925',
	'53928',
	'53929',
	'53930',
	'53932',
	'53935',
	'53937',
	'53940',
	'53941',
	'53942',
	'53943',
	'53944',
	'53948',
	'53949',
	'53950',
	'53951',
	'53952',
	'53953',
	'53954',
	'53955',
	'53957',
	'53958',
	'53959',
	'53960',
	'53961',
	'53962',
	'53964',
	'53965',
	'53968',
	'53969',
	'54618',
	'54637',
	'54641',
	'54646',
	'54664',
	'54960',
	'78040',
	'78041',
	'78042',
	'78043',
	'78044',
	'78045',
	'78046',
	'78049',
	'78067',
	'78076',
	'78344',
	'78369',
	'78371',
	'78564',
	'93201',
	'93202',
	'93204',
	'93207',
	'93208',
	'93210',
	'93212',
	'93218',
	'93219',
	'93221',
	'93223',
	'93227',
	'93230',
	'93232',
	'93234',
	'93235',
	'93237',
	'93239',
	'93242',
	'93244',
	'93245',
	'93246',
	'93247',
	'93256',
	'93257',
	'93258',
	'93260',
	'93261',
	'93262',
	'93265',
	'93266',
	'93267',
	'93270',
	'93271',
	'93272',
	'93274',
	'93275',
	'93277',
	'93278',
	'93279',
	'93282',
	'93286',
	'93290',
	'93291',
	'93292',
	'93601',
	'93602',
	'93603',
	'93604',
	'93605',
	'93606',
	'93607',
	'93608',
	'93609',
	'93610',
	'93611',
	'93612',
	'93613',
	'93614',
	'93615',
	'93616',
	'93618',
	'93619',
	'93620',
	'93621',
	'93622',
	'93623',
	'93624',
	'93625',
	'93626',
	'93627',
	'93628',
	'93630',
	'93631',
	'93633',
	'93634',
	'93635',
	'93636',
	'93637',
	'93638',
	'93639',
	'93640',
	'93641',
	'93642',
	'93643',
	'93644',
	'93645',
	'93646',
	'93647',
	'93648',
	'93649',
	'93650',
	'93651',
	'93652',
	'93653',
	'93654',
	'93656',
	'93657',
	'93660',
	'93661',
	'93662',
	'93664',
	'93665',
	'93666',
	'93667',
	'93668',
	'93669',
	'93670',
	'93673',
	'93675',
	'93701',
	'93702',
	'93703',
	'93704',
	'93705',
	'93706',
	'93707',
	'93708',
	'93709',
	'93710',
	'93711',
	'93712',
	'93714',
	'93715',
	'93716',
	'93717',
	'93718',
	'93720',
	'93721',
	'93722',
	'93723',
	'93724',
	'93725',
	'93726',
	'93727',
	'93728',
	'93729',
	'93730',
	'93740',
	'93741',
	'93744',
	'93745',
	'93747',
	'93750',
	'93755',
	'93760',
	'93761',
	'93764',
	'93765',
	'93771',
	'93772',
	'93773',
	'93774',
	'93775',
	'93776',
	'93777',
	'93778',
	'93779',
	'93780',
	'93784',
	'93786',
	'93790',
	'93791',
	'93792',
	'93793',
	'93794',
	'93844',
	'93888',
	'95301',
	'95303',
	'95306',
	'95311',
	'95312',
	'95315',
	'95317',
	'95318',
	'95322',
	'95324',
	'95325',
	'95333',
	'95334',
	'95338',
	'95340',
	'95341',
	'95343',
	'95344',
	'95345',
	'95348',
	'95365',
	'95369',
	'95374',
	'95388',
	'95389',
	'17001',
	'17002',
	'17003',
	'17004',
	'17005',
	'17006',
	'17007',
	'17008',
	'17009',
	'17010',
	'17011',
	'17012',
	'17013',
	'17014',
	'17015',
	'17016',
	'17018',
	'17019',
	'17020',
	'17021',
	'17022',
	'17023',
	'17024',
	'17025',
	'17026',
	'17027',
	'17028',
	'17029',
	'17030',
	'17032',
	'17033',
	'17034',
	'17035',
	'17036',
	'17037',
	'17038',
	'17039',
	'17040',
	'17041',
	'17042',
	'17043',
	'17044',
	'17045',
	'17046',
	'17047',
	'17048',
	'17049',
	'17050',
	'17051',
	'17053',
	'17054',
	'17055',
	'17056',
	'17057',
	'17058',
	'17059',
	'17061',
	'17062',
	'17063',
	'17064',
	'17065',
	'17067',
	'17068',
	'17069',
	'17070',
	'17071',
	'17072',
	'17073',
	'17074',
	'17075',
	'17076',
	'17077',
	'17078',
	'17080',
	'17081',
	'17082',
	'17083',
	'17084',
	'17085',
	'17086',
	'17087',
	'17088',
	'17089',
	'17090',
	'17093',
	'17094',
	'17097',
	'17098',
	'17099',
	'17101',
	'17102',
	'17103',
	'17104',
	'17105',
	'17106',
	'17107',
	'17108',
	'17109',
	'17110',
	'17111',
	'17112',
	'17113',
	'17120',
	'17121',
	'17122',
	'17124',
	'17125',
	'17126',
	'17127',
	'17128',
	'17129',
	'17130',
	'17140',
	'17177',
	'17201',
	'17202',
	'17210',
	'17214',
	'17217',
	'17219',
	'17220',
	'17221',
	'17222',
	'17224',
	'17225',
	'17231',
	'17232',
	'17235',
	'17236',
	'17237',
	'17240',
	'17241',
	'17244',
	'17246',
	'17247',
	'17250',
	'17251',
	'17252',
	'17254',
	'17256',
	'17257',
	'17261',
	'17262',
	'17263',
	'17265',
	'17266',
	'17268',
	'17270',
	'17271',
	'17272',
	'17301',
	'17302',
	'17303',
	'17304',
	'17306',
	'17307',
	'17309',
	'17310',
	'17311',
	'17312',
	'17313',
	'17314',
	'17315',
	'17316',
	'17317',
	'17318',
	'17319',
	'17320',
	'17321',
	'17322',
	'17323',
	'17324',
	'17325',
	'17326',
	'17327',
	'17329',
	'17331',
	'17332',
	'17333',
	'17334',
	'17337',
	'17339',
	'17340',
	'17342',
	'17343',
	'17344',
	'17345',
	'17347',
	'17349',
	'17350',
	'17352',
	'17353',
	'17354',
	'17355',
	'17356',
	'17358',
	'17360',
	'17361',
	'17362',
	'17363',
	'17364',
	'17365',
	'17366',
	'17368',
	'17370',
	'17371',
	'17372',
	'17375',
	'17401',
	'17402',
	'17403',
	'17404',
	'17405',
	'17406',
	'17407',
	'17408',
	'17415',
	'17501',
	'17502',
	'17503',
	'17504',
	'17505',
	'17506',
	'17507',
	'17508',
	'17509',
	'17512',
	'17516',
	'17517',
	'17518',
	'17519',
	'17520',
	'17521',
	'17522',
	'17527',
	'17528',
	'17529',
	'17532',
	'17533',
	'17534',
	'17535',
	'17536',
	'17537',
	'17538',
	'17540',
	'17543',
	'17545',
	'17547',
	'17549',
	'17550',
	'17551',
	'17552',
	'17554',
	'17555',
	'17557',
	'17560',
	'17562',
	'17563',
	'17564',
	'17565',
	'17566',
	'17567',
	'17568',
	'17569',
	'17570',
	'17572',
	'17573',
	'17575',
	'17576',
	'17578',
	'17579',
	'17580',
	'17581',
	'17582',
	'17583',
	'17584',
	'17585',
	'17601',
	'17602',
	'17603',
	'17604',
	'17605',
	'17606',
	'17607',
	'17608',
	'17622',
	'17699',
	'17841',
	'19501',
	'40003',
	'40004',
	'40006',
	'40007',
	'40008',
	'40009',
	'40010',
	'40011',
	'40012',
	'40013',
	'40014',
	'40018',
	'40019',
	'40020',
	'40022',
	'40023',
	'40025',
	'40026',
	'40027',
	'40031',
	'40032',
	'40033',
	'40036',
	'40037',
	'40040',
	'40041',
	'40045',
	'40046',
	'40047',
	'40048',
	'40049',
	'40050',
	'40051',
	'40052',
	'40055',
	'40056',
	'40057',
	'40058',
	'40059',
	'40060',
	'40061',
	'40062',
	'40063',
	'40065',
	'40066',
	'40067',
	'40068',
	'40069',
	'40070',
	'40071',
	'40075',
	'40076',
	'40077',
	'40078',
	'40104',
	'40107',
	'40108',
	'40109',
	'40110',
	'40111',
	'40115',
	'40117',
	'40118',
	'40119',
	'40121',
	'40129',
	'40140',
	'40142',
	'40143',
	'40144',
	'40145',
	'40146',
	'40150',
	'40152',
	'40153',
	'40155',
	'40157',
	'40159',
	'40160',
	'40161',
	'40162',
	'40165',
	'40170',
	'40171',
	'40175',
	'40176',
	'40177',
	'40178',
	'40201',
	'40202',
	'40203',
	'40204',
	'40205',
	'40206',
	'40207',
	'40208',
	'40209',
	'40210',
	'40211',
	'40212',
	'40213',
	'40214',
	'40215',
	'40216',
	'40217',
	'40218',
	'40219',
	'40220',
	'40221',
	'40222',
	'40223',
	'40224',
	'40225',
	'40228',
	'40229',
	'40232',
	'40233',
	'40241',
	'40242',
	'40243',
	'40245',
	'40250',
	'40251',
	'40252',
	'40253',
	'40255',
	'40256',
	'40257',
	'40258',
	'40259',
	'40261',
	'40266',
	'40268',
	'40269',
	'40270',
	'40272',
	'40280',
	'40281',
	'40282',
	'40283',
	'40285',
	'40287',
	'40289',
	'40290',
	'40291',
	'40292',
	'40293',
	'40294',
	'40296',
	'40297',
	'40298',
	'40299',
	'40328',
	'41008',
	'41045',
	'41083',
	'41098',
	'42701',
	'42702',
	'42712',
	'42715',
	'42716',
	'42718',
	'42719',
	'42720',
	'42721',
	'42724',
	'42726',
	'42728',
	'42732',
	'42733',
	'42740',
	'42741',
	'42742',
	'42743',
	'42748',
	'42753',
	'42754',
	'42755',
	'42757',
	'42758',
	'42762',
	'42764',
	'42776',
	'42782',
	'42784',
	'42788',
	'47102',
	'47104',
	'47106',
	'47107',
	'47108',
	'47110',
	'47111',
	'47112',
	'47114',
	'47115',
	'47116',
	'47117',
	'47118',
	'47119',
	'47120',
	'47122',
	'47123',
	'47124',
	'47125',
	'47126',
	'47129',
	'47130',
	'47131',
	'47132',
	'47133',
	'47134',
	'47135',
	'47136',
	'47137',
	'47138',
	'47139',
	'47140',
	'47141',
	'47142',
	'47143',
	'47144',
	'47145',
	'47146',
	'47147',
	'47150',
	'47151',
	'47160',
	'47161',
	'47162',
	'47163',
	'47164',
	'47165',
	'47166',
	'47167',
	'47170',
	'47172',
	'47174',
	'47175',
	'47177',
	'47190',
	'47199',
	'47220',
	'47223',
	'47224',
	'47227',
	'47228',
	'47229',
	'47230',
	'47231',
	'47235',
	'47243',
	'47245',
	'47249',
	'47250',
	'47260',
	'47264',
	'47265',
	'47270',
	'47273',
	'47274',
	'47281',
	'47282',
	'47432',
	'47452',
	'47454',
	'47469',
	'59631',
	'59632',
	'59634',
	'59638',
	'59701',
	'59702',
	'59703',
	'59707',
	'59710',
	'59711',
	'59713',
	'59714',
	'59715',
	'59716',
	'59717',
	'59718',
	'59719',
	'59720',
	'59721',
	'59722',
	'59724',
	'59725',
	'59727',
	'59728',
	'59729',
	'59730',
	'59731',
	'59732',
	'59733',
	'59735',
	'59736',
	'59739',
	'59740',
	'59741',
	'59743',
	'59745',
	'59746',
	'59747',
	'59748',
	'59749',
	'59750',
	'59751',
	'59752',
	'59754',
	'59755',
	'59756',
	'59758',
	'59759',
	'59760',
	'59761',
	'59762',
	'59771',
	'59772',
	'59773',
	'59843',
	'59854',
	'03215',
	'03217',
	'03222',
	'03223',
	'03238',
	'03240',
	'03241',
	'03245',
	'03251',
	'03262',
	'03264',
	'03266',
	'03274',
	'03279',
	'03280',
	'03282',
	'03284',
	'03285',
	'03293',
	'03561',
	'03574',
	'03580',
	'03585',
	'03586',
	'03601',
	'03603',
	'03605',
	'03607',
	'03740',
	'03741',
	'03743',
	'03745',
	'03746',
	'03748',
	'03749',
	'03750',
	'03751',
	'03752',
	'03753',
	'03754',
	'03755',
	'03756',
	'03765',
	'03766',
	'03768',
	'03769',
	'03770',
	'03771',
	'03773',
	'03774',
	'03777',
	'03779',
	'03780',
	'03781',
	'03782',
	'03784',
	'03785',
	'05001',
	'05009',
	'05030',
	'05031',
	'05032',
	'05033',
	'05034',
	'05035',
	'05036',
	'05037',
	'05038',
	'05039',
	'05040',
	'05041',
	'05042',
	'05043',
	'05045',
	'05046',
	'05047',
	'05048',
	'05049',
	'05050',
	'05051',
	'05052',
	'05053',
	'05054',
	'05055',
	'05056',
	'05058',
	'05059',
	'05060',
	'05061',
	'05062',
	'05065',
	'05067',
	'05068',
	'05069',
	'05070',
	'05071',
	'05072',
	'05073',
	'05074',
	'05075',
	'05076',
	'05077',
	'05079',
	'05081',
	'05083',
	'05084',
	'05085',
	'05086',
	'05088',
	'05089',
	'05091',
	'05142',
	'05143',
	'05144',
	'05149',
	'05150',
	'05151',
	'05153',
	'05156',
	'05161',
	'05401',
	'05402',
	'05403',
	'05404',
	'05405',
	'05406',
	'05407',
	'05408',
	'05439',
	'05440',
	'05441',
	'05442',
	'05443',
	'05444',
	'05445',
	'05446',
	'05447',
	'05448',
	'05449',
	'05450',
	'05451',
	'05452',
	'05453',
	'05454',
	'05455',
	'05456',
	'05457',
	'05458',
	'05459',
	'05460',
	'05461',
	'05462',
	'05463',
	'05464',
	'05465',
	'05466',
	'05468',
	'05469',
	'05470',
	'05471',
	'05472',
	'05473',
	'05474',
	'05476',
	'05477',
	'05478',
	'05479',
	'05481',
	'05482',
	'05483',
	'05485',
	'05486',
	'05487',
	'05488',
	'05489',
	'05490',
	'05491',
	'05492',
	'05494',
	'05495',
	'05601',
	'05602',
	'05603',
	'05604',
	'05609',
	'05620',
	'05633',
	'05640',
	'05641',
	'05647',
	'05648',
	'05649',
	'05650',
	'05651',
	'05652',
	'05653',
	'05654',
	'05655',
	'05656',
	'05657',
	'05658',
	'05660',
	'05661',
	'05662',
	'05663',
	'05664',
	'05665',
	'05666',
	'05667',
	'05669',
	'05670',
	'05671',
	'05672',
	'05673',
	'05674',
	'05675',
	'05676',
	'05677',
	'05678',
	'05679',
	'05680',
	'05681',
	'05682',
	'05701',
	'05702',
	'05730',
	'05731',
	'05732',
	'05733',
	'05734',
	'05735',
	'05736',
	'05737',
	'05738',
	'05739',
	'05740',
	'05741',
	'05742',
	'05743',
	'05744',
	'05745',
	'05746',
	'05747',
	'05748',
	'05750',
	'05751',
	'05753',
	'05757',
	'05758',
	'05759',
	'05760',
	'05761',
	'05762',
	'05763',
	'05764',
	'05765',
	'05766',
	'05767',
	'05769',
	'05770',
	'05772',
	'05773',
	'05774',
	'05775',
	'05777',
	'05778',
	'05819',
	'05820',
	'05821',
	'05822',
	'05823',
	'05824',
	'05825',
	'05826',
	'05827',
	'05828',
	'05829',
	'05830',
	'05832',
	'05833',
	'05836',
	'05837',
	'05838',
	'05839',
	'05840',
	'05841',
	'05842',
	'05843',
	'05845',
	'05846',
	'05847',
	'05848',
	'05849',
	'05850',
	'05851',
	'05853',
	'05855',
	'05857',
	'05858',
	'05859',
	'05860',
	'05861',
	'05862',
	'05863',
	'05866',
	'05867',
	'05868',
	'05871',
	'05872',
	'05873',
	'05874',
	'05875',
	'05901',
	'05902',
	'05903',
	'05904',
	'05905',
	'05906',
	'05907',
	'12851',
	'12852',
	'12855',
	'12857',
	'12858',
	'12870',
	'12872',
	'12879',
	'12883',
	'12901',
	'12903',
	'12910',
	'12911',
	'12912',
	'12913',
	'12914',
	'12915',
	'12916',
	'12917',
	'12918',
	'12919',
	'12920',
	'12921',
	'12923',
	'12924',
	'12926',
	'12928',
	'12929',
	'12930',
	'12932',
	'12933',
	'12934',
	'12935',
	'12936',
	'12937',
	'12939',
	'12941',
	'12942',
	'12943',
	'12944',
	'12945',
	'12946',
	'12950',
	'12952',
	'12953',
	'12955',
	'12956',
	'12957',
	'12958',
	'12959',
	'12960',
	'12961',
	'12962',
	'12964',
	'12966',
	'12969',
	'12970',
	'12972',
	'12974',
	'12975',
	'12976',
	'12977',
	'12978',
	'12979',
	'12980',
	'12981',
	'12983',
	'12985',
	'12986',
	'12987',
	'12989',
	'12992',
	'12993',
	'12995',
	'12996',
	'12997',
	'12998',
	'13655',
	'28320',
	'28332',
	'28337',
	'28392',
	'28399',
	'28401',
	'28402',
	'28403',
	'28404',
	'28405',
	'28406',
	'28407',
	'28408',
	'28409',
	'28410',
	'28411',
	'28412',
	'28420',
	'28421',
	'28422',
	'28423',
	'28424',
	'28425',
	'28428',
	'28429',
	'28430',
	'28431',
	'28432',
	'28433',
	'28434',
	'28435',
	'28436',
	'28438',
	'28439',
	'28442',
	'28443',
	'28448',
	'28449',
	'28450',
	'28451',
	'28452',
	'28454',
	'28455',
	'28456',
	'28457',
	'28459',
	'28461',
	'28462',
	'28463',
	'28465',
	'28467',
	'28468',
	'28469',
	'28470',
	'28472',
	'28478',
	'28479',
	'28480',
	'29901',
	'29902',
	'29903',
	'29904',
	'29905',
	'29906',
	'29907',
	'29909',
	'29910',
	'29911',
	'29912',
	'29913',
	'29914',
	'29915',
	'29916',
	'29918',
	'29920',
	'29921',
	'29922',
	'29923',
	'29924',
	'29925',
	'29926',
	'29927',
	'29928',
	'29931',
	'29932',
	'29933',
	'29934',
	'29935',
	'29936',
	'29938',
	'29939',
	'29940',
	'29941',
	'29943',
	'29944',
	'29945',
	'30410',
	'30412',
	'30414',
	'30415',
	'30417',
	'30420',
	'30421',
	'30423',
	'30424',
	'30427',
	'30429',
	'30436',
	'30438',
	'30439',
	'30445',
	'30446',
	'30449',
	'30450',
	'30451',
	'30452',
	'30453',
	'30455',
	'30458',
	'30459',
	'30460',
	'30461',
	'30467',
	'30470',
	'30473',
	'30474',
	'30475',
	'30499',
	'31301',
	'31302',
	'31303',
	'31304',
	'31305',
	'31307',
	'31308',
	'31309',
	'31310',
	'31312',
	'31313',
	'31314',
	'31315',
	'31316',
	'31318',
	'31319',
	'31320',
	'31321',
	'31322',
	'31323',
	'31324',
	'31326',
	'31327',
	'31328',
	'31329',
	'31331',
	'31333',
	'31401',
	'31402',
	'31403',
	'31404',
	'31405',
	'31406',
	'31407',
	'31408',
	'31409',
	'31410',
	'31411',
	'31412',
	'31414',
	'31415',
	'31416',
	'31418',
	'31419',
	'31420',
	'31421',
	'31510',
	'31513',
	'31515',
	'31532',
	'31539',
	'31545',
	'31546',
	'31555',
	'31560',
	'31563',
	'31598',
	'31599',
	'66840',
	'66842',
	'66843',
	'66845',
	'66850',
	'66851',
	'66853',
	'66855',
	'66858',
	'66859',
	'66860',
	'66861',
	'66862',
	'66863',
	'66866',
	'66869',
	'66870',
	'67001',
	'67002',
	'67003',
	'67004',
	'67005',
	'67008',
	'67009',
	'67010',
	'67012',
	'67013',
	'67016',
	'67017',
	'67018',
	'67019',
	'67020',
	'67021',
	'67022',
	'67023',
	'67025',
	'67026',
	'67028',
	'67029',
	'67030',
	'67031',
	'67035',
	'67036',
	'67037',
	'67038',
	'67039',
	'67041',
	'67042',
	'67045',
	'67047',
	'67049',
	'67050',
	'67051',
	'67052',
	'67053',
	'67054',
	'67055',
	'67056',
	'67057',
	'67058',
	'67059',
	'67060',
	'67061',
	'67062',
	'67063',
	'67065',
	'67066',
	'67067',
	'67068',
	'67070',
	'67071',
	'67072',
	'67073',
	'67074',
	'67101',
	'67102',
	'67103',
	'67104',
	'67105',
	'67106',
	'67107',
	'67108',
	'67109',
	'67110',
	'67111',
	'67112',
	'67114',
	'67117',
	'67118',
	'67119',
	'67120',
	'67122',
	'67123',
	'67124',
	'67127',
	'67131',
	'67132',
	'67133',
	'67134',
	'67135',
	'67137',
	'67138',
	'67140',
	'67142',
	'67143',
	'67144',
	'67146',
	'67147',
	'67149',
	'67150',
	'67151',
	'67152',
	'67154',
	'67155',
	'67156',
	'67159',
	'67201',
	'67202',
	'67203',
	'67204',
	'67205',
	'67206',
	'67207',
	'67208',
	'67209',
	'67210',
	'67211',
	'67212',
	'67213',
	'67214',
	'67215',
	'67216',
	'67217',
	'67218',
	'67219',
	'67220',
	'67221',
	'67223',
	'67226',
	'67227',
	'67228',
	'67230',
	'67232',
	'67235',
	'67260',
	'67275',
	'67277',
	'67278',
	'67345',
	'67346',
	'67349',
	'67352',
	'67353',
	'67401',
	'67402',
	'67410',
	'67416',
	'67418',
	'67420',
	'67422',
	'67423',
	'67425',
	'67427',
	'67428',
	'67430',
	'67431',
	'67436',
	'67437',
	'67438',
	'67439',
	'67441',
	'67442',
	'67443',
	'67444',
	'67446',
	'67448',
	'67449',
	'67450',
	'67451',
	'67452',
	'67454',
	'67455',
	'67456',
	'67457',
	'67459',
	'67460',
	'67464',
	'67467',
	'67470',
	'67473',
	'67474',
	'67475',
	'67476',
	'67478',
	'67480',
	'67481',
	'67482',
	'67483',
	'67484',
	'67485',
	'67490',
	'67491',
	'67492',
	'67501',
	'67502',
	'67504',
	'67505',
	'67510',
	'67511',
	'67512',
	'67513',
	'67514',
	'67515',
	'67516',
	'67518',
	'67519',
	'67520',
	'67521',
	'67522',
	'67523',
	'67524',
	'67525',
	'67526',
	'67529',
	'67530',
	'67543',
	'67544',
	'67545',
	'67546',
	'67547',
	'67548',
	'67550',
	'67552',
	'67553',
	'67554',
	'67556',
	'67557',
	'67559',
	'67560',
	'67561',
	'67563',
	'67564',
	'67565',
	'67566',
	'67567',
	'67568',
	'67570',
	'67572',
	'67573',
	'67574',
	'67575',
	'67576',
	'67578',
	'67579',
	'67581',
	'67583',
	'67584',
	'67585',
	'67601',
	'67622',
	'67623',
	'67625',
	'67626',
	'67627',
	'67629',
	'67631',
	'67632',
	'67634',
	'67635',
	'67637',
	'67640',
	'67642',
	'67643',
	'67645',
	'67648',
	'67649',
	'67650',
	'67651',
	'67653',
	'67654',
	'67656',
	'67657',
	'67658',
	'67659',
	'67660',
	'67663',
	'67665',
	'67667',
	'67669',
	'67671',
	'67672',
	'67673',
	'67674',
	'67675',
	'67701',
	'67730',
	'67731',
	'67732',
	'67733',
	'67734',
	'67735',
	'67736',
	'67737',
	'67738',
	'67739',
	'67740',
	'67741',
	'67743',
	'67744',
	'67745',
	'67747',
	'67748',
	'67749',
	'67751',
	'67752',
	'67753',
	'67756',
	'67757',
	'67758',
	'67761',
	'67762',
	'67764',
	'67801',
	'67831',
	'67834',
	'67835',
	'67836',
	'67837',
	'67838',
	'67839',
	'67840',
	'67841',
	'67842',
	'67843',
	'67844',
	'67846',
	'67849',
	'67850',
	'67851',
	'67853',
	'67854',
	'67855',
	'67857',
	'67859',
	'67860',
	'67861',
	'67862',
	'67863',
	'67864',
	'67865',
	'67867',
	'67868',
	'67869',
	'67870',
	'67871',
	'67876',
	'67877',
	'67878',
	'67879',
	'67880',
	'67882',
	'67901',
	'67905',
	'67950',
	'67951',
	'67952',
	'67953',
	'67954',
	'69021',
	'69030',
	'69037',
	'69041',
	'49812',
	'49821',
	'49845',
	'49847',
	'49848',
	'49858',
	'49863',
	'49873',
	'49874',
	'49886',
	'49887',
	'49893',
	'49896',
	'53010',
	'53014',
	'53015',
	'53019',
	'53042',
	'53049',
	'53057',
	'53061',
	'53062',
	'53063',
	'53065',
	'53079',
	'53088',
	'53919',
	'53926',
	'53931',
	'53939',
	'53946',
	'53947',
	'54101',
	'54102',
	'54104',
	'54106',
	'54107',
	'54110',
	'54111',
	'54112',
	'54113',
	'54114',
	'54115',
	'54119',
	'54123',
	'54124',
	'54125',
	'54126',
	'54127',
	'54128',
	'54129',
	'54130',
	'54131',
	'54135',
	'54136',
	'54137',
	'54138',
	'54139',
	'54140',
	'54141',
	'54143',
	'54149',
	'54150',
	'54151',
	'54152',
	'54153',
	'54154',
	'54155',
	'54156',
	'54157',
	'54159',
	'54160',
	'54161',
	'54162',
	'54165',
	'54166',
	'54169',
	'54170',
	'54171',
	'54173',
	'54174',
	'54175',
	'54177',
	'54180',
	'54182',
	'54201',
	'54202',
	'54204',
	'54205',
	'54207',
	'54208',
	'54209',
	'54210',
	'54211',
	'54212',
	'54213',
	'54214',
	'54215',
	'54216',
	'54217',
	'54220',
	'54221',
	'54226',
	'54227',
	'54228',
	'54229',
	'54230',
	'54232',
	'54234',
	'54235',
	'54240',
	'54241',
	'54245',
	'54246',
	'54247',
	'54301',
	'54302',
	'54303',
	'54304',
	'54305',
	'54306',
	'54307',
	'54308',
	'54311',
	'54313',
	'54324',
	'54344',
	'54414',
	'54416',
	'54450',
	'54486',
	'54499',
	'54901',
	'54902',
	'54903',
	'54904',
	'54906',
	'54911',
	'54912',
	'54913',
	'54914',
	'54915',
	'54919',
	'54922',
	'54923',
	'54926',
	'54927',
	'54928',
	'54929',
	'54930',
	'54931',
	'54932',
	'54933',
	'54934',
	'54935',
	'54936',
	'54937',
	'54940',
	'54941',
	'54942',
	'54943',
	'54944',
	'54945',
	'54946',
	'54947',
	'54948',
	'54949',
	'54950',
	'54952',
	'54956',
	'54957',
	'54961',
	'54962',
	'54963',
	'54964',
	'54965',
	'54966',
	'54967',
	'54968',
	'54969',
	'54970',
	'54971',
	'54974',
	'54976',
	'54977',
	'54978',
	'54979',
	'54980',
	'54981',
	'54982',
	'54983',
	'54984',
	'54985',
	'54986',
	'54990',
	'79821',
	'79835',
	'79836',
	'79837',
	'79838',
	'79839',
	'79847',
	'79849',
	'79851',
	'79853',
	'79855',
	'79901',
	'79902',
	'79903',
	'79904',
	'79905',
	'79906',
	'79907',
	'79908',
	'79910',
	'79911',
	'79912',
	'79913',
	'79914',
	'79915',
	'79916',
	'79917',
	'79918',
	'79920',
	'79922',
	'79923',
	'79924',
	'79925',
	'79926',
	'79927',
	'79928',
	'79929',
	'79930',
	'79931',
	'79932',
	'79934',
	'79935',
	'79936',
	'79937',
	'79938',
	'79940',
	'79941',
	'79942',
	'79943',
	'79944',
	'79945',
	'79946',
	'79947',
	'79948',
	'79949',
	'79950',
	'79951',
	'79952',
	'79953',
	'79954',
	'79955',
	'79958',
	'79960',
	'79961',
	'79968',
	'79976',
	'79978',
	'79980',
	'79995',
	'79996',
	'79997',
	'79998',
	'79999',
	'87936',
	'87937',
	'87940',
	'87941',
	'88001',
	'88002',
	'88003',
	'88004',
	'88005',
	'88006',
	'88007',
	'88008',
	'88011',
	'88012',
	'88013',
	'88021',
	'88024',
	'88027',
	'88032',
	'88033',
	'88044',
	'88046',
	'88047',
	'88048',
	'88052',
	'88054',
	'88058',
	'88063',
	'88072',
	'88081',
	'88510',
	'88520',
	'88538',
	'88540',
	'88590',
	'88595',
	'40310',
	'40311',
	'40312',
	'40313',
	'40316',
	'40317',
	'40319',
	'40322',
	'40324',
	'40330',
	'40334',
	'40336',
	'40337',
	'40339',
	'40340',
	'40342',
	'40346',
	'40347',
	'40348',
	'40350',
	'40351',
	'40353',
	'40356',
	'40357',
	'40358',
	'40360',
	'40361',
	'40362',
	'40366',
	'40370',
	'40371',
	'40372',
	'40374',
	'40376',
	'40379',
	'40380',
	'40383',
	'40384',
	'40385',
	'40386',
	'40387',
	'40390',
	'40391',
	'40392',
	'40402',
	'40403',
	'40404',
	'40405',
	'40409',
	'40410',
	'40419',
	'40422',
	'40423',
	'40434',
	'40437',
	'40440',
	'40442',
	'40444',
	'40445',
	'40446',
	'40447',
	'40448',
	'40452',
	'40456',
	'40460',
	'40461',
	'40464',
	'40468',
	'40472',
	'40473',
	'40475',
	'40476',
	'40481',
	'40484',
	'40486',
	'40488',
	'40489',
	'40492',
	'40495',
	'40502',
	'40503',
	'40504',
	'40505',
	'40506',
	'40507',
	'40508',
	'40509',
	'40510',
	'40511',
	'40512',
	'40513',
	'40514',
	'40515',
	'40516',
	'40517',
	'40522',
	'40523',
	'40524',
	'40526',
	'40533',
	'40536',
	'40544',
	'40546',
	'40550',
	'40555',
	'40574',
	'40575',
	'40576',
	'40577',
	'40578',
	'40579',
	'40580',
	'40581',
	'40582',
	'40583',
	'40588',
	'40591',
	'40598',
	'40601',
	'40602',
	'40603',
	'40604',
	'40618',
	'40619',
	'40620',
	'40621',
	'40622',
	'40701',
	'40702',
	'40724',
	'40729',
	'40730',
	'40734',
	'40737',
	'40740',
	'40741',
	'40743',
	'40744',
	'40745',
	'40754',
	'40755',
	'40759',
	'40763',
	'40769',
	'40771',
	'40903',
	'40906',
	'40914',
	'40915',
	'40921',
	'40923',
	'40930',
	'40932',
	'40935',
	'40941',
	'40943',
	'40944',
	'40946',
	'40949',
	'40951',
	'40953',
	'40962',
	'40972',
	'40981',
	'40982',
	'40983',
	'40995',
	'40997',
	'40999',
	'41003',
	'41031',
	'41037',
	'41039',
	'41041',
	'41049',
	'41065',
	'41081',
	'41093',
	'41301',
	'41307',
	'41310',
	'41311',
	'41313',
	'41314',
	'41317',
	'41332',
	'41333',
	'41338',
	'41339',
	'41347',
	'41348',
	'41351',
	'41352',
	'41360',
	'41362',
	'41364',
	'41365',
	'41366',
	'41367',
	'41368',
	'41385',
	'41386',
	'41390',
	'41397',
	'41408',
	'41413',
	'41421',
	'41425',
	'41426',
	'41433',
	'41451',
	'41459',
	'41464',
	'41465',
	'41472',
	'41477',
	'41632',
	'41701',
	'41702',
	'41712',
	'41713',
	'41719',
	'41721',
	'41722',
	'41723',
	'41725',
	'41727',
	'41729',
	'41731',
	'41735',
	'41736',
	'41739',
	'41740',
	'41743',
	'41745',
	'41746',
	'41747',
	'41751',
	'41754',
	'41759',
	'41760',
	'41763',
	'41772',
	'41773',
	'41774',
	'41778',
	'41817',
	'41822',
	'41828',
	'41831',
	'41834',
	'41836',
	'41839',
	'41843',
	'41844',
	'41847',
	'41859',
	'41861',
	'41862',
	'42501',
	'42502',
	'42503',
	'42516',
	'42518',
	'42519',
	'42528',
	'42533',
	'42539',
	'42541',
	'42544',
	'42553',
	'42558',
	'42564',
	'42565',
	'42566',
	'42567',
	'42629',
	'42633',
	'42642',
	'50104',
	'50136',
	'50173',
	'50255',
	'50268',
	'50602',
	'50603',
	'50604',
	'50605',
	'50606',
	'50607',
	'50608',
	'50609',
	'50611',
	'50612',
	'50613',
	'50614',
	'50619',
	'50621',
	'50622',
	'50623',
	'50624',
	'50625',
	'50626',
	'50629',
	'50630',
	'50631',
	'50632',
	'50634',
	'50635',
	'50636',
	'50638',
	'50641',
	'50642',
	'50643',
	'50644',
	'50645',
	'50647',
	'50648',
	'50649',
	'50650',
	'50651',
	'50652',
	'50654',
	'50655',
	'50657',
	'50658',
	'50659',
	'50660',
	'50661',
	'50662',
	'50664',
	'50665',
	'50666',
	'50667',
	'50668',
	'50669',
	'50670',
	'50671',
	'50673',
	'50674',
	'50675',
	'50676',
	'50677',
	'50680',
	'50681',
	'50682',
	'50701',
	'50702',
	'50703',
	'50704',
	'50706',
	'50707',
	'52001',
	'52002',
	'52003',
	'52004',
	'52032',
	'52033',
	'52035',
	'52036',
	'52038',
	'52039',
	'52040',
	'52041',
	'52042',
	'52043',
	'52044',
	'52045',
	'52046',
	'52047',
	'52048',
	'52049',
	'52050',
	'52052',
	'52053',
	'52056',
	'52057',
	'52065',
	'52066',
	'52068',
	'52072',
	'52073',
	'52076',
	'52077',
	'52078',
	'52079',
	'52101',
	'52132',
	'52133',
	'52135',
	'52140',
	'52141',
	'52142',
	'52144',
	'52146',
	'52147',
	'52149',
	'52151',
	'52154',
	'52156',
	'52157',
	'52158',
	'52159',
	'52160',
	'52161',
	'52162',
	'52164',
	'52165',
	'52166',
	'52168',
	'52169',
	'52170',
	'52171',
	'52172',
	'52175',
	'52201',
	'52202',
	'52203',
	'52204',
	'52205',
	'52206',
	'52208',
	'52209',
	'52210',
	'52212',
	'52213',
	'52214',
	'52215',
	'52216',
	'52217',
	'52218',
	'52219',
	'52220',
	'52223',
	'52224',
	'52225',
	'52227',
	'52228',
	'52229',
	'52231',
	'52233',
	'52235',
	'52236',
	'52237',
	'52240',
	'52241',
	'52242',
	'52243',
	'52244',
	'52245',
	'52246',
	'52247',
	'52248',
	'52249',
	'52251',
	'52252',
	'52253',
	'52255',
	'52257',
	'52301',
	'52302',
	'52305',
	'52306',
	'52307',
	'52308',
	'52310',
	'52312',
	'52313',
	'52314',
	'52315',
	'52316',
	'52317',
	'52318',
	'52319',
	'52320',
	'52321',
	'52322',
	'52323',
	'52324',
	'52325',
	'52326',
	'52327',
	'52328',
	'52329',
	'52330',
	'52332',
	'52333',
	'52334',
	'52335',
	'52336',
	'52337',
	'52338',
	'52339',
	'52340',
	'52341',
	'52342',
	'52344',
	'52345',
	'52346',
	'52347',
	'52348',
	'52349',
	'52350',
	'52351',
	'52352',
	'52353',
	'52354',
	'52355',
	'52356',
	'52358',
	'52359',
	'52361',
	'52362',
	'52401',
	'52402',
	'52403',
	'52404',
	'52405',
	'52406',
	'52407',
	'52408',
	'52409',
	'52410',
	'52411',
	'52498',
	'52499',
	'52540',
	'52550',
	'52562',
	'52563',
	'52568',
	'52576',
	'52585',
	'52591',
	'52621',
	'52721',
	'52747',
	'52755',
	'52772',
	'97324',
	'97326',
	'97330',
	'97331',
	'97333',
	'97339',
	'97370',
	'97401',
	'97402',
	'97403',
	'97404',
	'97405',
	'97407',
	'97408',
	'97409',
	'97410',
	'97411',
	'97412',
	'97413',
	'97414',
	'97416',
	'97417',
	'97419',
	'97420',
	'97423',
	'97424',
	'97426',
	'97427',
	'97428',
	'97429',
	'97430',
	'97431',
	'97432',
	'97434',
	'97435',
	'97436',
	'97437',
	'97438',
	'97439',
	'97440',
	'97441',
	'97442',
	'97443',
	'97447',
	'97448',
	'97449',
	'97451',
	'97452',
	'97453',
	'97454',
	'97455',
	'97456',
	'97457',
	'97458',
	'97459',
	'97461',
	'97462',
	'97463',
	'97466',
	'97467',
	'97469',
	'97470',
	'97471',
	'97472',
	'97473',
	'97477',
	'97478',
	'97479',
	'97480',
	'97481',
	'97482',
	'97484',
	'97486',
	'97487',
	'97488',
	'97489',
	'97490',
	'97492',
	'97493',
	'97494',
	'97495',
	'97496',
	'97499',
	'14001',
	'14004',
	'14005',
	'14006',
	'14008',
	'14009',
	'14010',
	'14011',
	'14012',
	'14013',
	'14020',
	'14021',
	'14024',
	'14025',
	'14026',
	'14027',
	'14028',
	'14029',
	'14030',
	'14031',
	'14032',
	'14033',
	'14034',
	'14035',
	'14036',
	'14037',
	'14038',
	'14039',
	'14040',
	'14041',
	'14042',
	'14043',
	'14047',
	'14048',
	'14051',
	'14052',
	'14054',
	'14055',
	'14056',
	'14057',
	'14058',
	'14059',
	'14060',
	'14061',
	'14062',
	'14063',
	'14065',
	'14066',
	'14067',
	'14068',
	'14069',
	'14070',
	'14072',
	'14075',
	'14080',
	'14081',
	'14082',
	'14083',
	'14085',
	'14086',
	'14091',
	'14092',
	'14094',
	'14095',
	'14098',
	'14101',
	'14102',
	'14103',
	'14105',
	'14107',
	'14108',
	'14109',
	'14110',
	'14111',
	'14112',
	'14113',
	'14120',
	'14125',
	'14126',
	'14127',
	'14129',
	'14130',
	'14131',
	'14132',
	'14133',
	'14134',
	'14135',
	'14136',
	'14138',
	'14139',
	'14140',
	'14141',
	'14143',
	'14144',
	'14145',
	'14150',
	'14151',
	'14166',
	'14167',
	'14168',
	'14169',
	'14170',
	'14171',
	'14172',
	'14173',
	'14174',
	'14201',
	'14202',
	'14203',
	'14204',
	'14205',
	'14206',
	'14207',
	'14208',
	'14209',
	'14210',
	'14211',
	'14212',
	'14213',
	'14214',
	'14215',
	'14216',
	'14217',
	'14218',
	'14219',
	'14220',
	'14221',
	'14222',
	'14223',
	'14224',
	'14225',
	'14226',
	'14227',
	'14228',
	'14231',
	'14233',
	'14240',
	'14241',
	'14260',
	'14261',
	'14263',
	'14264',
	'14265',
	'14267',
	'14269',
	'14270',
	'14272',
	'14273',
	'14276',
	'14280',
	'14301',
	'14302',
	'14303',
	'14304',
	'14305',
	'14411',
	'14416',
	'14422',
	'14427',
	'14429',
	'14452',
	'14470',
	'14476',
	'14477',
	'14479',
	'14482',
	'14486',
	'14525',
	'14530',
	'14536',
	'14549',
	'14550',
	'14557',
	'14569',
	'14571',
	'14591',
	'14701',
	'14702',
	'14706',
	'14707',
	'14708',
	'14709',
	'14710',
	'14711',
	'14712',
	'14714',
	'14715',
	'14716',
	'14717',
	'14718',
	'14719',
	'14720',
	'14721',
	'14722',
	'14723',
	'14724',
	'14726',
	'14727',
	'14728',
	'14729',
	'14730',
	'14731',
	'14732',
	'14733',
	'14735',
	'14736',
	'14737',
	'14738',
	'14739',
	'14740',
	'14741',
	'14742',
	'14743',
	'14744',
	'14745',
	'14747',
	'14748',
	'14750',
	'14751',
	'14752',
	'14753',
	'14754',
	'14755',
	'14756',
	'14757',
	'14758',
	'14760',
	'14766',
	'14767',
	'14769',
	'14770',
	'14772',
	'14774',
	'14775',
	'14777',
	'14778',
	'14779',
	'14781',
	'14782',
	'14783',
	'14784',
	'14785',
	'14786',
	'14787',
	'14788',
	'14802',
	'14803',
	'14804',
	'14806',
	'14813',
	'14822',
	'14880',
	'14884',
	'14895',
	'14897',
	'16333',
	'16701',
	'16720',
	'16724',
	'16725',
	'16726',
	'16727',
	'16729',
	'16730',
	'16731',
	'16732',
	'16733',
	'16735',
	'16738',
	'16740',
	'16743',
	'16744',
	'16745',
	'16746',
	'16748',
	'16749',
	'16750',
	'16915',
	'16922',
	'16923',
	'16927',
	'16937',
	'16941',
	'16948',
	'17729',
	'76932',
	'78851',
	'79511',
	'79701',
	'79702',
	'79703',
	'79704',
	'79705',
	'79706',
	'79707',
	'79708',
	'79710',
	'79711',
	'79712',
	'79713',
	'79714',
	'79718',
	'79719',
	'79720',
	'79721',
	'79730',
	'79731',
	'79733',
	'79734',
	'79735',
	'79739',
	'79740',
	'79741',
	'79742',
	'79743',
	'79744',
	'79745',
	'79748',
	'79749',
	'79752',
	'79754',
	'79755',
	'79756',
	'79758',
	'79759',
	'79760',
	'79761',
	'79762',
	'79763',
	'79764',
	'79765',
	'79766',
	'79768',
	'79769',
	'79770',
	'79772',
	'79776',
	'79777',
	'79778',
	'79780',
	'79781',
	'79782',
	'79783',
	'79785',
	'79786',
	'79788',
	'79789',
	'79830',
	'79831',
	'79832',
	'79834',
	'79842',
	'79843',
	'79845',
	'79846',
	'79848',
	'79852',
	'79854',
	'97701',
	'97702',
	'97707',
	'97708',
	'97709',
	'97712',
	'97739',
	'97756',
	'97759',
	'60911',
	'60912',
	'60918',
	'60919',
	'60922',
	'60924',
	'60926',
	'60927',
	'60928',
	'60930',
	'60931',
	'60932',
	'60933',
	'60936',
	'60938',
	'60939',
	'60942',
	'60945',
	'60946',
	'60948',
	'60949',
	'60951',
	'60952',
	'60953',
	'60955',
	'60956',
	'60957',
	'60959',
	'60960',
	'60962',
	'60963',
	'60966',
	'60967',
	'60968',
	'60970',
	'60973',
	'60974',
	'61723',
	'61727',
	'61735',
	'61749',
	'61750',
	'61751',
	'61756',
	'61773',
	'61777',
	'61778',
	'61801',
	'61802',
	'61803',
	'61810',
	'61811',
	'61812',
	'61813',
	'61814',
	'61815',
	'61816',
	'61817',
	'61818',
	'61820',
	'61821',
	'61822',
	'61824',
	'61825',
	'61826',
	'61830',
	'61831',
	'61832',
	'61833',
	'61834',
	'61839',
	'61840',
	'61841',
	'61842',
	'61843',
	'61844',
	'61845',
	'61846',
	'61847',
	'61848',
	'61849',
	'61850',
	'61851',
	'61852',
	'61853',
	'61854',
	'61855',
	'61856',
	'61857',
	'61858',
	'61859',
	'61862',
	'61863',
	'61864',
	'61865',
	'61866',
	'61870',
	'61871',
	'61872',
	'61873',
	'61874',
	'61875',
	'61876',
	'61877',
	'61878',
	'61880',
	'61882',
	'61883',
	'61884',
	'61910',
	'61911',
	'61912',
	'61913',
	'61914',
	'61917',
	'61919',
	'61920',
	'61924',
	'61925',
	'61928',
	'61929',
	'61930',
	'61931',
	'61932',
	'61933',
	'61936',
	'61937',
	'61938',
	'61940',
	'61941',
	'61942',
	'61943',
	'61944',
	'61949',
	'61951',
	'61953',
	'61955',
	'61956',
	'61957',
	'62083',
	'62401',
	'62411',
	'62414',
	'62422',
	'62424',
	'62426',
	'62428',
	'62431',
	'62435',
	'62436',
	'62438',
	'62440',
	'62443',
	'62444',
	'62445',
	'62447',
	'62461',
	'62462',
	'62463',
	'62465',
	'62467',
	'62468',
	'62469',
	'62473',
	'62501',
	'62510',
	'62512',
	'62513',
	'62514',
	'62515',
	'62517',
	'62518',
	'62519',
	'62520',
	'62521',
	'62522',
	'62523',
	'62524',
	'62525',
	'62526',
	'62530',
	'62531',
	'62532',
	'62534',
	'62535',
	'62536',
	'62537',
	'62539',
	'62540',
	'62541',
	'62543',
	'62544',
	'62545',
	'62546',
	'62547',
	'62548',
	'62549',
	'62550',
	'62551',
	'62553',
	'62554',
	'62555',
	'62556',
	'62557',
	'62558',
	'62561',
	'62563',
	'62565',
	'62567',
	'62568',
	'62570',
	'62571',
	'62573',
	'62601',
	'62611',
	'62612',
	'62613',
	'62615',
	'62618',
	'62622',
	'62625',
	'62627',
	'62628',
	'62629',
	'62631',
	'62634',
	'62635',
	'62638',
	'62642',
	'62643',
	'62650',
	'62651',
	'62656',
	'62659',
	'62660',
	'62661',
	'62662',
	'62665',
	'62666',
	'62668',
	'62670',
	'62671',
	'62673',
	'62675',
	'62677',
	'62684',
	'62688',
	'62689',
	'62691',
	'62692',
	'62693',
	'62695',
	'62701',
	'62702',
	'62703',
	'62704',
	'62705',
	'62706',
	'62707',
	'62708',
	'62711',
	'62712',
	'62713',
	'62715',
	'62716',
	'62719',
	'62721',
	'62722',
	'62723',
	'62726',
	'62736',
	'62739',
	'62746',
	'62756',
	'62761',
	'62762',
	'62763',
	'62764',
	'62765',
	'62766',
	'62767',
	'62769',
	'62776',
	'62777',
	'62781',
	'62786',
	'62791',
	'62794',
	'62796',
	'39631',
	'39633',
	'39638',
	'39645',
	'39664',
	'39669',
	'70339',
	'70340',
	'70341',
	'70342',
	'70346',
	'70372',
	'70380',
	'70381',
	'70390',
	'70391',
	'70392',
	'70393',
	'70441',
	'70449',
	'70453',
	'70462',
	'70514',
	'70522',
	'70523',
	'70538',
	'70540',
	'70704',
	'70706',
	'70707',
	'70710',
	'70711',
	'70712',
	'70714',
	'70715',
	'70718',
	'70719',
	'70721',
	'70722',
	'70725',
	'70726',
	'70727',
	'70728',
	'70729',
	'70730',
	'70732',
	'70733',
	'70734',
	'70736',
	'70737',
	'70738',
	'70739',
	'70740',
	'70744',
	'70747',
	'70748',
	'70749',
	'70752',
	'70753',
	'70754',
	'70755',
	'70756',
	'70757',
	'70759',
	'70760',
	'70761',
	'70762',
	'70764',
	'70765',
	'70767',
	'70769',
	'70770',
	'70772',
	'70773',
	'70774',
	'70775',
	'70776',
	'70777',
	'70778',
	'70780',
	'70782',
	'70783',
	'70784',
	'70785',
	'70786',
	'70787',
	'70788',
	'70789',
	'70791',
	'70801',
	'70802',
	'70803',
	'70804',
	'70805',
	'70806',
	'70807',
	'70808',
	'70809',
	'70810',
	'70811',
	'70812',
	'70813',
	'70814',
	'70815',
	'70816',
	'70817',
	'70818',
	'70819',
	'70820',
	'70821',
	'70822',
	'70823',
	'70825',
	'70826',
	'70827',
	'70831',
	'70833',
	'70835',
	'70836',
	'70837',
	'70874',
	'70879',
	'70883',
	'70884',
	'70891',
	'70892',
	'70893',
	'70894',
	'70895',
	'70896',
	'70898',
	'71601',
	'71602',
	'71603',
	'71611',
	'71612',
	'71613',
	'71630',
	'71631',
	'71639',
	'71643',
	'71644',
	'71647',
	'71651',
	'71652',
	'71654',
	'71655',
	'71656',
	'71657',
	'71659',
	'71660',
	'71662',
	'71665',
	'71666',
	'71667',
	'71670',
	'71671',
	'71674',
	'71675',
	'71677',
	'71678',
	'71701',
	'71711',
	'71720',
	'71721',
	'71722',
	'71725',
	'71726',
	'71728',
	'71742',
	'71743',
	'71744',
	'71745',
	'71748',
	'71751',
	'71763',
	'71764',
	'71766',
	'71772',
	'71828',
	'71835',
	'71844',
	'71857',
	'71858',
	'71864',
	'71901',
	'71902',
	'71903',
	'71909',
	'71910',
	'71913',
	'71914',
	'71920',
	'71921',
	'71922',
	'71923',
	'71929',
	'71932',
	'71933',
	'71935',
	'71937',
	'71940',
	'71941',
	'71942',
	'71943',
	'71944',
	'71945',
	'71949',
	'71950',
	'71951',
	'71952',
	'71953',
	'71956',
	'71957',
	'71958',
	'71959',
	'71960',
	'71961',
	'71962',
	'71964',
	'71965',
	'71966',
	'71968',
	'71969',
	'71970',
	'71972',
	'71973',
	'71998',
	'71999',
	'72001',
	'72002',
	'72003',
	'72004',
	'72005',
	'72006',
	'72007',
	'72010',
	'72011',
	'72012',
	'72013',
	'72014',
	'72015',
	'72016',
	'72017',
	'72018',
	'72019',
	'72020',
	'72021',
	'72022',
	'72023',
	'72024',
	'72025',
	'72026',
	'72027',
	'72028',
	'72029',
	'72030',
	'72031',
	'72032',
	'72033',
	'72034',
	'72035',
	'72036',
	'72037',
	'72038',
	'72039',
	'72040',
	'72041',
	'72042',
	'72043',
	'72044',
	'72045',
	'72046',
	'72047',
	'72048',
	'72051',
	'72052',
	'72053',
	'72055',
	'72057',
	'72058',
	'72059',
	'72060',
	'72061',
	'72063',
	'72064',
	'72065',
	'72066',
	'72067',
	'72068',
	'72069',
	'72070',
	'72072',
	'72073',
	'72074',
	'72075',
	'72076',
	'72078',
	'72079',
	'72080',
	'72081',
	'72082',
	'72083',
	'72084',
	'72085',
	'72086',
	'72087',
	'72088',
	'72089',
	'72099',
	'72101',
	'72102',
	'72103',
	'72104',
	'72105',
	'72106',
	'72107',
	'72108',
	'72110',
	'72111',
	'72112',
	'72113',
	'72114',
	'72115',
	'72116',
	'72117',
	'72118',
	'72119',
	'72120',
	'72121',
	'72122',
	'72123',
	'72124',
	'72125',
	'72126',
	'72127',
	'72128',
	'72129',
	'72130',
	'72131',
	'72132',
	'72133',
	'72134',
	'72135',
	'72136',
	'72137',
	'72139',
	'72140',
	'72141',
	'72142',
	'72143',
	'72145',
	'72149',
	'72150',
	'72152',
	'72153',
	'72156',
	'72157',
	'72160',
	'72164',
	'72165',
	'72166',
	'72167',
	'72168',
	'72169',
	'72170',
	'72173',
	'72175',
	'72176',
	'72178',
	'72179',
	'72180',
	'72181',
	'72182',
	'72183',
	'72189',
	'72190',
	'72198',
	'72199',
	'72201',
	'72202',
	'72203',
	'72204',
	'72205',
	'72206',
	'72207',
	'72209',
	'72210',
	'72211',
	'72212',
	'72214',
	'72215',
	'72216',
	'72217',
	'72219',
	'72221',
	'72222',
	'72223',
	'72225',
	'72227',
	'72231',
	'72260',
	'72295',
	'72379',
	'72431',
	'72471',
	'72473',
	'72501',
	'72503',
	'72512',
	'72517',
	'72519',
	'72522',
	'72523',
	'72524',
	'72526',
	'72527',
	'72528',
	'72530',
	'72533',
	'72534',
	'72536',
	'72540',
	'72543',
	'72545',
	'72546',
	'72550',
	'72553',
	'72555',
	'72556',
	'72560',
	'72561',
	'72562',
	'72564',
	'72565',
	'72566',
	'72567',
	'72568',
	'72571',
	'72573',
	'72575',
	'72579',
	'72581',
	'72584',
	'72585',
	'72587',
	'72629',
	'72636',
	'72639',
	'72645',
	'72650',
	'72657',
	'72663',
	'72669',
	'72675',
	'72679',
	'72680',
	'72686',
	'72801',
	'72802',
	'72811',
	'72812',
	'72823',
	'72824',
	'72827',
	'72828',
	'72829',
	'72833',
	'72834',
	'72837',
	'72838',
	'72842',
	'72843',
	'72847',
	'72853',
	'72857',
	'72858',
	'72860',
	'16822',
	'16848',
	'16910',
	'16914',
	'16925',
	'16926',
	'16945',
	'16947',
	'17017',
	'17701',
	'17702',
	'17703',
	'17705',
	'17720',
	'17721',
	'17723',
	'17724',
	'17726',
	'17727',
	'17728',
	'17730',
	'17731',
	'17735',
	'17737',
	'17738',
	'17739',
	'17740',
	'17742',
	'17744',
	'17745',
	'17747',
	'17748',
	'17749',
	'17750',
	'17751',
	'17752',
	'17754',
	'17756',
	'17758',
	'17760',
	'17762',
	'17763',
	'17764',
	'17767',
	'17768',
	'17769',
	'17771',
	'17772',
	'17773',
	'17774',
	'17776',
	'17777',
	'17778',
	'17779',
	'17801',
	'17810',
	'17812',
	'17813',
	'17814',
	'17815',
	'17820',
	'17821',
	'17822',
	'17823',
	'17824',
	'17827',
	'17829',
	'17830',
	'17831',
	'17832',
	'17833',
	'17834',
	'17835',
	'17836',
	'17837',
	'17839',
	'17840',
	'17842',
	'17843',
	'17844',
	'17845',
	'17846',
	'17847',
	'17850',
	'17851',
	'17853',
	'17855',
	'17856',
	'17857',
	'17858',
	'17859',
	'17860',
	'17861',
	'17862',
	'17864',
	'17865',
	'17866',
	'17867',
	'17868',
	'17870',
	'17872',
	'17876',
	'17877',
	'17878',
	'17880',
	'17881',
	'17882',
	'17883',
	'17884',
	'17885',
	'17886',
	'17887',
	'17888',
	'17889',
	'17901',
	'17920',
	'17921',
	'17922',
	'17923',
	'17925',
	'17929',
	'17930',
	'17931',
	'17932',
	'17933',
	'17934',
	'17935',
	'17936',
	'17938',
	'17941',
	'17942',
	'17943',
	'17944',
	'17945',
	'17946',
	'17948',
	'17949',
	'17951',
	'17952',
	'17953',
	'17954',
	'17957',
	'17959',
	'17960',
	'17961',
	'17963',
	'17964',
	'17965',
	'17966',
	'17967',
	'17968',
	'17970',
	'17972',
	'17974',
	'17976',
	'17978',
	'17979',
	'17980',
	'17981',
	'17982',
	'17983',
	'17985',
	'18012',
	'18030',
	'18058',
	'18071',
	'18201',
	'18202',
	'18210',
	'18211',
	'18212',
	'18214',
	'18216',
	'18218',
	'18219',
	'18220',
	'18221',
	'18222',
	'18223',
	'18224',
	'18225',
	'18229',
	'18230',
	'18231',
	'18232',
	'18234',
	'18235',
	'18237',
	'18239',
	'18240',
	'18241',
	'18242',
	'18244',
	'18245',
	'18246',
	'18247',
	'18248',
	'18249',
	'18250',
	'18251',
	'18252',
	'18254',
	'18255',
	'18256',
	'18301',
	'18302',
	'18320',
	'18321',
	'18322',
	'18323',
	'18325',
	'18326',
	'18327',
	'18330',
	'18331',
	'18332',
	'18333',
	'18334',
	'18335',
	'18341',
	'18342',
	'18344',
	'18346',
	'18347',
	'18348',
	'18349',
	'18350',
	'18352',
	'18353',
	'18354',
	'18355',
	'18356',
	'18357',
	'18360',
	'18370',
	'18372',
	'18403',
	'18405',
	'18407',
	'18410',
	'18411',
	'18413',
	'18414',
	'18415',
	'18416',
	'18417',
	'18419',
	'18420',
	'18421',
	'18424',
	'18427',
	'18430',
	'18431',
	'18433',
	'18434',
	'18436',
	'18437',
	'18438',
	'18439',
	'18440',
	'18441',
	'18443',
	'18444',
	'18445',
	'18446',
	'18447',
	'18448',
	'18449',
	'18452',
	'18453',
	'18454',
	'18455',
	'18456',
	'18459',
	'18460',
	'18461',
	'18462',
	'18463',
	'18465',
	'18466',
	'18469',
	'18470',
	'18471',
	'18472',
	'18473',
	'18501',
	'18502',
	'18503',
	'18504',
	'18505',
	'18507',
	'18508',
	'18509',
	'18510',
	'18512',
	'18514',
	'18515',
	'18517',
	'18518',
	'18519',
	'18522',
	'18577',
	'18601',
	'18602',
	'18603',
	'18610',
	'18611',
	'18612',
	'18614',
	'18615',
	'18616',
	'18617',
	'18618',
	'18619',
	'18621',
	'18622',
	'18623',
	'18624',
	'18625',
	'18626',
	'18627',
	'18628',
	'18629',
	'18630',
	'18631',
	'18632',
	'18634',
	'18635',
	'18636',
	'18640',
	'18641',
	'18642',
	'18643',
	'18644',
	'18651',
	'18653',
	'18654',
	'18655',
	'18656',
	'18657',
	'18660',
	'18661',
	'18690',
	'18701',
	'18702',
	'18703',
	'18704',
	'18705',
	'18706',
	'18707',
	'18708',
	'18709',
	'18710',
	'18711',
	'18762',
	'18764',
	'18765',
	'18766',
	'18767',
	'18769',
	'18773',
	'18801',
	'18810',
	'18812',
	'18813',
	'18814',
	'18815',
	'18816',
	'18817',
	'18818',
	'18820',
	'18821',
	'18822',
	'18823',
	'18824',
	'18825',
	'18826',
	'18827',
	'18828',
	'18829',
	'18830',
	'18831',
	'18832',
	'18833',
	'18834',
	'18837',
	'18840',
	'18842',
	'18843',
	'18844',
	'18845',
	'18846',
	'18847',
	'18848',
	'18850',
	'18851',
	'18853',
	'18854',
	'19549',
	'48411',
	'48413',
	'48414',
	'48415',
	'48417',
	'48418',
	'48420',
	'48423',
	'48429',
	'48430',
	'48432',
	'48433',
	'48435',
	'48436',
	'48437',
	'48438',
	'48439',
	'48441',
	'48445',
	'48449',
	'48451',
	'48457',
	'48458',
	'48460',
	'48463',
	'48467',
	'48468',
	'48470',
	'48473',
	'48475',
	'48476',
	'48480',
	'48501',
	'48502',
	'48503',
	'48504',
	'48505',
	'48506',
	'48507',
	'48509',
	'48519',
	'48529',
	'48531',
	'48532',
	'48550',
	'48551',
	'48552',
	'48553',
	'48554',
	'48555',
	'48556',
	'48557',
	'48559',
	'48601',
	'48602',
	'48603',
	'48604',
	'48605',
	'48606',
	'48607',
	'48608',
	'48609',
	'48610',
	'48611',
	'48612',
	'48613',
	'48614',
	'48615',
	'48616',
	'48618',
	'48620',
	'48623',
	'48624',
	'48626',
	'48628',
	'48631',
	'48634',
	'48635',
	'48637',
	'48638',
	'48640',
	'48641',
	'48642',
	'48649',
	'48650',
	'48652',
	'48654',
	'48655',
	'48657',
	'48658',
	'48659',
	'48661',
	'48662',
	'48663',
	'48667',
	'48670',
	'48674',
	'48686',
	'48701',
	'48703',
	'48706',
	'48707',
	'48708',
	'48710',
	'48720',
	'48722',
	'48723',
	'48724',
	'48725',
	'48726',
	'48729',
	'48730',
	'48731',
	'48732',
	'48733',
	'48734',
	'48735',
	'48736',
	'48739',
	'48741',
	'48743',
	'48744',
	'48746',
	'48747',
	'48748',
	'48749',
	'48750',
	'48754',
	'48755',
	'48756',
	'48757',
	'48758',
	'48759',
	'48761',
	'48763',
	'48764',
	'48765',
	'48766',
	'48767',
	'48768',
	'48769',
	'48770',
	'48787',
	'48801',
	'48802',
	'48804',
	'48806',
	'48807',
	'48817',
	'48830',
	'48832',
	'48841',
	'48847',
	'48848',
	'48856',
	'48857',
	'48858',
	'48859',
	'48862',
	'48867',
	'48871',
	'48872',
	'48874',
	'48877',
	'48878',
	'48880',
	'48882',
	'48883',
	'48889',
	'48893',
	'48896',
	'49310',
	'93203',
	'93205',
	'93206',
	'93215',
	'93216',
	'93220',
	'93222',
	'93224',
	'93225',
	'93226',
	'93238',
	'93240',
	'93241',
	'93243',
	'93249',
	'93250',
	'93251',
	'93252',
	'93255',
	'93263',
	'93268',
	'93276',
	'93280',
	'93283',
	'93285',
	'93287',
	'93301',
	'93302',
	'93303',
	'93304',
	'93305',
	'93306',
	'93307',
	'93308',
	'93309',
	'93311',
	'93312',
	'93313',
	'93314',
	'93380',
	'93381',
	'93382',
	'93383',
	'93384',
	'93385',
	'93386',
	'93387',
	'93388',
	'93389',
	'93390',
	'93501',
	'93502',
	'93504',
	'93505',
	'93516',
	'93518',
	'93519',
	'93523',
	'93524',
	'93527',
	'93528',
	'93531',
	'93554',
	'93555',
	'93556',
	'93560',
	'93561',
	'93581',
	'93596',
	'95527',
	'95552',
	'95563',
	'95595',
	'95913',
	'95914',
	'95916',
	'95917',
	'95920',
	'95926',
	'95927',
	'95928',
	'95929',
	'95930',
	'95938',
	'95939',
	'95940',
	'95941',
	'95942',
	'95943',
	'95948',
	'95951',
	'95954',
	'95958',
	'95963',
	'95965',
	'95966',
	'95967',
	'95968',
	'95969',
	'95973',
	'95974',
	'95976',
	'95978',
	'95988',
	'96001',
	'96002',
	'96003',
	'96006',
	'96007',
	'96008',
	'96010',
	'96011',
	'96013',
	'96015',
	'96016',
	'96017',
	'96019',
	'96021',
	'96022',
	'96024',
	'96028',
	'96029',
	'96033',
	'96035',
	'96040',
	'96041',
	'96046',
	'96047',
	'96048',
	'96049',
	'96051',
	'96052',
	'96054',
	'96055',
	'96059',
	'96061',
	'96062',
	'96063',
	'96065',
	'96069',
	'96070',
	'96071',
	'96073',
	'96074',
	'96075',
	'96076',
	'96078',
	'96079',
	'96080',
	'96084',
	'96087',
	'96088',
	'96089',
	'96090',
	'96091',
	'96092',
	'96093',
	'96095',
	'96096',
	'96099',
	'96101',
	'96104',
	'96108',
	'96110',
	'96112',
	'96115',
	'96116',
	'32601',
	'32602',
	'32603',
	'32604',
	'32605',
	'32606',
	'32607',
	'32608',
	'32609',
	'32610',
	'32611',
	'32612',
	'32613',
	'32614',
	'32615',
	'32616',
	'32618',
	'32619',
	'32621',
	'32625',
	'32626',
	'32627',
	'32628',
	'32631',
	'32633',
	'32635',
	'32639',
	'32640',
	'32641',
	'32643',
	'32644',
	'32648',
	'32653',
	'32654',
	'32655',
	'32658',
	'32662',
	'32667',
	'32668',
	'32669',
	'32680',
	'32683',
	'32692',
	'32693',
	'32694',
	'32696',
	'34449',
	'34498',
	'60420',
	'60460',
	'60920',
	'60921',
	'60929',
	'60934',
	'61311',
	'61313',
	'61319',
	'61326',
	'61327',
	'61333',
	'61335',
	'61336',
	'61340',
	'61363',
	'61369',
	'61375',
	'61377',
	'61415',
	'61421',
	'61424',
	'61426',
	'61427',
	'61431',
	'61432',
	'61433',
	'61441',
	'61449',
	'61451',
	'61459',
	'61477',
	'61479',
	'61482',
	'61483',
	'61484',
	'61491',
	'61501',
	'61516',
	'61517',
	'61519',
	'61520',
	'61523',
	'61524',
	'61525',
	'61526',
	'61528',
	'61529',
	'61530',
	'61531',
	'61532',
	'61533',
	'61534',
	'61535',
	'61536',
	'61537',
	'61539',
	'61540',
	'61541',
	'61542',
	'61543',
	'61544',
	'61545',
	'61546',
	'61547',
	'61548',
	'61550',
	'61552',
	'61553',
	'61554',
	'61555',
	'61558',
	'61559',
	'61560',
	'61561',
	'61562',
	'61563',
	'61564',
	'61565',
	'61567',
	'61568',
	'61569',
	'61570',
	'61571',
	'61601',
	'61602',
	'61603',
	'61604',
	'61605',
	'61606',
	'61607',
	'61610',
	'61611',
	'61612',
	'61613',
	'61614',
	'61615',
	'61616',
	'61625',
	'61629',
	'61630',
	'61633',
	'61634',
	'61635',
	'61636',
	'61637',
	'61638',
	'61639',
	'61641',
	'61643',
	'61650',
	'61651',
	'61652',
	'61653',
	'61654',
	'61655',
	'61656',
	'61701',
	'61702',
	'61704',
	'61705',
	'61709',
	'61710',
	'61720',
	'61721',
	'61722',
	'61724',
	'61725',
	'61726',
	'61728',
	'61729',
	'61730',
	'61731',
	'61732',
	'61733',
	'61734',
	'61736',
	'61737',
	'61738',
	'61739',
	'61740',
	'61741',
	'61742',
	'61743',
	'61744',
	'61745',
	'61747',
	'61748',
	'61752',
	'61753',
	'61754',
	'61755',
	'61758',
	'61759',
	'61760',
	'61761',
	'61764',
	'61769',
	'61770',
	'61771',
	'61772',
	'61774',
	'61775',
	'61776',
	'61790',
	'61791',
	'61799',
	'62617',
	'62633',
	'62644',
	'62655',
	'62664',
	'62682',
	'43316',
	'43323',
	'43330',
	'43351',
	'43359',
	'43402',
	'43403',
	'43406',
	'43407',
	'43408',
	'43410',
	'43412',
	'43413',
	'43414',
	'43416',
	'43420',
	'43430',
	'43431',
	'43432',
	'43433',
	'43434',
	'43435',
	'43436',
	'43437',
	'43439',
	'43440',
	'43441',
	'43442',
	'43443',
	'43445',
	'43446',
	'43447',
	'43449',
	'43450',
	'43451',
	'43452',
	'43456',
	'43457',
	'43458',
	'43460',
	'43462',
	'43463',
	'43464',
	'43465',
	'43466',
	'43467',
	'43468',
	'43469',
	'43501',
	'43502',
	'43504',
	'43505',
	'43506',
	'43510',
	'43511',
	'43512',
	'43515',
	'43516',
	'43517',
	'43518',
	'43519',
	'43520',
	'43521',
	'43522',
	'43523',
	'43524',
	'43525',
	'43526',
	'43527',
	'43528',
	'43529',
	'43530',
	'43531',
	'43532',
	'43533',
	'43534',
	'43535',
	'43536',
	'43537',
	'43540',
	'43541',
	'43542',
	'43543',
	'43545',
	'43547',
	'43548',
	'43549',
	'43550',
	'43551',
	'43552',
	'43553',
	'43554',
	'43555',
	'43556',
	'43557',
	'43558',
	'43560',
	'43565',
	'43566',
	'43567',
	'43569',
	'43570',
	'43571',
	'43601',
	'43603',
	'43604',
	'43605',
	'43606',
	'43607',
	'43608',
	'43609',
	'43610',
	'43611',
	'43612',
	'43613',
	'43614',
	'43615',
	'43616',
	'43617',
	'43618',
	'43619',
	'43620',
	'43623',
	'43635',
	'43652',
	'43654',
	'43656',
	'43657',
	'43659',
	'43660',
	'43661',
	'43666',
	'43667',
	'43681',
	'43682',
	'43697',
	'43699',
	'44802',
	'44804',
	'44807',
	'44809',
	'44815',
	'44817',
	'44818',
	'44828',
	'44830',
	'44836',
	'44841',
	'44844',
	'44845',
	'44849',
	'44853',
	'44861',
	'44867',
	'44882',
	'44883',
	'45814',
	'45816',
	'45839',
	'45840',
	'45841',
	'45858',
	'45867',
	'45868',
	'45872',
	'45881',
	'45889',
	'45890',
	'45897',
	'49220',
	'49221',
	'49228',
	'49229',
	'49233',
	'49235',
	'49236',
	'49238',
	'49247',
	'49248',
	'49253',
	'49256',
	'49265',
	'49268',
	'49276',
	'49279',
	'49286',
	'49287',
	'49289',
	'32320',
	'32321',
	'32322',
	'32323',
	'32328',
	'32329',
	'32334',
	'32335',
	'32360',
	'32401',
	'32402',
	'32403',
	'32404',
	'32405',
	'32406',
	'32407',
	'32408',
	'32409',
	'32410',
	'32411',
	'32412',
	'32413',
	'32417',
	'32420',
	'32421',
	'32422',
	'32423',
	'32424',
	'32425',
	'32426',
	'32427',
	'32428',
	'32430',
	'32431',
	'32432',
	'32433',
	'32434',
	'32435',
	'32437',
	'32438',
	'32439',
	'32440',
	'32442',
	'32443',
	'32444',
	'32445',
	'32446',
	'32447',
	'32448',
	'32449',
	'32452',
	'32454',
	'32455',
	'32456',
	'32457',
	'32459',
	'32460',
	'32461',
	'32462',
	'32463',
	'32464',
	'32465',
	'32466',
	'32538',
	'32550',
	'70501',
	'70502',
	'70503',
	'70504',
	'70505',
	'70506',
	'70507',
	'70508',
	'70509',
	'70510',
	'70511',
	'70512',
	'70513',
	'70515',
	'70516',
	'70517',
	'70518',
	'70519',
	'70520',
	'70521',
	'70524',
	'70525',
	'70526',
	'70527',
	'70528',
	'70529',
	'70531',
	'70532',
	'70533',
	'70534',
	'70535',
	'70537',
	'70541',
	'70542',
	'70543',
	'70544',
	'70546',
	'70548',
	'70549',
	'70550',
	'70551',
	'70552',
	'70554',
	'70555',
	'70556',
	'70558',
	'70559',
	'70560',
	'70562',
	'70563',
	'70569',
	'70570',
	'70571',
	'70575',
	'70576',
	'70577',
	'70578',
	'70580',
	'70581',
	'70582',
	'70583',
	'70584',
	'70585',
	'70586',
	'70589',
	'70591',
	'70592',
	'70593',
	'70595',
	'70596',
	'70598',
	'70640',
	'70650',
	'70750',
	'71345',
	'71353',
	'71356',
	'71358',
	'71367',
	'83301',
	'83302',
	'83303',
	'83311',
	'83312',
	'83313',
	'83314',
	'83316',
	'83318',
	'83320',
	'83321',
	'83323',
	'83324',
	'83325',
	'83328',
	'83330',
	'83332',
	'83333',
	'83334',
	'83335',
	'83336',
	'83338',
	'83340',
	'83341',
	'83342',
	'83343',
	'83344',
	'83346',
	'83347',
	'83348',
	'83349',
	'83350',
	'83352',
	'83353',
	'83354',
	'83355',
	'53821',
	'53826',
	'54405',
	'54420',
	'54421',
	'54422',
	'54425',
	'54436',
	'54437',
	'54446',
	'54456',
	'54460',
	'54493',
	'54498',
	'54526',
	'54530',
	'54563',
	'54601',
	'54602',
	'54603',
	'54610',
	'54611',
	'54612',
	'54614',
	'54615',
	'54616',
	'54619',
	'54620',
	'54621',
	'54622',
	'54623',
	'54624',
	'54625',
	'54626',
	'54627',
	'54628',
	'54629',
	'54630',
	'54631',
	'54632',
	'54634',
	'54635',
	'54636',
	'54638',
	'54639',
	'54640',
	'54642',
	'54643',
	'54644',
	'54645',
	'54648',
	'54649',
	'54650',
	'54651',
	'54652',
	'54653',
	'54654',
	'54655',
	'54656',
	'54657',
	'54658',
	'54659',
	'54660',
	'54661',
	'54662',
	'54665',
	'54666',
	'54667',
	'54669',
	'54670',
	'54701',
	'54702',
	'54703',
	'54720',
	'54721',
	'54722',
	'54724',
	'54726',
	'54727',
	'54729',
	'54731',
	'54732',
	'54736',
	'54738',
	'54741',
	'54742',
	'54743',
	'54745',
	'54746',
	'54747',
	'54748',
	'54754',
	'54755',
	'54756',
	'54757',
	'54758',
	'54759',
	'54760',
	'54766',
	'54768',
	'54769',
	'54770',
	'54771',
	'54773',
	'54774',
	'54819',
	'54848',
	'54895',
	'55910',
	'55919',
	'55921',
	'55925',
	'55931',
	'55941',
	'55942',
	'55943',
	'55947',
	'55952',
	'55959',
	'55969',
	'55972',
	'55974',
	'55979',
	'55987',
	'55988',
	'99501',
	'99502',
	'99503',
	'99504',
	'99505',
	'99506',
	'99507',
	'99508',
	'99509',
	'99510',
	'99511',
	'99513',
	'99514',
	'99515',
	'99516',
	'99517',
	'99518',
	'99519',
	'99520',
	'99521',
	'99522',
	'99523',
	'99524',
	'99529',
	'99540',
	'99556',
	'99567',
	'99568',
	'99572',
	'99577',
	'99587',
	'99599',
	'99603',
	'99605',
	'99610',
	'99611',
	'99629',
	'99631',
	'99635',
	'99639',
	'99645',
	'99652',
	'99654',
	'99663',
	'99664',
	'99667',
	'99669',
	'99672',
	'99674',
	'99676',
	'99682',
	'99683',
	'99687',
	'99688',
	'99694',
	'99695',
	'45813',
	'45821',
	'45832',
	'45838',
	'45849',
	'45851',
	'45855',
	'45861',
	'45863',
	'45873',
	'45874',
	'45879',
	'45880',
	'45886',
	'45891',
	'45894',
	'45898',
	'45899',
	'46701',
	'46702',
	'46703',
	'46704',
	'46705',
	'46706',
	'46710',
	'46711',
	'46713',
	'46714',
	'46721',
	'46723',
	'46725',
	'46730',
	'46731',
	'46732',
	'46733',
	'46737',
	'46738',
	'46740',
	'46741',
	'46742',
	'46743',
	'46745',
	'46747',
	'46748',
	'46750',
	'46755',
	'46759',
	'46760',
	'46763',
	'46764',
	'46765',
	'46766',
	'46767',
	'46769',
	'46770',
	'46772',
	'46773',
	'46774',
	'46776',
	'46777',
	'46778',
	'46779',
	'46780',
	'46781',
	'46782',
	'46783',
	'46784',
	'46785',
	'46787',
	'46788',
	'46791',
	'46792',
	'46793',
	'46794',
	'46796',
	'46797',
	'46798',
	'46799',
	'46801',
	'46802',
	'46803',
	'46804',
	'46805',
	'46806',
	'46807',
	'46808',
	'46809',
	'46814',
	'46815',
	'46816',
	'46818',
	'46819',
	'46825',
	'46835',
	'46845',
	'46850',
	'46851',
	'46852',
	'46853',
	'46854',
	'46855',
	'46856',
	'46857',
	'46858',
	'46859',
	'46860',
	'46861',
	'46862',
	'46863',
	'46864',
	'46865',
	'46866',
	'46867',
	'46868',
	'46869',
	'46885',
	'46895',
	'46896',
	'46897',
	'46898',
	'46899',
	'46940',
	'46941',
	'46943',
	'46946',
	'46962',
	'46974',
	'46980',
	'46984',
	'46990',
	'46992',
	'47326',
	'47336',
	'47369',
	'47371',
	'47373',
	'47381',
	'31601',
	'31602',
	'31603',
	'31604',
	'31605',
	'31606',
	'31623',
	'31625',
	'31626',
	'31629',
	'31630',
	'31631',
	'31632',
	'31634',
	'31635',
	'31636',
	'31638',
	'31641',
	'31643',
	'31648',
	'31649',
	'31698',
	'31699',
	'31720',
	'31738',
	'31757',
	'31758',
	'31765',
	'31773',
	'31778',
	'31792',
	'31799',
	'32008',
	'32013',
	'32052',
	'32053',
	'32059',
	'32060',
	'32062',
	'32064',
	'32066',
	'32071',
	'32094',
	'32096',
	'32301',
	'32302',
	'32303',
	'32304',
	'32305',
	'32306',
	'32307',
	'32308',
	'32309',
	'32310',
	'32311',
	'32312',
	'32313',
	'32314',
	'32315',
	'32316',
	'32317',
	'32318',
	'32324',
	'32326',
	'32327',
	'32330',
	'32331',
	'32332',
	'32333',
	'32336',
	'32337',
	'32340',
	'32341',
	'32343',
	'32344',
	'32345',
	'32346',
	'32347',
	'32348',
	'32350',
	'32351',
	'32352',
	'32353',
	'32355',
	'32356',
	'32357',
	'32358',
	'32359',
	'32361',
	'32362',
	'32395',
	'32399',
	'39815',
	'39817',
	'39818',
	'39819',
	'39825',
	'39827',
	'39828',
	'39829',
	'39834',
	'39837',
	'39845',
	'39852',
	'39859',
	'39897',
	'15411',
	'15424',
	'15485',
	'15501',
	'15502',
	'15510',
	'15520',
	'15521',
	'15522',
	'15530',
	'15531',
	'15532',
	'15533',
	'15534',
	'15535',
	'15537',
	'15538',
	'15539',
	'15540',
	'15541',
	'15542',
	'15544',
	'15545',
	'15546',
	'15547',
	'15548',
	'15549',
	'15550',
	'15551',
	'15552',
	'15553',
	'15554',
	'15555',
	'15557',
	'15558',
	'15559',
	'15560',
	'15561',
	'15562',
	'15563',
	'15564',
	'15565',
	'15711',
	'15714',
	'15715',
	'15721',
	'15722',
	'15730',
	'15733',
	'15737',
	'15738',
	'15740',
	'15744',
	'15753',
	'15757',
	'15760',
	'15762',
	'15764',
	'15767',
	'15770',
	'15773',
	'15775',
	'15776',
	'15778',
	'15780',
	'15781',
	'15784',
	'15801',
	'15821',
	'15822',
	'15823',
	'15824',
	'15825',
	'15827',
	'15829',
	'15831',
	'15832',
	'15834',
	'15840',
	'15841',
	'15845',
	'15846',
	'15847',
	'15848',
	'15849',
	'15851',
	'15853',
	'15856',
	'15857',
	'15860',
	'15861',
	'15863',
	'15864',
	'15865',
	'15866',
	'15868',
	'15870',
	'15901',
	'15902',
	'15904',
	'15905',
	'15906',
	'15907',
	'15909',
	'15921',
	'15922',
	'15924',
	'15925',
	'15926',
	'15927',
	'15928',
	'15930',
	'15931',
	'15934',
	'15935',
	'15936',
	'15937',
	'15938',
	'15940',
	'15942',
	'15943',
	'15945',
	'15946',
	'15948',
	'15951',
	'15952',
	'15953',
	'15955',
	'15956',
	'15958',
	'15959',
	'15960',
	'15961',
	'15962',
	'15963',
	'16601',
	'16602',
	'16603',
	'16611',
	'16613',
	'16616',
	'16617',
	'16619',
	'16620',
	'16621',
	'16622',
	'16623',
	'16624',
	'16625',
	'16627',
	'16629',
	'16630',
	'16631',
	'16633',
	'16634',
	'16635',
	'16636',
	'16637',
	'16638',
	'16639',
	'16640',
	'16641',
	'16644',
	'16645',
	'16646',
	'16647',
	'16648',
	'16650',
	'16651',
	'16652',
	'16654',
	'16655',
	'16656',
	'16657',
	'16659',
	'16660',
	'16661',
	'16662',
	'16663',
	'16664',
	'16665',
	'16666',
	'16667',
	'16668',
	'16669',
	'16670',
	'16671',
	'16672',
	'16673',
	'16674',
	'16675',
	'16677',
	'16678',
	'16679',
	'16680',
	'16681',
	'16682',
	'16683',
	'16684',
	'16685',
	'16686',
	'16692',
	'16693',
	'16694',
	'16695',
	'16698',
	'16699',
	'16728',
	'16734',
	'16801',
	'16802',
	'16803',
	'16804',
	'16805',
	'16820',
	'16821',
	'16823',
	'16825',
	'16826',
	'16827',
	'16828',
	'16829',
	'16830',
	'16832',
	'16833',
	'16834',
	'16835',
	'16836',
	'16837',
	'16838',
	'16839',
	'16840',
	'16841',
	'16843',
	'16844',
	'16845',
	'16847',
	'16849',
	'16850',
	'16851',
	'16852',
	'16853',
	'16854',
	'16855',
	'16856',
	'16858',
	'16859',
	'16860',
	'16861',
	'16863',
	'16864',
	'16865',
	'16866',
	'16868',
	'16870',
	'16871',
	'16872',
	'16873',
	'16874',
	'16875',
	'16876',
	'16877',
	'16878',
	'16879',
	'16881',
	'16882',
	'17052',
	'17060',
	'17066',
	'17211',
	'17213',
	'17239',
	'17243',
	'17249',
	'17253',
	'17255',
	'17260',
	'17264',
	'77950',
	'77990',
	'78022',
	'78060',
	'78071',
	'78075',
	'78102',
	'78104',
	'78125',
	'78142',
	'78145',
	'78146',
	'78162',
	'78330',
	'78332',
	'78333',
	'78335',
	'78336',
	'78338',
	'78339',
	'78340',
	'78341',
	'78342',
	'78343',
	'78347',
	'78349',
	'78350',
	'78351',
	'78352',
	'78353',
	'78355',
	'78357',
	'78358',
	'78359',
	'78360',
	'78361',
	'78362',
	'78363',
	'78364',
	'78368',
	'78370',
	'78372',
	'78373',
	'78374',
	'78375',
	'78376',
	'78377',
	'78379',
	'78380',
	'78381',
	'78382',
	'78383',
	'78384',
	'78385',
	'78387',
	'78389',
	'78390',
	'78391',
	'78393',
	'78401',
	'78402',
	'78403',
	'78404',
	'78405',
	'78406',
	'78407',
	'78408',
	'78409',
	'78410',
	'78411',
	'78412',
	'78413',
	'78414',
	'78415',
	'78416',
	'78417',
	'78418',
	'78419',
	'78426',
	'78427',
	'78460',
	'78461',
	'78463',
	'78465',
	'78466',
	'78467',
	'78468',
	'78469',
	'78470',
	'78471',
	'78472',
	'78473',
	'78475',
	'78476',
	'78477',
	'78478',
	'78480',
	'35010',
	'35011',
	'36003',
	'36005',
	'36006',
	'36008',
	'36009',
	'36010',
	'36013',
	'36015',
	'36020',
	'36022',
	'36023',
	'36024',
	'36025',
	'36028',
	'36029',
	'36030',
	'36031',
	'36032',
	'36033',
	'36034',
	'36035',
	'36036',
	'36037',
	'36038',
	'36039',
	'36040',
	'36041',
	'36042',
	'36043',
	'36045',
	'36046',
	'36047',
	'36049',
	'36051',
	'36052',
	'36053',
	'36054',
	'36057',
	'36061',
	'36062',
	'36064',
	'36065',
	'36066',
	'36067',
	'36068',
	'36069',
	'36071',
	'36075',
	'36078',
	'36079',
	'36080',
	'36081',
	'36082',
	'36083',
	'36087',
	'36088',
	'36089',
	'36092',
	'36093',
	'36101',
	'36102',
	'36103',
	'36104',
	'36105',
	'36106',
	'36107',
	'36108',
	'36109',
	'36110',
	'36111',
	'36112',
	'36113',
	'36114',
	'36115',
	'36116',
	'36117',
	'36118',
	'36120',
	'36121',
	'36123',
	'36124',
	'36125',
	'36130',
	'36131',
	'36132',
	'36133',
	'36134',
	'36135',
	'36140',
	'36141',
	'36142',
	'36177',
	'36191',
	'36256',
	'36420',
	'36421',
	'36435',
	'36442',
	'36455',
	'36456',
	'36467',
	'36474',
	'36476',
	'36483',
	'36701',
	'36702',
	'36703',
	'36720',
	'36721',
	'36722',
	'36723',
	'36726',
	'36728',
	'36732',
	'36736',
	'36738',
	'36741',
	'36742',
	'36745',
	'36748',
	'36749',
	'36751',
	'36752',
	'36753',
	'36754',
	'36756',
	'36758',
	'36759',
	'36761',
	'36763',
	'36764',
	'36766',
	'36767',
	'36768',
	'36769',
	'36773',
	'36775',
	'36782',
	'36783',
	'36785',
	'36786',
	'36850',
	'36853',
	'36861',
	'36866',
	'46366',
	'46374',
	'46501',
	'46502',
	'46504',
	'46506',
	'46507',
	'46508',
	'46510',
	'46511',
	'46513',
	'46514',
	'46515',
	'46516',
	'46517',
	'46524',
	'46526',
	'46527',
	'46528',
	'46530',
	'46531',
	'46532',
	'46534',
	'46536',
	'46537',
	'46538',
	'46539',
	'46540',
	'46542',
	'46543',
	'46544',
	'46545',
	'46546',
	'46550',
	'46552',
	'46553',
	'46554',
	'46555',
	'46556',
	'46561',
	'46562',
	'46563',
	'46565',
	'46567',
	'46570',
	'46571',
	'46572',
	'46573',
	'46574',
	'46580',
	'46581',
	'46582',
	'46590',
	'46595',
	'46601',
	'46604',
	'46613',
	'46614',
	'46615',
	'46616',
	'46617',
	'46619',
	'46620',
	'46624',
	'46626',
	'46628',
	'46634',
	'46635',
	'46637',
	'46660',
	'46680',
	'46699',
	'46746',
	'46761',
	'46771',
	'46786',
	'46789',
	'46795',
	'46910',
	'46912',
	'46922',
	'46931',
	'46935',
	'46939',
	'46945',
	'46960',
	'46968',
	'46975',
	'46982',
	'46985',
	'46996',
	'47946',
	'47957',
	'49022',
	'49023',
	'49031',
	'49038',
	'49039',
	'49047',
	'49061',
	'49067',
	'49084',
	'49085',
	'49095',
	'49098',
	'49101',
	'49102',
	'49103',
	'49104',
	'49106',
	'49107',
	'49111',
	'49112',
	'49113',
	'49115',
	'49116',
	'49117',
	'49119',
	'49120',
	'49121',
	'49125',
	'49126',
	'49127',
	'49128',
	'49129',
	'49130',
	'24201',
	'24202',
	'24203',
	'24205',
	'24209',
	'24210',
	'24211',
	'24212',
	'24215',
	'24216',
	'24217',
	'24218',
	'24219',
	'24220',
	'24221',
	'24224',
	'24225',
	'24226',
	'24228',
	'24230',
	'24236',
	'24237',
	'24239',
	'24243',
	'24244',
	'24245',
	'24246',
	'24248',
	'24250',
	'24251',
	'24256',
	'24258',
	'24260',
	'24263',
	'24265',
	'24266',
	'24269',
	'24270',
	'24271',
	'24272',
	'24273',
	'24277',
	'24279',
	'24280',
	'24281',
	'24282',
	'24283',
	'24290',
	'24293',
	'24311',
	'24319',
	'24327',
	'24340',
	'24354',
	'24361',
	'24370',
	'24375',
	'24603',
	'24607',
	'24614',
	'24620',
	'24624',
	'24627',
	'24628',
	'24631',
	'24634',
	'24639',
	'24646',
	'24647',
	'24649',
	'24656',
	'24657',
	'24658',
	'37601',
	'37602',
	'37604',
	'37605',
	'37614',
	'37615',
	'37616',
	'37617',
	'37618',
	'37620',
	'37621',
	'37625',
	'37640',
	'37641',
	'37642',
	'37643',
	'37644',
	'37645',
	'37650',
	'37656',
	'37657',
	'37658',
	'37659',
	'37660',
	'37662',
	'37663',
	'37664',
	'37665',
	'37669',
	'37680',
	'37681',
	'37682',
	'37683',
	'37684',
	'37686',
	'37687',
	'37688',
	'37690',
	'37691',
	'37692',
	'37694',
	'37711',
	'37731',
	'37743',
	'37744',
	'37745',
	'37809',
	'37810',
	'37811',
	'37818',
	'37857',
	'37873',
	'40803',
	'40816',
	'40826',
	'40827',
	'40840',
	'40844',
	'40858',
	'40862',
	'40868',
	'40874',
	'40979',
	'41517',
	'41537',
	'41714',
	'41749',
	'41762',
	'41764',
	'41766',
	'41775',
	'41776',
	'41777',
	'41804',
	'41810',
	'41812',
	'41815',
	'41819',
	'41821',
	'41824',
	'41825',
	'41826',
	'41832',
	'41833',
	'41835',
	'41837',
	'41838',
	'41840',
	'41845',
	'41848',
	'41849',
	'41855',
	'41858',
	'01001',
	'01002',
	'01003',
	'01004',
	'01007',
	'01008',
	'01009',
	'01010',
	'01011',
	'01012',
	'01013',
	'01014',
	'01020',
	'01021',
	'01022',
	'01026',
	'01027',
	'01028',
	'01030',
	'01032',
	'01033',
	'01034',
	'01035',
	'01036',
	'01038',
	'01039',
	'01040',
	'01041',
	'01050',
	'01053',
	'01054',
	'01056',
	'01057',
	'01059',
	'01060',
	'01061',
	'01062',
	'01063',
	'01066',
	'01069',
	'01070',
	'01071',
	'01072',
	'01073',
	'01075',
	'01077',
	'01079',
	'01080',
	'01081',
	'01082',
	'01084',
	'01085',
	'01086',
	'01088',
	'01089',
	'01090',
	'01093',
	'01095',
	'01096',
	'01097',
	'01098',
	'01101',
	'01102',
	'01103',
	'01104',
	'01105',
	'01106',
	'01107',
	'01108',
	'01109',
	'01111',
	'01115',
	'01116',
	'01118',
	'01119',
	'01128',
	'01129',
	'01133',
	'01138',
	'01139',
	'01144',
	'01151',
	'01152',
	'01195',
	'01199',
	'01243',
	'01301',
	'01302',
	'01330',
	'01337',
	'01338',
	'01339',
	'01340',
	'01341',
	'01342',
	'01344',
	'01346',
	'01347',
	'01349',
	'01350',
	'01351',
	'01354',
	'01355',
	'01360',
	'01364',
	'01367',
	'01370',
	'01373',
	'01375',
	'01376',
	'01378',
	'01379',
	'01380',
	'01521',
	'57621',
	'57634',
	'57639',
	'57641',
	'57642',
	'57645',
	'57657',
	'57658',
	'57659',
	'57660',
	'58310',
	'58313',
	'58316',
	'58318',
	'58319',
	'58329',
	'58341',
	'58353',
	'58366',
	'58367',
	'58368',
	'58369',
	'58384',
	'58385',
	'58413',
	'58418',
	'58422',
	'58423',
	'58428',
	'58430',
	'58438',
	'58440',
	'58442',
	'58444',
	'58451',
	'58460',
	'58463',
	'58475',
	'58477',
	'58478',
	'58482',
	'58486',
	'58487',
	'58488',
	'58494',
	'58495',
	'58501',
	'58502',
	'58503',
	'58504',
	'58505',
	'58506',
	'58507',
	'58520',
	'58521',
	'58523',
	'58524',
	'58528',
	'58529',
	'58530',
	'58531',
	'58532',
	'58533',
	'58535',
	'58538',
	'58540',
	'58541',
	'58542',
	'58544',
	'58545',
	'58549',
	'58552',
	'58554',
	'58558',
	'58559',
	'58560',
	'58561',
	'58562',
	'58563',
	'58564',
	'58565',
	'58566',
	'58568',
	'58569',
	'58570',
	'58571',
	'58572',
	'58573',
	'58575',
	'58576',
	'58577',
	'58579',
	'58580',
	'58581',
	'58601',
	'58602',
	'58620',
	'58621',
	'58622',
	'58623',
	'58625',
	'58626',
	'58627',
	'58630',
	'58631',
	'58632',
	'58634',
	'58636',
	'58638',
	'58639',
	'58640',
	'58641',
	'58642',
	'58643',
	'58644',
	'58645',
	'58646',
	'58647',
	'58649',
	'58650',
	'58651',
	'58652',
	'58653',
	'58654',
	'58655',
	'58656',
	'58701',
	'58702',
	'58703',
	'58704',
	'58705',
	'58707',
	'58710',
	'58711',
	'58712',
	'58713',
	'58716',
	'58718',
	'58721',
	'58722',
	'58723',
	'58725',
	'58727',
	'58730',
	'58731',
	'58733',
	'58734',
	'58735',
	'58736',
	'58737',
	'58740',
	'58741',
	'58744',
	'58746',
	'58748',
	'58750',
	'58752',
	'58755',
	'58756',
	'58757',
	'58758',
	'58759',
	'58760',
	'58761',
	'58762',
	'58763',
	'58765',
	'58768',
	'58769',
	'58770',
	'58771',
	'58772',
	'58773',
	'58775',
	'58776',
	'58778',
	'58779',
	'58781',
	'58782',
	'58783',
	'58784',
	'58785',
	'58787',
	'58788',
	'58789',
	'58790',
	'58792',
	'58793',
	'58794',
	'58795',
	'58801',
	'58802',
	'58830',
	'58831',
	'58833',
	'58835',
	'58838',
	'58843',
	'58844',
	'58845',
	'58847',
	'58849',
	'58852',
	'58853',
	'58854',
	'58856',
	'59201',
	'59211',
	'59212',
	'59213',
	'59214',
	'59215',
	'59217',
	'59218',
	'59219',
	'59221',
	'59222',
	'59226',
	'59242',
	'59243',
	'59247',
	'59252',
	'59253',
	'59254',
	'59255',
	'59256',
	'59257',
	'59258',
	'59262',
	'59263',
	'59270',
	'59274',
	'59275',
	'59276',
	'59313',
	'59344',
	'59353',
	'59354',
	'60113',
	'60530',
	'60553',
	'61006',
	'61007',
	'61008',
	'61010',
	'61011',
	'61012',
	'61013',
	'61015',
	'61016',
	'61018',
	'61019',
	'61020',
	'61021',
	'61024',
	'61027',
	'61030',
	'61031',
	'61032',
	'61038',
	'61039',
	'61042',
	'61043',
	'61044',
	'61047',
	'61048',
	'61049',
	'61050',
	'61052',
	'61054',
	'61057',
	'61060',
	'61061',
	'61062',
	'61063',
	'61064',
	'61065',
	'61067',
	'61068',
	'61070',
	'61072',
	'61073',
	'61077',
	'61079',
	'61080',
	'61084',
	'61088',
	'61089',
	'61091',
	'61101',
	'61102',
	'61103',
	'61104',
	'61105',
	'61106',
	'61107',
	'61108',
	'61109',
	'61110',
	'61111',
	'61112',
	'61114',
	'61115',
	'61125',
	'61126',
	'61130',
	'61131',
	'61132',
	'61310',
	'61318',
	'61324',
	'61331',
	'61353',
	'61367',
	'61378',
	'24851',
	'25003',
	'25005',
	'25009',
	'25011',
	'25015',
	'25019',
	'25021',
	'25022',
	'25024',
	'25025',
	'25026',
	'25028',
	'25030',
	'25033',
	'25035',
	'25039',
	'25043',
	'25045',
	'25047',
	'25049',
	'25051',
	'25053',
	'25054',
	'25059',
	'25061',
	'25063',
	'25064',
	'25067',
	'25070',
	'25071',
	'25075',
	'25076',
	'25079',
	'25081',
	'25082',
	'25083',
	'25086',
	'25088',
	'25093',
	'25102',
	'25103',
	'25106',
	'25107',
	'25108',
	'25109',
	'25110',
	'25111',
	'25112',
	'25113',
	'25114',
	'25121',
	'25123',
	'25124',
	'25125',
	'25126',
	'25130',
	'25132',
	'25133',
	'25134',
	'25141',
	'25142',
	'25143',
	'25148',
	'25149',
	'25154',
	'25156',
	'25159',
	'25160',
	'25162',
	'25164',
	'25165',
	'25168',
	'25169',
	'25177',
	'25180',
	'25181',
	'25183',
	'25187',
	'25193',
	'25201',
	'25202',
	'25203',
	'25204',
	'25205',
	'25206',
	'25208',
	'25209',
	'25211',
	'25213',
	'25214',
	'25231',
	'25234',
	'25235',
	'25239',
	'25241',
	'25243',
	'25244',
	'25245',
	'25247',
	'25248',
	'25251',
	'25252',
	'25253',
	'25259',
	'25260',
	'25261',
	'25262',
	'25264',
	'25265',
	'25266',
	'25268',
	'25270',
	'25271',
	'25275',
	'25276',
	'25285',
	'25286',
	'25287',
	'25301',
	'25302',
	'25303',
	'25304',
	'25305',
	'25306',
	'25309',
	'25311',
	'25312',
	'25313',
	'25314',
	'25315',
	'25317',
	'25320',
	'25321',
	'25322',
	'25323',
	'25324',
	'25325',
	'25326',
	'25327',
	'25328',
	'25329',
	'25330',
	'25331',
	'25332',
	'25333',
	'25334',
	'25335',
	'25336',
	'25337',
	'25338',
	'25339',
	'25356',
	'25357',
	'25358',
	'25360',
	'25361',
	'25362',
	'25364',
	'25365',
	'25375',
	'25387',
	'25389',
	'25392',
	'25396',
	'25501',
	'25502',
	'25503',
	'25504',
	'25505',
	'25506',
	'25507',
	'25508',
	'25510',
	'25511',
	'25512',
	'25514',
	'25515',
	'25517',
	'25520',
	'25521',
	'25523',
	'25524',
	'25526',
	'25529',
	'25530',
	'25534',
	'25535',
	'25537',
	'25540',
	'25541',
	'25544',
	'25545',
	'25547',
	'25550',
	'25555',
	'25557',
	'25559',
	'25560',
	'25562',
	'25564',
	'25565',
	'25567',
	'25569',
	'25570',
	'25571',
	'25572',
	'25573',
	'25601',
	'25606',
	'25607',
	'25608',
	'25611',
	'25612',
	'25614',
	'25617',
	'25621',
	'25624',
	'25625',
	'25628',
	'25630',
	'25632',
	'25634',
	'25635',
	'25637',
	'25638',
	'25639',
	'25644',
	'25646',
	'25647',
	'25649',
	'25650',
	'25651',
	'25652',
	'25653',
	'25654',
	'25661',
	'25665',
	'25666',
	'25667',
	'25669',
	'25670',
	'25671',
	'25672',
	'25674',
	'25676',
	'25678',
	'25685',
	'25686',
	'25688',
	'25690',
	'25691',
	'25692',
	'25696',
	'25699',
	'25701',
	'25702',
	'25703',
	'25704',
	'25705',
	'25706',
	'25707',
	'25708',
	'25709',
	'25710',
	'25711',
	'25712',
	'25713',
	'25714',
	'25715',
	'25716',
	'25717',
	'25718',
	'25719',
	'25720',
	'25721',
	'25722',
	'25723',
	'25724',
	'25725',
	'25726',
	'25727',
	'25728',
	'25729',
	'25755',
	'25770',
	'25772',
	'25773',
	'25774',
	'25775',
	'25776',
	'25777',
	'25778',
	'25779',
	'26136',
	'26137',
	'26138',
	'26141',
	'26143',
	'26147',
	'26151',
	'26152',
	'26160',
	'26164',
	'26202',
	'26205',
	'26261',
	'26335',
	'26601',
	'26610',
	'26615',
	'26617',
	'26619',
	'26621',
	'26623',
	'26624',
	'26627',
	'26629',
	'26631',
	'26651',
	'26656',
	'26660',
	'26662',
	'26667',
	'26671',
	'26675',
	'26676',
	'26678',
	'26679',
	'26681',
	'26684',
	'26690',
	'26691',
	'41101',
	'41102',
	'41105',
	'41114',
	'41121',
	'41124',
	'41128',
	'41129',
	'41132',
	'41135',
	'41139',
	'41141',
	'41142',
	'41143',
	'41144',
	'41146',
	'41149',
	'41159',
	'41160',
	'41164',
	'41166',
	'41168',
	'41169',
	'41171',
	'41173',
	'41174',
	'41175',
	'41179',
	'41180',
	'41181',
	'41183',
	'41189',
	'41201',
	'41203',
	'41204',
	'41214',
	'41216',
	'41219',
	'41222',
	'41224',
	'41226',
	'41230',
	'41231',
	'41232',
	'41234',
	'41238',
	'41240',
	'41250',
	'41254',
	'41255',
	'41256',
	'41257',
	'41260',
	'41262',
	'41263',
	'41264',
	'41265',
	'41267',
	'41268',
	'41271',
	'41274',
	'41501',
	'41502',
	'41503',
	'41512',
	'41513',
	'41514',
	'41519',
	'41520',
	'41522',
	'41524',
	'41526',
	'41527',
	'41528',
	'41531',
	'41534',
	'41535',
	'41538',
	'41539',
	'41540',
	'41542',
	'41543',
	'41544',
	'41547',
	'41548',
	'41549',
	'41553',
	'41554',
	'41555',
	'41557',
	'41558',
	'41559',
	'41560',
	'41561',
	'41562',
	'41563',
	'41564',
	'41566',
	'41567',
	'41568',
	'41571',
	'41572',
	'41601',
	'41602',
	'41603',
	'41604',
	'41605',
	'41606',
	'41607',
	'41612',
	'41615',
	'41616',
	'41619',
	'41621',
	'41622',
	'41630',
	'41631',
	'41635',
	'41636',
	'41640',
	'41642',
	'41643',
	'41645',
	'41647',
	'41649',
	'41650',
	'41651',
	'41653',
	'41655',
	'41659',
	'41660',
	'41663',
	'41666',
	'41667',
	'41669',
	'45614',
	'45619',
	'45620',
	'45621',
	'45622',
	'45623',
	'45629',
	'45630',
	'45631',
	'45634',
	'45636',
	'45638',
	'45640',
	'45643',
	'45645',
	'45648',
	'45651',
	'45652',
	'45653',
	'45654',
	'45656',
	'45657',
	'45658',
	'45659',
	'45662',
	'45663',
	'45669',
	'45671',
	'45672',
	'45674',
	'45675',
	'45677',
	'45678',
	'45680',
	'45682',
	'45685',
	'45686',
	'45688',
	'45692',
	'45694',
	'45695',
	'45696',
	'45698',
	'45699',
	'45720',
	'45741',
	'45743',
	'45760',
	'45769',
	'45770',
	'45771',
	'45772',
	'45775',
	'45779',
	'45783',
	'47901',
	'47902',
	'47903',
	'47904',
	'47905',
	'47906',
	'47907',
	'47909',
	'47917',
	'47920',
	'47921',
	'47924',
	'47930',
	'47941',
	'47942',
	'47944',
	'47962',
	'47970',
	'47971',
	'47975',
	'47981',
	'47982',
	'47983',
	'47984',
	'47986',
	'47991',
	'47992',
	'47993',
	'47996',
	'53910',
	'53927',
	'53934',
	'53936',
	'54103',
	'54401',
	'54402',
	'54403',
	'54404',
	'54406',
	'54407',
	'54408',
	'54409',
	'54410',
	'54411',
	'54412',
	'54413',
	'54415',
	'54417',
	'54418',
	'54423',
	'54424',
	'54426',
	'54427',
	'54428',
	'54429',
	'54430',
	'54432',
	'54433',
	'54434',
	'54435',
	'54439',
	'54440',
	'54441',
	'54442',
	'54443',
	'54447',
	'54448',
	'54449',
	'54451',
	'54452',
	'54454',
	'54455',
	'54457',
	'54458',
	'54459',
	'54462',
	'54463',
	'54464',
	'54465',
	'54466',
	'54467',
	'54469',
	'54470',
	'54471',
	'54472',
	'54473',
	'54474',
	'54475',
	'54476',
	'54479',
	'54480',
	'54481',
	'54482',
	'54484',
	'54485',
	'54487',
	'54488',
	'54489',
	'54490',
	'54491',
	'54492',
	'54494',
	'54495',
	'54501',
	'54511',
	'54512',
	'54513',
	'54515',
	'54519',
	'54520',
	'54521',
	'54524',
	'54529',
	'54531',
	'54532',
	'54537',
	'54538',
	'54539',
	'54540',
	'54541',
	'54543',
	'54545',
	'54548',
	'54552',
	'54554',
	'54555',
	'54556',
	'54557',
	'54558',
	'54560',
	'54561',
	'54562',
	'54564',
	'54566',
	'54568',
	'54613',
	'54909',
	'54921',
	'25267',
	'26148',
	'26161',
	'26178',
	'26201',
	'26203',
	'26206',
	'26208',
	'26210',
	'26215',
	'26217',
	'26218',
	'26219',
	'26222',
	'26224',
	'26228',
	'26229',
	'26230',
	'26234',
	'26236',
	'26237',
	'26238',
	'26241',
	'26250',
	'26253',
	'26254',
	'26257',
	'26259',
	'26260',
	'26263',
	'26266',
	'26267',
	'26268',
	'26269',
	'26270',
	'26271',
	'26273',
	'26275',
	'26276',
	'26278',
	'26280',
	'26282',
	'26283',
	'26285',
	'26287',
	'26288',
	'26289',
	'26292',
	'26293',
	'26294',
	'26296',
	'26298',
	'26301',
	'26302',
	'26306',
	'26321',
	'26323',
	'26325',
	'26327',
	'26330',
	'26337',
	'26338',
	'26339',
	'26342',
	'26343',
	'26346',
	'26347',
	'26349',
	'26351',
	'26354',
	'26361',
	'26362',
	'26366',
	'26369',
	'26372',
	'26376',
	'26378',
	'26384',
	'26385',
	'26386',
	'26404',
	'26405',
	'26408',
	'26411',
	'26412',
	'26415',
	'26416',
	'26421',
	'26422',
	'26424',
	'26426',
	'26430',
	'26431',
	'26435',
	'26436',
	'26438',
	'26440',
	'26443',
	'26447',
	'26448',
	'26451',
	'26452',
	'26456',
	'26461',
	'26463',
	'26554',
	'26555',
	'26559',
	'26560',
	'26563',
	'26566',
	'26568',
	'26570',
	'26571',
	'26572',
	'26574',
	'26576',
	'26578',
	'26582',
	'26585',
	'26586',
	'26587',
	'26588',
	'26591',
	'26611',
	'26636',
	'26638',
	'04354',
	'04401',
	'04402',
	'04406',
	'04408',
	'04410',
	'04411',
	'04412',
	'04413',
	'04414',
	'04415',
	'04416',
	'04417',
	'04418',
	'04419',
	'04420',
	'04421',
	'04422',
	'04424',
	'04426',
	'04427',
	'04428',
	'04429',
	'04430',
	'04431',
	'04434',
	'04435',
	'04438',
	'04441',
	'04442',
	'04443',
	'04444',
	'04448',
	'04449',
	'04450',
	'04451',
	'04453',
	'04454',
	'04455',
	'04456',
	'04457',
	'04459',
	'04460',
	'04461',
	'04462',
	'04463',
	'04464',
	'04467',
	'04468',
	'04469',
	'04472',
	'04473',
	'04474',
	'04475',
	'04476',
	'04478',
	'04479',
	'04481',
	'04485',
	'04487',
	'04488',
	'04489',
	'04490',
	'04491',
	'04492',
	'04493',
	'04495',
	'04496',
	'04605',
	'04606',
	'04607',
	'04609',
	'04611',
	'04612',
	'04613',
	'04614',
	'04616',
	'04617',
	'04619',
	'04622',
	'04623',
	'04624',
	'04625',
	'04626',
	'04627',
	'04628',
	'04629',
	'04630',
	'04631',
	'04634',
	'04635',
	'04637',
	'04640',
	'04642',
	'04643',
	'04644',
	'04646',
	'04648',
	'04649',
	'04650',
	'04652',
	'04653',
	'04654',
	'04655',
	'04657',
	'04658',
	'04660',
	'04662',
	'04664',
	'04666',
	'04667',
	'04668',
	'04669',
	'04671',
	'04672',
	'04673',
	'04674',
	'04675',
	'04676',
	'04677',
	'04679',
	'04680',
	'04681',
	'04683',
	'04684',
	'04685',
	'04686',
	'04691',
	'04693',
	'04694',
	'04765',
	'04777',
	'04848',
	'04849',
	'04850',
	'04911',
	'04912',
	'04915',
	'04920',
	'04921',
	'04922',
	'04923',
	'04924',
	'04925',
	'04928',
	'04929',
	'04930',
	'04932',
	'04933',
	'04937',
	'04939',
	'04941',
	'04942',
	'04943',
	'04944',
	'04945',
	'04949',
	'04950',
	'04951',
	'04952',
	'04953',
	'04954',
	'04957',
	'04958',
	'04961',
	'04965',
	'04967',
	'04969',
	'04971',
	'04972',
	'04973',
	'04974',
	'04975',
	'04976',
	'04978',
	'04979',
	'04981',
	'04985',
	'04986',
	'04987',
	'04988',
	'12064',
	'12116',
	'12155',
	'12197',
	'13054',
	'13123',
	'13157',
	'13162',
	'13301',
	'13303',
	'13304',
	'13308',
	'13309',
	'13313',
	'13315',
	'13316',
	'13318',
	'13319',
	'13320',
	'13321',
	'13322',
	'13323',
	'13324',
	'13326',
	'13328',
	'13329',
	'13331',
	'13333',
	'13335',
	'13337',
	'13338',
	'13340',
	'13341',
	'13342',
	'13348',
	'13350',
	'13352',
	'13354',
	'13357',
	'13361',
	'13362',
	'13363',
	'13365',
	'13401',
	'13403',
	'13406',
	'13407',
	'13413',
	'13415',
	'13416',
	'13417',
	'13420',
	'13424',
	'13425',
	'13431',
	'13435',
	'13438',
	'13439',
	'13440',
	'13441',
	'13442',
	'13449',
	'13450',
	'13454',
	'13455',
	'13456',
	'13457',
	'13461',
	'13468',
	'13469',
	'13471',
	'13472',
	'13475',
	'13476',
	'13477',
	'13478',
	'13479',
	'13480',
	'13482',
	'13483',
	'13486',
	'13488',
	'13490',
	'13491',
	'13492',
	'13494',
	'13495',
	'13501',
	'13502',
	'13503',
	'13504',
	'13505',
	'13599',
	'13747',
	'13776',
	'13796',
	'13807',
	'13808',
	'13810',
	'13820',
	'13825',
	'13834',
	'13849',
	'13859',
	'13861',
	'64728',
	'64741',
	'64748',
	'64750',
	'64755',
	'64759',
	'64762',
	'64765',
	'64766',
	'64767',
	'64769',
	'64771',
	'64772',
	'64778',
	'64783',
	'64784',
	'64790',
	'64801',
	'64802',
	'64803',
	'64804',
	'64830',
	'64831',
	'64832',
	'64833',
	'64834',
	'64835',
	'64836',
	'64840',
	'64841',
	'64842',
	'64843',
	'64844',
	'64847',
	'64849',
	'64850',
	'64853',
	'64854',
	'64855',
	'64856',
	'64857',
	'64858',
	'64859',
	'64861',
	'64862',
	'64863',
	'64864',
	'64865',
	'64866',
	'64867',
	'64868',
	'64869',
	'64870',
	'65730',
	'66701',
	'66710',
	'66711',
	'66712',
	'66713',
	'66714',
	'66716',
	'66717',
	'66720',
	'66724',
	'66725',
	'66728',
	'66732',
	'66733',
	'66734',
	'66735',
	'66736',
	'66738',
	'66739',
	'66740',
	'66741',
	'66742',
	'66743',
	'66746',
	'66748',
	'66749',
	'66751',
	'66753',
	'66754',
	'66755',
	'66756',
	'66757',
	'66759',
	'66760',
	'66761',
	'66762',
	'66763',
	'66769',
	'66770',
	'66771',
	'66772',
	'66773',
	'66775',
	'66776',
	'66777',
	'66778',
	'66779',
	'66780',
	'66781',
	'66782',
	'66783',
	'67330',
	'67332',
	'67336',
	'67341',
	'67342',
	'67354',
	'67356',
	'67357',
	'74331',
	'74333',
	'74335',
	'74339',
	'74343',
	'74354',
	'74355',
	'74358',
	'74360',
	'74363',
	'74370',
	'22801',
	'22802',
	'22803',
	'22807',
	'22811',
	'22812',
	'22815',
	'22820',
	'22821',
	'22827',
	'22830',
	'22831',
	'22832',
	'22833',
	'22834',
	'22840',
	'22841',
	'22843',
	'22846',
	'22848',
	'22850',
	'22853',
	'22939',
	'22952',
	'22980',
	'24401',
	'24402',
	'24411',
	'24421',
	'24430',
	'24431',
	'24432',
	'24437',
	'24440',
	'24441',
	'24459',
	'24463',
	'24467',
	'24469',
	'24471',
	'24476',
	'24477',
	'24479',
	'24482',
	'24485',
	'24486',
	'26802',
	'26804',
	'26807',
	'26814',
	'26815',
	'26866',
	'26884',
	'26886',
	'72401',
	'72402',
	'72403',
	'72404',
	'72410',
	'72411',
	'72412',
	'72413',
	'72414',
	'72415',
	'72416',
	'72417',
	'72419',
	'72421',
	'72422',
	'72424',
	'72425',
	'72427',
	'72430',
	'72433',
	'72434',
	'72435',
	'72436',
	'72437',
	'72439',
	'72440',
	'72441',
	'72443',
	'72444',
	'72445',
	'72447',
	'72449',
	'72450',
	'72451',
	'72453',
	'72454',
	'72455',
	'72456',
	'72457',
	'72458',
	'72459',
	'72460',
	'72461',
	'72462',
	'72464',
	'72465',
	'72466',
	'72467',
	'72469',
	'72470',
	'72474',
	'72476',
	'72478',
	'72482',
	'72513',
	'72521',
	'72525',
	'72529',
	'72532',
	'72542',
	'72569',
	'72572',
	'72577',
	'82224',
	'82229',
	'82310',
	'82401',
	'82430',
	'82442',
	'82443',
	'82501',
	'82510',
	'82512',
	'82513',
	'82514',
	'82515',
	'82516',
	'82520',
	'82523',
	'82524',
	'82601',
	'82602',
	'82604',
	'82605',
	'82609',
	'82620',
	'82630',
	'82633',
	'82635',
	'82636',
	'82637',
	'82638',
	'82642',
	'82643',
	'82644',
	'82646',
	'82648',
	'82649',
	'69341',
	'69346',
	'69352',
	'69353',
	'69355',
	'69356',
	'69357',
	'69358',
	'69361',
	'69363',
	'82001',
	'82002',
	'82003',
	'82005',
	'82006',
	'82007',
	'82008',
	'82009',
	'82010',
	'82050',
	'82053',
	'82054',
	'82059',
	'82060',
	'82061',
	'82081',
	'82082',
	'82212',
	'82217',
	'82218',
	'82219',
	'82221',
	'82223',
	'82240',
	'82243',
	'82244',
	'76825',
	'76836',
	'76837',
	'76841',
	'76848',
	'76849',
	'76852',
	'76854',
	'76855',
	'76858',
	'76859',
	'76862',
	'76866',
	'76872',
	'76874',
	'76886',
	'76887',
	'76901',
	'76902',
	'76903',
	'76904',
	'76905',
	'76906',
	'76908',
	'76909',
	'76930',
	'76933',
	'76934',
	'76935',
	'76936',
	'76937',
	'76939',
	'76940',
	'76941',
	'76943',
	'76945',
	'76949',
	'76950',
	'76951',
	'76953',
	'76955',
	'76957',
	'76958',
	'59032',
	'59223',
	'59225',
	'59230',
	'59231',
	'59240',
	'59241',
	'59244',
	'59248',
	'59250',
	'59260',
	'59261',
	'59273',
	'59401',
	'59402',
	'59403',
	'59404',
	'59405',
	'59406',
	'59411',
	'59412',
	'59414',
	'59416',
	'59417',
	'59418',
	'59419',
	'59420',
	'59421',
	'59422',
	'59424',
	'59425',
	'59427',
	'59430',
	'59432',
	'59433',
	'59434',
	'59435',
	'59436',
	'59440',
	'59441',
	'59442',
	'59443',
	'59444',
	'59446',
	'59447',
	'59448',
	'59450',
	'59451',
	'59452',
	'59454',
	'59456',
	'59457',
	'59460',
	'59461',
	'59462',
	'59463',
	'59464',
	'59465',
	'59466',
	'59467',
	'59468',
	'59469',
	'59471',
	'59472',
	'59474',
	'59477',
	'59479',
	'59480',
	'59482',
	'59483',
	'59484',
	'59485',
	'59486',
	'59487',
	'59489',
	'59501',
	'59520',
	'59521',
	'59522',
	'59523',
	'59524',
	'59525',
	'59526',
	'59527',
	'59528',
	'59529',
	'59530',
	'59531',
	'59532',
	'59535',
	'59537',
	'59538',
	'59540',
	'59542',
	'59544',
	'59545',
	'59546',
	'59547',
	'45801',
	'45802',
	'45804',
	'45805',
	'45806',
	'45807',
	'45808',
	'45809',
	'45815',
	'45817',
	'45819',
	'45820',
	'45827',
	'45830',
	'45831',
	'45833',
	'45837',
	'45844',
	'45848',
	'45850',
	'45853',
	'45854',
	'45856',
	'45864',
	'45865',
	'45869',
	'45870',
	'45871',
	'45875',
	'45876',
	'45877',
	'45884',
	'45885',
	'45887',
	'45888',
	'45893',
	'45895',
	'45896',
	'47424',
	'47438',
	'47439',
	'47441',
	'47443',
	'47445',
	'47449',
	'47453',
	'47457',
	'47459',
	'47465',
	'47471',
	'47501',
	'47512',
	'47516',
	'47519',
	'47522',
	'47524',
	'47528',
	'47529',
	'47535',
	'47553',
	'47557',
	'47558',
	'47561',
	'47562',
	'47568',
	'47573',
	'47578',
	'47581',
	'47591',
	'47596',
	'47597',
	'47801',
	'47802',
	'47803',
	'47804',
	'47805',
	'47807',
	'47808',
	'47809',
	'47811',
	'47812',
	'47830',
	'47831',
	'47832',
	'47833',
	'47834',
	'47836',
	'47837',
	'47838',
	'47840',
	'47841',
	'47842',
	'47845',
	'47846',
	'47847',
	'47848',
	'47849',
	'47850',
	'47851',
	'47852',
	'47853',
	'47854',
	'47855',
	'47856',
	'47857',
	'47858',
	'47859',
	'47860',
	'47861',
	'47862',
	'47863',
	'47864',
	'47865',
	'47866',
	'47869',
	'47870',
	'47871',
	'47872',
	'47874',
	'47875',
	'47876',
	'47878',
	'47879',
	'47880',
	'47881',
	'47882',
	'47884',
	'47885',
	'47928',
	'47966',
	'47974',
	'62413',
	'62417',
	'62419',
	'62420',
	'62421',
	'62423',
	'62425',
	'62427',
	'62432',
	'62433',
	'62439',
	'62441',
	'62442',
	'62448',
	'62449',
	'62450',
	'62451',
	'62452',
	'62454',
	'62459',
	'62460',
	'62464',
	'62466',
	'62474',
	'62475',
	'62477',
	'62478',
	'62479',
	'62480',
	'62481',
	'62868',
	'36301',
	'36302',
	'36303',
	'36304',
	'36305',
	'36310',
	'36311',
	'36312',
	'36313',
	'36314',
	'36316',
	'36317',
	'36318',
	'36319',
	'36320',
	'36321',
	'36322',
	'36323',
	'36330',
	'36331',
	'36340',
	'36343',
	'36344',
	'36345',
	'36346',
	'36349',
	'36350',
	'36351',
	'36352',
	'36353',
	'36360',
	'36361',
	'36362',
	'36370',
	'36371',
	'36373',
	'36374',
	'36375',
	'36376',
	'36453',
	'36477',
	'39823',
	'39832',
	'39841',
	'39861',
	'52501',
	'52530',
	'52533',
	'52535',
	'52536',
	'52537',
	'52542',
	'52548',
	'52551',
	'52552',
	'52553',
	'52554',
	'52556',
	'52557',
	'52560',
	'52565',
	'52566',
	'52567',
	'52570',
	'52573',
	'52580',
	'52584',
	'52588',
	'52620',
	'52626',
	'52635',
	'52651',
	'63431',
	'63432',
	'63442',
	'63501',
	'63530',
	'63532',
	'63533',
	'63534',
	'63535',
	'63536',
	'63538',
	'63539',
	'63540',
	'63541',
	'63543',
	'63544',
	'63545',
	'63546',
	'63548',
	'63549',
	'63551',
	'63552',
	'63555',
	'63556',
	'63558',
	'63559',
	'63560',
	'63561',
	'63563',
	'63565',
	'63566',
	'63567',
	'64645',
	'64646',
	'64655',
	'64667',
	'64672',
	'65247',
	'70601',
	'70602',
	'70605',
	'70606',
	'70607',
	'70609',
	'70611',
	'70612',
	'70615',
	'70616',
	'70629',
	'70630',
	'70631',
	'70632',
	'70633',
	'70634',
	'70637',
	'70638',
	'70643',
	'70644',
	'70645',
	'70646',
	'70647',
	'70648',
	'70651',
	'70652',
	'70653',
	'70654',
	'70655',
	'70657',
	'70658',
	'70660',
	'70661',
	'70662',
	'70663',
	'70664',
	'70665',
	'70668',
	'70669',
	'71463',
	'38201',
	'38220',
	'38233',
	'38235',
	'38258',
	'38301',
	'38302',
	'38303',
	'38305',
	'38308',
	'38313',
	'38314',
	'38316',
	'38317',
	'38318',
	'38321',
	'38324',
	'38326',
	'38327',
	'38328',
	'38330',
	'38331',
	'38332',
	'38338',
	'38340',
	'38342',
	'38343',
	'38344',
	'38345',
	'38346',
	'38347',
	'38348',
	'38351',
	'38352',
	'38355',
	'38356',
	'38358',
	'38361',
	'38362',
	'38365',
	'38366',
	'38368',
	'38369',
	'38370',
	'38371',
	'38372',
	'38376',
	'38378',
	'38382',
	'38387',
	'38388',
	'38389',
	'38390',
	'38391',
	'38392',
	'38475',
	'49801',
	'49802',
	'49805',
	'49806',
	'49807',
	'49808',
	'49814',
	'49815',
	'49816',
	'49817',
	'49818',
	'49819',
	'49822',
	'49825',
	'49826',
	'49829',
	'49831',
	'49833',
	'49834',
	'49835',
	'49836',
	'49837',
	'49839',
	'49840',
	'49841',
	'49849',
	'49852',
	'49854',
	'49855',
	'49861',
	'49862',
	'49864',
	'49865',
	'49866',
	'49870',
	'49871',
	'49872',
	'49876',
	'49877',
	'49878',
	'49879',
	'49880',
	'49881',
	'49883',
	'49884',
	'49885',
	'49891',
	'49892',
	'49894',
	'49895',
	'49901',
	'49902',
	'49903',
	'49905',
	'49908',
	'49910',
	'49912',
	'49913',
	'49915',
	'49916',
	'49917',
	'49918',
	'49919',
	'49920',
	'49921',
	'49922',
	'49925',
	'49927',
	'49929',
	'49930',
	'49931',
	'49934',
	'49935',
	'49942',
	'49945',
	'49946',
	'49948',
	'49950',
	'49952',
	'49953',
	'49955',
	'49958',
	'49960',
	'49961',
	'49962',
	'49963',
	'49964',
	'49965',
	'49967',
	'49970',
	'49971',
	'54120',
	'54121',
	'54542',
	'26003',
	'26030',
	'26031',
	'26032',
	'26033',
	'26034',
	'26035',
	'26036',
	'26037',
	'26038',
	'26039',
	'26040',
	'26041',
	'26047',
	'26050',
	'26055',
	'26056',
	'26058',
	'26059',
	'26060',
	'26062',
	'26070',
	'26074',
	'26075',
	'26146',
	'26149',
	'26155',
	'26159',
	'26162',
	'26167',
	'26175',
	'26320',
	'26348',
	'26377',
	'26419',
	'26434',
	'26437',
	'26561',
	'26562',
	'26575',
	'26581',
	'43713',
	'43716',
	'43718',
	'43719',
	'43747',
	'43752',
	'43754',
	'43757',
	'43759',
	'43786',
	'43789',
	'43793',
	'43901',
	'43902',
	'43903',
	'43905',
	'43906',
	'43907',
	'43908',
	'43909',
	'43910',
	'43912',
	'43913',
	'43914',
	'43915',
	'43916',
	'43917',
	'43925',
	'43926',
	'43927',
	'43928',
	'43930',
	'43931',
	'43932',
	'43933',
	'43934',
	'43935',
	'43937',
	'43938',
	'43939',
	'43940',
	'43941',
	'43942',
	'43943',
	'43944',
	'43946',
	'43947',
	'43948',
	'43950',
	'43951',
	'43952',
	'43953',
	'43961',
	'43963',
	'43964',
	'43967',
	'43970',
	'43971',
	'43972',
	'43973',
	'43974',
	'43976',
	'43977',
	'43981',
	'43983',
	'43984',
	'43985',
	'43986',
	'43988',
	'44693',
	'44695',
	'44699',
	'35459',
	'35460',
	'35464',
	'35470',
	'35477',
	'36901',
	'36904',
	'36907',
	'36908',
	'36910',
	'36912',
	'36913',
	'36915',
	'36916',
	'36919',
	'36921',
	'36922',
	'36925',
	'39057',
	'39301',
	'39302',
	'39303',
	'39304',
	'39305',
	'39307',
	'39309',
	'39320',
	'39323',
	'39325',
	'39326',
	'39327',
	'39328',
	'39330',
	'39332',
	'39335',
	'39336',
	'39337',
	'39342',
	'39345',
	'39347',
	'39350',
	'39352',
	'39354',
	'39355',
	'39358',
	'39360',
	'39363',
	'39364',
	'39365',
	'38701',
	'38702',
	'38703',
	'38704',
	'38720',
	'38722',
	'38723',
	'38725',
	'38726',
	'38730',
	'38731',
	'38732',
	'38733',
	'38736',
	'38737',
	'38738',
	'38740',
	'38744',
	'38746',
	'38748',
	'38749',
	'38751',
	'38753',
	'38756',
	'38759',
	'38760',
	'38761',
	'38762',
	'38764',
	'38768',
	'38769',
	'38771',
	'38772',
	'38773',
	'38774',
	'38776',
	'38778',
	'38780',
	'38781',
	'38782',
	'38901',
	'38902',
	'38917',
	'38920',
	'38921',
	'38923',
	'38926',
	'38927',
	'38928',
	'38929',
	'38930',
	'38935',
	'38940',
	'38941',
	'38943',
	'38944',
	'38945',
	'38946',
	'38947',
	'38950',
	'38952',
	'38954',
	'38957',
	'38958',
	'38959',
	'38960',
	'38962',
	'38963',
	'38966',
	'39176',
	'71638',
	'71640',
	'71653',
	'26101',
	'26102',
	'26103',
	'26104',
	'26105',
	'26106',
	'26120',
	'26121',
	'26133',
	'26134',
	'26142',
	'26150',
	'26169',
	'26170',
	'26180',
	'26181',
	'26184',
	'26187',
	'45712',
	'45713',
	'45714',
	'45715',
	'45721',
	'45724',
	'45729',
	'45734',
	'45742',
	'45744',
	'45745',
	'45746',
	'45750',
	'45767',
	'45768',
	'45773',
	'45784',
	'45786',
	'45787',
	'45788',
	'45789',
	'69101',
	'69103',
	'69123',
	'69132',
	'69142',
	'69143',
	'69151',
	'69161',
	'69163',
	'69165',
	'69166',
	'69167',
	'69169',
	'69170',
])
