import { NextRequest, NextResponse } from 'next/server'
import { geolocation } from '@vercel/functions'
import { chooseExperimentVariant, COOKIE_FOR_IDS, COOKIE_FOR_KEYS, getActiveExperiments, IVariant } from 'ab_testing'
import { config as region_config } from 'utils/internationalization'
import { FRAGILE_ZIP_CODES } from 'utils/fragile-zips-dma-full'

export const config = {
	matcher: '/((?!api|blog|truemed-order|vanities|getcart|_next|static|public|favicon|robots|apple|sitemap|android|manifest|.well-known).*)',
}

export const MAIN_EXPERIMENT = 'root'

export function middleware(req: NextRequest) {
	const { pathname, hostname, href } = req.nextUrl

	// Check for webhooks domain restrictions
	if (hostname.startsWith('webhooks.') && !pathname.startsWith('/api/')) {
		return new NextResponse('Bad Request', { status: 400 })
	}

	// Handle special case for pod cover referral
	if (href === 'https://www.eightsleep.com/product/pod-cover/?utm_campaign=pod+referral+widget&utm_content=personal_url&utm_medium=referral&utm_source=friendbuy&fbuy_ref_code=r1f0w') {
		return NextResponse.redirect('https://www.eightsleep.com/product/pod-cover', 302)
	}

	// Lowercase URL path if it doesn't contain "thank_you"
	if (!pathname.includes('thank_you')) {
		const url = new URL(href)
		const lowercasedPathname = url.pathname.toLowerCase()

		if (lowercasedPathname !== url.pathname) {
			url.pathname = lowercasedPathname
			return NextResponse.redirect(url.toString(), 308) // 308 is Permanent Redirect that preserves the HTTP method
		}
	}

	// Get geolocation data
	const geo = geolocation(req)
	const country = geo?.country ?? 'US'
	const postalCode = geo?.postalCode ?? ''

	// Get cookies
	const cookies = req.cookies
	const hasUsaCookie = cookies.get('choseusa')?.value === 'true'

	// Handle country-based redirects
	if (country in countryMap && !alreadyInRegion(pathname) && !hasUsaCookie && !hostname.startsWith('site.')) {
		const isShopPage = pathname.includes('/product/')
		const isPodShop =
			pathname.includes('/product/pod-cover') || pathname.includes('/product/pod-mattress') || pathname.includes('/product/eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208')

		if (isShopPage && !isPodShop) {
			const newUrl = new URL(href)
			return NextResponse.redirect(newUrl.origin, 302)
		}

		if (country === 'SA') {
			const newUrl = new URL(href)
			newUrl.pathname = `/sa${pathname}`
			return NextResponse.redirect(newUrl.toString(), 302)
		}

		const newUrl = new URL(href)
		newUrl.pathname = `/${countryMap[country]}${pathname}`
		return NextResponse.redirect(newUrl.toString(), 302)
	}

	let response: NextResponse
	const cookie1 = req.cookies.get(COOKIE_FOR_IDS)?.value
	const cookie2 = req.cookies.get(COOKIE_FOR_KEYS)?.value
	const cookie_ab_tests = cookie1?.split(';')
	const cookie_ab_tests_keys = cookie2?.split(';')

	const IS_FRAGILE_DMA_TARGET = country === 'US' && FRAGILE_ZIP_CODES.has(postalCode)

	const url = req.nextUrl
	const original_pathname = url.pathname

	// Remove '/mx/' from URL path if hostname is .com.mx
	if (hostname.endsWith('.com.mx') && original_pathname.startsWith('/mx/')) {
		const newUrl = new URL(href)
		newUrl.pathname = original_pathname.replace('/mx/', '/')
		return NextResponse.redirect(newUrl.toString(), 308) // 308 is Permanent Redirect that preserves the HTTP method
	}

	if (!region_config.allRegions.find((it) => original_pathname.startsWith(`/${it}/`)) && !hostname.endsWith('.com.mx')) {
		url.pathname = original_pathname.replace('/', '/us/')
	}

	const skipABTest = href.includes('automated=true')
	if (skipABTest) {
		url.pathname = url.pathname.replace('/', `/${MAIN_EXPERIMENT}/`)
		return NextResponse.rewrite(url)
	}

	const active_experiments = getActiveExperiments()
	const testVariants: string[] = []
	const testKeys: string[] = []
	active_experiments.forEach((test) => {
		const active_cookie_test = cookie_ab_tests ? cookie_ab_tests.find((it) => it.startsWith(test.id)) : null
		const active_cookie_test_key = cookie_ab_tests_keys ? cookie_ab_tests_keys.find((it) => it.startsWith(test.key)) : null
		if (active_cookie_test && active_cookie_test_key && !!test.variants.find((it) => it.id === Number(active_cookie_test.split('.')[1]))) {
			testVariants.push(active_cookie_test)
			testKeys.push(active_cookie_test_key)
		} else if ((test.paths.length === 0 || test.paths.includes(original_pathname)) && !test.excludedPaths?.includes(original_pathname)) {
			let variant: IVariant = null
			if (test.id === 'fragileAllDmaAB') {
				// For fragileAllDmaAB test, assign variant based on location
				variant = test.variants[IS_FRAGILE_DMA_TARGET ? 1 : 0]
			} else {
				// For other tests, use random assignment with exclusions
				do {
					variant = chooseExperimentVariant(test)
				} while (variant.exclude && variant.exclude.some((it) => href.includes(it)))
			}
			testVariants.push(`${test.id}.${variant.id}`)
			testKeys.push(`${test.key}_${variant.id}`)
		}
	})

	if (testVariants.length > 0) {
		// inject into the URL only those experiments which are not in the default variant 0
		const active_variants = testVariants
			.filter((it) => !it.endsWith('.0'))
			.filter((it) => {
				const at = active_experiments.find((test) => test.id === it.split('.')[0])
				return at && !at.client_only && (at.paths.length === 0 || at.paths.includes(original_pathname)) && !at.excludedPaths?.includes(original_pathname)
			})

		active_variants.sort((a, b) => a.length - b.length || a.localeCompare(b))

		url.pathname = active_variants.length > 0 ? url.pathname.replace('/', `/${active_variants.join(';')}/`) : url.pathname.replace('/', `/${MAIN_EXPERIMENT}/`)
		response = NextResponse.rewrite(url)

		const new_id_cookie = testVariants.join(';')
		if (cookie1 !== new_id_cookie) {
			const keys_cookie = testKeys.join(';')
			const days = 60
			const domain = !hostname.startsWith('localhost') ? `.${hostname}` : hostname
			response.cookies.set(COOKIE_FOR_IDS, new_id_cookie, { maxAge: 60 * 60 * 24 * days, domain })
			response.cookies.set(COOKIE_FOR_KEYS, keys_cookie, { maxAge: 60 * 60 * 24 * days, domain })
		}
	} else {
		url.pathname = url.pathname.replace('/', `/${MAIN_EXPERIMENT}/`)
		response = NextResponse.rewrite(url)
	}

	response.cookies.set('geo-country', country, { maxAge: 60 * 60 * 24 })

	return response
}

// Country to region mapping
const countryMap = {
	CA: 'ca',
	AU: 'au',
	GB: 'uk',
	SE: 'se',
	DK: 'dk',
	AE: 'ae',
	SA: 'sa',
	CH: 'ch',
	MX: 'mx',
	// EU
	DE: 'eu',
	FR: 'eu',
	IT: 'eu',
	ES: 'eu',
	NL: 'eu',
	BE: 'eu',
	FI: 'eu',
	LT: 'eu',
	PT: 'eu',
	BG: 'eu',
	LU: 'eu',
	RO: 'eu',
	CZ: 'eu',
	HU: 'eu',
	SI: 'eu',
	HR: 'eu',
	SK: 'eu',
	EE: 'eu',
	AT: 'eu',
	IE: 'eu',
	LV: 'eu',
	PL: 'eu',
}

const alreadyInRegion = (pathname: string): boolean => {
	// Matches region and then either / or end of string
	if (pathname.match(/^\/(au|ca|eu|uk|se|dk|ae|sa|ch|mx)($|\/)/)) return true
	return false
}
