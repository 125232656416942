import { Product } from 'utils/events'
import { AffirmLocations, BundleInteractionType, HomePageHeroCTAType, ProactiveInteractionType } from 'constants/events'
import { formatLink, formatURL } from 'utils/formatters'
import { sha256 } from 'js-sha256'
import { getCookieABTests } from 'ab_testing'
import * as amplitude from '@amplitude/analytics-browser'
import Stripe from 'stripe'
import { datadogReportAPICall, datadogReportEvent } from 'events/datadog'
import { AddressVerificationData } from 'components/AddressVerification'

declare global {
	interface Window {
		amplitude?: any
	}
}

enum UserPropertyOperation {
	APPEND = 'append',
	NEW = 'new',
}
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_IS_PROD === 'true' ? 'b939591fd87a7f2d814ac84016c60699' : '3d58ab67e56af197929011a29b73a84f'

const SYSTEM_COOKIE_MAX_AGE = 7 * 24 * 60 * 60
const SYSTEM_AMPLITUDE_COOKIE_NAME = 'SYSTEM_AMP_DEV_ID'

const setAmplitudeCookie = (id?: string): void => {
	if (typeof window !== 'undefined') {
		const d = new Date(Date.now() + SYSTEM_COOKIE_MAX_AGE * 1000)
		const expires = 'expires=' + d.toUTCString()
		const domain = window?.location?.hostname ? `domain=.${window.location.hostname}` : ''
		const secure = process.env.NODE_ENV === 'production' ? 'secure' : ''
		document.cookie = `${SYSTEM_AMPLITUDE_COOKIE_NAME}=${id};${expires};path=/;sameSite=lax;${domain};${secure}`
	}
}

function logEvent(eventType: string, eventProperties: any = null, functionName: string = null) {
	setAmplitudeCookie(amplitude.getDeviceId())
	try {
		if (typeof window === 'undefined' && !window) {
			return
		}

		if ((window as any)['CONFIG_TYPE'] === 'DEVELOPMENT' || window.location.hostname.includes('chromatic.com')) {
			console.log('Amplitude not available in Storybook', eventType)
			return
		}

		if (sessionStorage.getItem('automated') === 'true' || window.location.search.includes('automated=true')) {
			console.log('Amplitude not available in automated testing', eventType)
			return
		}

		const tests = [...getCookieABTests().keys]
		const abTestsProp = {
			abTests: tests,
		}

		amplitude.track(eventType, { ...abTestsProp, ...eventProperties }).promise.then((res) => {
			if (functionName && sessionStorage.getItem(functionName)) {
				sessionStorage.removeItem(functionName)
			}
			datadogReportAPICall(res.code, eventType, eventProperties)
		})
		datadogReportEvent('success', eventType, eventProperties)
	} catch (err) {
		console.log(err)
		datadogReportEvent('failure', eventType, eventProperties)
	}
}

export const amplitudeThankYou = (invoice: Stripe.Invoice) => {
	console.log('thank you triggered')
	let totalProducts = 0
	invoice.lines.data.forEach((line) => {
		purchaseProduct(line, invoice.id)
		totalProducts += line.quantity
	})
	revenue(invoice.total / 100, totalProducts, invoice.id)
	logEvent('Order Confirmation')
	logEvent('Checkout Complete')
	logEvent('Checkout - Completed Purchase', {
		orderId: invoice.id,
		totalOrderValue: invoice.total / 100,
		isPodOrder: false,
		isPodProOder: false,
		dateTime: new Date().toLocaleString('en-US', {
			timeZone: 'America/New_York',
		}),
	})
}
export const purchaseProduct = (item: Stripe.InvoiceLineItem, transactionId) => {
	logEvent('eCommerce - Purchase - Item', {
		Id: item.price.metadata?.sku,
		Name: item.description.replace(/[^ -~]+/g, ''),
		Price: item.amount / 100,
		Quantity: item.quantity,
		'Total Product Amount': (item.amount * item.quantity) / 100,
		'Transaction Id': transactionId,
	})
}

export const revenue = (price, productCount, transactionId) => {
	const revenue = new amplitude.Revenue()
	revenue.setPrice(price)
	revenue.setQuantity(1)
	revenue.setEventProperties({
		'Product Count': productCount,
		'Transaction Id': transactionId,
	})
	amplitude.revenue(revenue)
}

export const appendUserProperty = (property: string, value: any = null, type: UserPropertyOperation = UserPropertyOperation.APPEND) => {
	try {
		if (property && value && type === UserPropertyOperation.APPEND) {
			const data = {}
			data[property] = value
			const identifyEvent = new amplitude.Identify().append(property, value)
			amplitude.identify(identifyEvent)
		}
		if (property && value && type === UserPropertyOperation.NEW) {
			const identifyEvent = new amplitude.Identify().set(property, value)

			amplitude.identify(identifyEvent)
		}
	} catch (err) {
		console.log(err)
	}
}

export const trackHasVisitedSite = () => {
	appendUserProperty('hasVisitedWebsite', true, UserPropertyOperation.NEW)
}

export const amABTests = () => {
	const savedTests = localStorage.getItem('ab-tests')?.split(',') || []
	const newTests: string[] = []

	try {
		const otherTests = [...getCookieABTests().keys]
		for (const test of otherTests) {
			const key = test.split('_')[0]
			const value = test.split('_')[1]
			if (savedTests.indexOf(key) === -1) {
				newTests.push(`${key}_${value}`)
				savedTests.push(key)
			}
		}

		localStorage.setItem('ab-tests', savedTests.toString())
		appendUserProperty('ab-tests', newTests, UserPropertyOperation.APPEND)
	} catch (err) {
		console.log(err)
	}
}

// Rajiv Checked
export function amPageView() {
	try {
		logEvent('Viewed Page View', {
			URL: formatURL(window.location.href),
		})
	} catch (e) {
		console.log(e)
	}
}

// currently not in use
// Rajiv checked
export function amRemoveFromCart(name, sku, price, quantity = 1) {
	logEvent('Remove from Cart', {
		name,
		sku,
		price,
		quantity,
		URL: formatURL(window.location.href),
	})
}

// not in use
export function amCtaClick(button_name) {
	logEvent('Click - Info - The Pod', { 'Button Name': button_name })
}

// Rajiv checked and validated
export function amClickBeds(bedType) {
	logEvent('Shop - Beds', {
		'Source Page URL': formatURL(window.location.href),
		'Bed Type': bedType,
	})
}

export function amClickDropdownAccessory(name, location, badge) {
	logEvent('Click - Dropdown - Accessories', {
		'Source Page URL': formatLink(formatURL(window.location.href)),
		name,
		location,
		badge,
	})
}

// Rajiv checked and validated
export function amClickAccessories(type) {
	logEvent('Shop - Accessories', {
		'Source Page URL': formatURL(window.location.href),
		'Accessory Type': type,
	})
}

// Rajiv checked and validated
export function amClickNav(target) {
	logEvent('Click - Navigation - Top', {
		'Source Page URL': formatURL(window.location.href),
		Destination: target,
	})
}

// Rajiv checked and validated footer and checkout
export function amSubscribeEmail(email, location) {
	logEvent('Subscribe', { Email: email, 'Subscribe Location': location })
}

export function amSubscribeReferralEmail(email, location, referralCode) {
	logEvent('Subscribe', { Email: email, 'Subscribe Location': location, 'Referred By': referralCode, referral_modal: 'true' })
}

// not in use
// Rajiv checked and validated
export function amAppDownload(os, page = 'pod_landing_page') {
	logEvent('App Download', { 'App Store': os, 'Page Position': page })
}
// Rajiv checked and validated
export function amClickFaq(question) {
	logEvent('Click - FAQ - The Pod', { Question: question })
}

// Rajiv checked and validated
export const amClickSocialMedia = (target) => {
	logEvent('Click - Social Media External', {
		'Source Page URL': window.location.href,
		Destination: target,
	})
}

// Rajiv checked and validated
export const amClickSupport = (target) => {
	logEvent(`Click - Support - ${target}`, {
		'Source Page URL': window.location.href,
	})
}

// Rajiv checked and validated
export const amClickFooter = (target) => {
	logEvent('Click - Footer Button - General', {
		'Source Page URL': window.location.href,
		Destination: target,
	})
}

// TODO: rename the below events

// Rajiv checked and validated
export const amOpenCart = () => {
	logEvent('Open or Close - Shopping Cart', {
		'Source Page URL': formatURL(window.location.href),
		Action: 'open',
	})
}

// Rajiv checked and validated
export const amCloseCart = () => {
	logEvent('Open or Close - Shopping Cart', {
		'Source Page URL': formatURL(window.location.href),
		Action: 'close',
	})
}

// Rajiv checked and validated
export const amClickUseCase = (target) => {
	logEvent(`Click - Use Case - ${target}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// Rajiv checked and validated
export const amViewToolTip = (target) => {
	logEvent(`View ToolTip`, {
		'Source Page URL': formatLink(formatURL(window.location.href)),
		'Tool Tip': target,
	})
}

// Rajiv checked and added to new twitter slider on podlanding; validated
export const amClickTwitterSlider = (target) => {
	logEvent(`Click Twitter Slider -  ${target}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

export const amClickCheckoutPayment = (target) => {
	logEvent(`Initiated Payment - ${target}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// Not in use
// Rajiv has checked
export const amViewTypography = () => {
	logEvent(`View Typography`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// not in use
// Rajiv has checked
export const amClickUrgencyArea = () => {
	logEvent(`Click Urgency 'X In Your Area'`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// on who-we-are
// this currently works any time bio is clicked (expanded or closed)
// Rajiv has checked and validated
export const amClickBioCard = (name) => {
	logEvent('Expand Scientist Card', {
		'Scientist Name': name,
		'Source Page URL': window.location.href,
	})
}

// not currently in use
// Rajiv has checked
export const amFirstLandingPageVisit = () => {
	logEvent('First Landing Page Visit', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

// not in use
// rajiv has checked
// export const handleFirstPageVisit = () => {
// 	try {
// 		window.amplitude.getInstance().onInit()(() => {
// 			setTimeout(() => {
// 				amFirstLandingPageVisit()
// 			}, 2000)
// 		})
// 	} catch (e) {
// 		console.log(e)
// 	}
// }

// not in use
// Rajiv has checked
export const amShowUrgencyTest = (dates, zipcode, qi = null, ki = null, cki = null) => {
	logEvent(`Show Urgency Dates`, {
		Dates: dates,
		Zipcode: zipcode,
		'Queen Invetory': qi,
		'King Inventory': ki,
		'Cali King Inventory': cki,
		'Source Page URL': formatURL(window.location.href),
	})
}

// click to get details for nearby store modal
// not in use
// Rajiv has checked
export const amClickNearbyStoreModalDetails = (store: string) => {
	logEvent(`Click Nearby Store Modal - ${store}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// clicked map on nearby store modal
// not in use
// Rajiv has checked
export const amClickNearbyStoreModalMap = (store: string) => {
	logEvent(`Click Nearby Store Modal Map - ${store}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// complete contact information
// Rajiv has checked and validated
export const amCompleteContactInformation = () => {
	logEvent(`Checkout - Complete Contact Info`)
}

// Rajiv has checked and validated
export const amCompletePaymentMethodSelection = (paymentMethod, itemNames: string[]) => {
	// we do this to not break tracking w/ GA. Eventually we should GA to use the 'pay now - ...' convention
	const formattedPaymentName = paymentMethod === 'recharge' ? 'pay now - recharge' : paymentMethod
	logEvent(`Checkout - Completed Payment Method`, {
		paymentMethod: formattedPaymentName,
		itemNames,
	})
}

// Rajiv has checked and validated
export const amInitiateCheckout = (itemNames: string[]) => {
	logEvent(`Checkout - Initiated`, {
		'Source Page URL': formatURL(window.location.href),
		itemNames,
	})
}

// TODO: rajiv validate
export const amAddEmailUserProperty = (email) => {
	appendUserProperty('email', email, UserPropertyOperation.NEW)
}

// Rajiv has checked and validated
export const amEnterEmailInCheckout = (email: string) => {
	logEvent('Checkout - Enter Email', { email })
}

export const amEnterShippingAddressInCheckout = (address: any) => {
	logEvent('Checkout - Enter Shipping Address', { address })
}

// Rajiv has checked and validated
export const amAddToCartV1 = (product: Product, saveToSessionStorage: boolean) => {
	logEvent('Add to Cart', product)
	// this is the legacy event we can use for historical comparison

	if (saveToSessionStorage) sessionStorage.setItem('amAddToCartV1', JSON.stringify(product))
	logEvent('eCommerce - AddToCart - Item', product, 'amAddToCartV1')
}

export const removeFromCart = (product: Product) => {
	logEvent('Remove from Cart', product)
}

export const updateItemInCart = (product: Product, action: string) => {
	logEvent(`${action} item quantity in cart`, product)
}

// Rajiv has checked and validated
export const amClickAffirmLearnMore = (location: AffirmLocations) => {
	logEvent('Clicked Affirm Learn more', { location })
}

// Rajiv has checked and validated
export const amViewedPageSet = (eventName, pageType) => {
	logEvent(eventName, { pageType })
}

// not in use
// Rajiv has checked and validated
export const amClickedShopReviewStars = () => {
	logEvent('Click Review Stars', {
		location: formatLink(formatURL(window.location.href)),
	})
}

export const amPageLoaded = () => {
	const loadTime = window.performance.timing.loadEventEnd - window.performance.timing.navigationStart
	logEvent('Page Loaded', {
		location: formatLink(formatURL(window.location.href)),
		'Source Page URL': window.location.href,
		'Load Time': loadTime,
	})
}

// Rajiv has checked and validated
export const amInteractedPaidAccessoryDescription = (accessory, actionType) => {
	// actionType is Expanded or Closed
	logEvent('Upsell Accessory - Description ' + actionType, {
		accessory,
		location: formatLink(formatURL(window.location.href)),
	})
}

// not in use
// Rajiv has checked
export const amInteractedFreeAccessoryDescription = (accessory, actionType) => {
	logEvent('Free Accessory - Description ' + actionType, {
		accessory,
	})
}

// not in use
// Rajiv has checked
export const amToggledFreeAccessory = (accessory) => {
	logEvent('Free Accessory - Toggled', {
		accessory,
	})
}

// not in use
// Rajiv has checked
export const amSelectedFreeAccessory = (accessory) => {
	logEvent('Free Accessory - Selected', {
		accessory,
	})
}

// Rajiv has checked and validated
export const amClosedPromoBar = () => {
	logEvent('Promo Bar - Closed', {
		location: formatLink(formatURL(window.location.href)),
	})
}

// not in use??
// Rajiv has checked
export const amWatchedVideo = () => {
	logEvent('Video - Viewed', {
		location: formatLink(formatURL(window.location.href)),
	})
}

// not in use
// Rajiv has checked
export const amClickedFeatureBlock = (feature) => {
	logEvent('Clicked Feature Block', {
		feature,
	})
}

// not in use
// Rajiv has checked
export const amClickHomePageHeroCTA = (cta: HomePageHeroCTAType) => {
	logEvent('Click Home Page Hero CTA', { cta })
}

// no longer in use
// Rajiv has checked
export const amClickChevronSubNav = (action: string) => {
	logEvent('Click Subnav Chevron', { action })
}

// not in use
// Rajiv has checked
export const amOpenFreeAccessoryModal = (accessory) => {
	logEvent('Open Free Accessory Modal', {
		accessory,
		location: formatLink(formatURL(window.location.href)),
	})
}

// Rajiv has checked and validated
export const amClickedSelectedPodProduct = (variant: string, id: number) => {
	logEvent('Clicked Pod Product', {
		variant,
		id,
	})
}

// Rajiv has checked and validated
export const amClickedChat = () => {
	logEvent('Clicked Chat', {
		URL: formatURL(window.location.href),
	})
}

// Rajiv has checked and validated
export const amViewedPodSpecs = () => {
	logEvent('View Specs - The Pod')
}

// Rajiv has checked and validated
export const amClickLink = (to, htmlId) => {
	logEvent('Click Button', {
		'Source Page URL': window.location.href,
		target: to,
		'HTML element id': htmlId,
	})
}

export const setUserIdAsHashedEmail = (email: string) => {
	const hashedEmail = sha256(email)
	try {
		if (!amplitude.getUserId()) {
			amplitude.setUserId(hashedEmail)
		}
	} catch (e) {
		console.log(e)
	}
}

export const amClickCallUsCard = (option: string) => {
	logEvent('Clicked Call Us Card', {
		URL: formatLink(window.location.href),
		option,
	})
}

export const amClickedStudy = (study: string) => {
	logEvent('Clicked Study', {
		URL: formatLink(formatURL(window.location.href)),
		study,
	})
}

export const amCompareSliderInteraction = () => {
	logEvent('Interacted with Compare Slider')
}

export const amTechSliderInteraction = (title: string) => {
	logEvent('Tech Slider Interaction', { title })
}

export const amClickedUpsellSlider = (sliderName: string) => {
	logEvent('Clicked Upsell Slider', { sliderName })
}

export const amTrackBundleInteraction = (interaction: BundleInteractionType, name: string) => {
	logEvent(interaction + ' Bundle', { name })
}

export const amTrackAccesoryCardV2ButtonClick = (to: string) => {
	try {
		logEvent('Clicked Accessory Card Button', {
			from: formatLink(formatURL(window.location.href)),
			to,
		})
	} catch (e) {
		console.log(e)
	}
}

export const amTrackProactiveChatInteraction = (interactionType: ProactiveInteractionType) => {
	logEvent(interactionType + ' Proactive Chat', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedShopSectionOnVanity = (name: string) => {
	logEvent('Viewed shop section on T1/T2', {
		name: name,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedShopPage2xIn90Days = (daysInBetweenVisits: number) => {
	logEvent('Viewed Pod Shop Page 2x in 90 days', { daysInBetweenVisits })
}

export const amViewedShopPage2xIn28Days = (daysInBetweenVisits: number) => {
	logEvent('Viewed Pod Shop Page 2x in 28 days', { daysInBetweenVisits })
}

export const amClickedGridFilter = (filter: string) => {
	logEvent('Clicked Grid Filter', {
		URL: formatLink(window.location.href),
		filter,
	})
}

export const amTrackClickedVideoOnReviewCard = (title: string, id: string) => {
	logEvent('Watched Review Card Video', {
		title,
		id,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTrackClickedCoverExplainerVideoPlay = () => {
	logEvent('Watched Cover Explainer video', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

// index in list of mattress bundles
export const amAddMattressBundleToCart = (title: string, ids: number[], isPodPro: boolean, size: string, index: number) => {
	logEvent('Peloton Bundle - Added Mattress Bundle To cart', {
		title,
		ids,
		isPodPro,
		size,
		index,
	})
}

export const amTrackMattressBundleSlideDown = (title: string, ids: number[], isPodPro: boolean, size: string, index: number) => {
	logEvent('Peloton Bundle - Expanded Mattress Bundle Includes List', {
		title,
		ids,
		isPodPro,
		size,
		index,
	})
}

export const amAddAppleStyleUpsellToCart = (title: string, productId: number, variantId: number) => {
	logEvent('Apple Bundle - Add Upsell To Cart', {
		title,
		productId,
		variantId,
	})
}

export const amClickShopTab = (title: string) => {
	logEvent('Clicked Tab - Shop Page', {
		title,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEmailCapClickBookDemo = (properties) => {
	logEvent('Email Cap - Click Book Demo', { ...properties })
}

export const amEmailCapQuizChoice = (choice, modalName) => {
	logEvent('Quiz Email Capture - ' + choice, { Type: modalName })
}

export const amViewedEmailCap = (modalName: string) => {
	logEvent('Email Cap Modal Shown', {
		Modal: modalName,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedAssistedModal = (modalName: string) => {
	logEvent('Assisted Sale Modal Shown', {
		Modal: modalName,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedSecondaryEmailCapModal = (modalName: string) => {
	logEvent('Secondary Email Cap Modal Shown', {
		Modal: modalName,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEmailButtonModalPress = (modalName: string, buttonPressed: string) => {
	logEvent(`Clicked ${buttonPressed}`, {
		Modal: modalName,
	})
}

export const amTrackEmailModalFloatingButtonPress = () => {
	logEvent(`Clicked ecap floating button`, {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTrackEmailModalDismissFloatingButtonPress = () => {
	logEvent(`Dismiss ecap floating button`, {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscribedViaEmailCap = (properties) => {
	logEvent('BZ - Email Capture', {
		...properties,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEcapQuizShown = () => {
	logEvent('Ecap Quiz Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSecondaryQuizEcapShown = () => {
	logEvent('Secondary Special Quiz Ecap Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSecondaryQuizEcapStepShown = (step: number) => {
	logEvent(`Secondary Special Quiz Ecap Step ${step} shown`, {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEcapQuizAnswered = (answer: string, question: string) => {
	logEvent('Clicked Ecap Quiz Question', {
		Answer: answer,
		Question: question,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSecondaryQuizEcapAnswered = (answer: string, question: string) => {
	logEvent('Clicked Secondary Quiz Ecap Question', {
		Answer: answer,
		Question: question,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSelectReasonsNotYes = (action: string, reason: string, index: number) => {
	logEvent(`${action} - ${reason}`, { index })
}

export const amCompleteFollowUpQuestionEmailCap = (question: string, reasons: string[] | string, modalName: string) => {
	logEvent('Completed Follow Up Question', { question, reasons, modalName })
}

export const amOptInSMS = (choice: boolean) => {
	logEvent(`Opt ${choice ? 'in' : 'out'} SMS`)
	appendUserProperty('opt_in_sms', choice, UserPropertyOperation.NEW)
}

export const amClickTonePolicyLink = () => {
	logEvent('Tone - Clicked Policy Link')
}

export const amClickFinancingModalAffirm = (location) => {
	logEvent('Clicked Learn more - Affirm', {
		URL: location ? location : formatLink(formatURL(window.location.href)),
	})
}

export const amClickAssistedSaleCall = (location) => {
	logEvent('Assisted Sales - Clicked Call', {
		URL: formatLink(formatURL(window.location.href)),
		location: location,
	})
}

export const amClickAccessoryVideoLink = (item: string, type: string) => {
	logEvent('Clicked Accessory Video Link', {
		item,
		type,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickBlueAccessoryCard = (item: string) => {
	logEvent('Clicked Blue Accessory Card', {
		item,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickFinancingBannerButton = (item: string) => {
	logEvent('Clicked Financing Banner Button', {
		item,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickAssistedSaleChat = (location = null) => {
	logEvent('Assisted Sales - Clicked Chat', {
		URL: formatLink(formatURL(window.location.href)),
		location: location,
	})
}

export const amClickAssistedSaleDemo = (location) => {
	logEvent('Assisted Sales - Clicked Book Demo', {
		URL: formatLink(formatURL(window.location.href)),
		location: location,
	})
}

export const amInteractWithCartUpsellLearnMore = (item: string) => {
	logEvent('Cart Upsell Interaction - Learn more', {
		URL: formatLink(formatURL(window.location.href)),
		item,
	})
}

export const amClickedContactUsItem = (contactMethod: string) => {
	logEvent('Clicked Contact Item', {
		URL: formatLink(formatURL(window.location.href)),
		contactMethod,
	})
}
export const amClickSleepFitnessProductCard = (item: string) => {
	logEvent('Clicked Sleep Fitness Product Card', {
		item,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedVideoInComfortSlider = (name: string, id) => {
	logEvent('Comfort Slider - Viewed Video', {
		name,
		id,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedAllVideosInComfortSlider = () => {
	logEvent('Comfort Slider - Viewed All Videos', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSelectedMattressComfortLayers = (name: string) => {
	logEvent('Comfort Layers - Clicked Mattress', {
		name,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickedComfortLayer = (name: string, videoTitle: string, arrow: string) => {
	logEvent('Comfort Layers - Clicked Layer', {
		name,
		videoTitle,
		arrow,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amInteractedWithAllComfortLayersForMattress = (name: string) => {
	logEvent('Comfort Layers - Interacted With All Layers', {
		name,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amInteractedWithAllMattressesComfortLayers = () => {
	logEvent('Comfort Layers - Interacted With All Mattresses', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSwitchProduct = (name: string) => {
	logEvent('Switched Product', {
		name,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickAwardWinningPressLink = (source: string) => {
	logEvent('Clicked Award Winning Press Link', {
		source,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export type NavLinkType =
	| 'Main'
	| 'Desktop Pods Dropdown'
	| 'Mobile Pods Dropdown'
	| 'Desktop Pods Dropdown Image'
	| 'Desktop Accessories Dropdown Image'
	| 'Desktop Accessories Dropdown'
	| 'Mobile Accessories Dropdown'
	| 'Mobile Subnav'
	| 'Desktop Nav Dropdown'

export function amClickNavLink(type: NavLinkType, target: string) {
	logEvent('Click Nav Link', {
		'Source Page URL': formatURL(window.location.href),
		type: type,
		name: target,
	})
}

export const trackClickSMSJoin = (location = 'na') => {
	logEvent('Click SMS Signup', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSMSCapture = (location = 'na') => {
	logEvent('Submit SMS Capture', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const clickedPlusMembership = (location = 'na') => {
	logEvent('Clicked 8+ Membership Modal', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const clickedTooltip = (location = 'na') => {
	logEvent('Clicked Tooltip', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const clickedTwitterCTA = (location = 'na', href = 'na') => {
	logEvent('Clicked Twitter Wall CTA', {
		href: href,
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectMembershipPlan = (plan: string) => {
	logEvent('Select membership in modal', {
		membership: plan,
		location: 'membership change modal',
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackCartMembershipUpdate = (plan: string) => {
	logEvent('Update cart membership', {
		membership: plan,
		location: 'cart',
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectBasicPlan = (location: string) => {
	logEvent('8PLUS - Select Basic Plan', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectProPlan = (location: string) => {
	logEvent('8PLUS - Select Pro Plan', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectProPlanAnnual = (location: string) => {
	logEvent('8PLUS - Select Pro Plan Annual', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectElitePlan = (location: string) => {
	logEvent('8PLUS - Select Elite Plan', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackPlanHoverInfo = (tooltip: string, plan: 'basic' | 'pro' | 'standard', location: string) => {
	logEvent('8PLUS - View Plan Tooltip', {
		plan: plan,
		tooltip: tooltip,
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackPlanFeatureHoverInfo = (tooltip: string) => {
	logEvent('8PLUS - View Plan Feature Tooltip', {
		tooltip: tooltip,
		location: 'select-plan',
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCheckoutCanadaWarning = (itemNames: string[]) => {
	logEvent(`Checkout - Canada Warning`, {
		'Source Page URL': formatURL(window.location.href),
		itemNames,
	})
}

export const amClickPricingSectionAffirm = (target) => {
	logEvent('Shop Page - Price Toggle', {
		'Source Page URL': formatURL(window.location.href),
		'Finance Type': target,
	})
}
export function amClickHamburgerABC(status: 'Open' | 'Close') {
	logEvent('Hamburger Menu - Open or Close', {
		'Source Page URL': formatURL(window.location.href),
		Action: status,
	})
}

export function amHamburgerABCLink(url) {
	logEvent('Hamburger Menu - Select Link', {
		'Source Page URL': formatURL(window.location.href),
		Destination: url,
	})
}

export function amHamburgerABCDropdown(title) {
	logEvent('Hamburger Menu - Open Submenu', {
		'Source Page URL': formatURL(window.location.href),
		Submenu: title,
	})
}

export function amClickedLeasePriceBlock(status: 'Full Price' | 'Lease') {
	logEvent('Clicked Lease Pricing Block', {
		'Source Page URL': formatURL(window.location.href),
		Action: status,
	})
}

export function amClicked8PlusModal() {
	logEvent('Clicked 8+ Modal', {
		'Source Page URL': formatURL(window.location.href),
		Action: 'Clicked 8+ Modal',
	})
}

export function amClickedCoverDualZone(title) {
	logEvent('Clicked Cover DualZone', {
		'Source Page URL': formatURL(window.location.href),
		Title: title,
	})
}

export function amClickedSleepCoachingEmailCap() {
	logEvent('Clicked Sleep Coach Modal CTA', {
		'Source Page URL': formatURL(window.location.href),
	})
}

export function amClickedAccordionPanel(title: string) {
	logEvent(`Open drawer "${title}"`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

export function amClickedButton(name, htmlId, trackingData?: Record<string, string>) {
	logEvent('Click Button', {
		'Name (Button copy)': name,
		'HTML element id': htmlId,
		'Source Page URL': formatURL(window.location.href),
		...trackingData,
	})
}

export function amClickedFAQ(question: string) {
	logEvent('Click Button', {
		Question: question,
		'Source Page URL': formatURL(window.location.href),
	})
}

export const amViewedSleepCoachingEmailCap = () => {
	logEvent('Sleep Coaching Intro Modal Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export function amAIPoweredInsightsModal(title: string, htmlId: string) {
	logEvent('Click Button', {
		'Source Page URL': formatURL(window.location.href),
		'HTML element id': htmlId,
		Title: title,
		Action: "Clicked 'learn more'",
	})
}

// Rajiv checked and validated
export const amClickSelectPlanFAQ = (q) => {
	logEvent(`Expanded FAQ`, {
		'Source Page URL': formatURL(window.location.href),
		Title: 'Expanded FAQ',
		Action: q,
	})
}

export function amExpandedShopPageFeatureCard(feature: string) {
	logEvent('Expanded feature card', {
		'Source Page URL': formatURL(window.location.href),
		Title: feature,
	})
}

export const amUpdateUserUTMs = (utm_medium: string, utm_source: string) => {
	const identify = new amplitude.Identify()
	identify.set('utm_medium', utm_medium).set('utm_source', utm_source)
	amplitude.identify(identify)
}

export const amSubscribedViaSMSCap = (properties) => {
	logEvent('BZ - SMS Capture', {
		...properties,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCheckIncludeMembership = (checked: boolean) => {
	logEvent('Variant check add 8+ Membership', {
		checked,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTonalCheckIncludeMembership = (checked: boolean) => {
	logEvent('Tonal Variant check add 8+ Membership', {
		checked: checked,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amOpenMembershipModal = () => {
	logEvent('Tonal Variant open 8+ Membership modal', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCloseMembershipModal = () => {
	logEvent('Tonal Variant close 8+ Membership modal', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickMembershipModalDrawer = (id: string) => {
	logEvent('Click Membership Modal Drawer', {
		id: id,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickMembershipInModal = (membership: string) => {
	logEvent('Click Membership In Modal', {
		membership: membership,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSelectedVariant = (variant: string) => {
	logEvent('Selected Variant', {
		variant,
	})
}

export const amSelectedProduct = (productName: string, productId: number | string) => {
	logEvent('Selected Product', {
		productName,
		productId,
	})
}

export const amSelectedMembership = (productName: string, productId: number | string) => {
	logEvent('Selected Membership', {
		productName,
		productId,
	})
}

export const amSelectedBundleUpsell = (productName: string, productId: number | string) => {
	logEvent('Selected Bundle Upsell', {
		productName,
		productId,
	})
}

export const amSelectedMattress = (productName: string, productId: number | string) => {
	logEvent('Selected Mattress', {
		productName,
		productId,
	})
}

export const amSelectDropdown = (dropdownValue: string) => {
	logEvent('Selected Dropdown', {
		dropdownValue,
	})
}
export const amChangePlanCheckbox = (plan: string) => {
	logEvent('Plan checkbox selected', {
		Title: 'Switched plan',
		Action: 'Clicked plan: ' + plan,
		'Source Page URL': formatURL(window.location.href),
	})
}

export const amOpenSPFCompare = () => {
	logEvent('Open SPF Compare', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCloseSPFCompare = () => {
	logEvent('Close SPF Compare', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amOpenSPFFeatures = () => {
	logEvent('Open SPF Features', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCloseSPFFeatures = () => {
	logEvent('Close SPF Features', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amShowStickyATC = () => {
	logEvent('Sticky ATC Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amHideStickyATC = () => {
	logEvent('Sticky ATC Hid', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amShowedTooltip = (id: string) => {
	logEvent('Showed Tooltip', {
		URL: formatLink(formatURL(window.location.href)),
		product: id,
	})
}

export const amHidTooltip = (id: string) => {
	logEvent('Hid Tooltip', {
		URL: formatLink(formatURL(window.location.href)),
		product: id,
	})
}

export const amClickedTooltipButton = (id: string, minus: boolean) => {
	logEvent('Clicked Tooltip Button', {
		URL: formatLink(formatURL(window.location.href)),
		id: id,
		minus: minus.toString(),
	})
}

export const amEightBallEnteredEmail = (email: string) => {
	logEvent('8Ball Entered Email', {
		URL: formatLink(formatURL(window.location.href)),
		email: email,
	})
}

export const amEightBallClickedBack = () => {
	logEvent('8Ball Click Back', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEightBallTap = () => {
	logEvent('8Ball Tap', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickMarker = (location: string) => {
	logEvent('Click Map Marker', {
		URL: formatLink(formatURL(window.location.href)),
		location: location,
	})
}

export const amScrollMarker = (percentage: number) => {
	logEvent('Hit Scroll Marker', {
		URL: formatLink(formatURL(window.location.href)),
		percentage: percentage,
	})
}

export const amExpandedFooterSection = (header: string) => {
	logEvent('Expanded Footer Section', {
		URL: formatLink(formatURL(window.location.href)),
		header,
	})
}

export const amClosedFooterSection = (header: string) => {
	logEvent('Expanded Footer Section', {
		URL: formatLink(formatURL(window.location.href)),
		header,
	})
}

export const amOpenSelectPlanModal = () => {
	logEvent('Open Select Plan Cart Modal', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCloseSelectPlanModal = () => {
	logEvent('Close Select Plan Cart Modal', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amAppError = (message: string) => {
	logEvent('Generic website error', {
		URL: formatLink(formatURL(window.location.href)),
		message: message,
	})
}

export const amAppCheckoutError = (message: string) => {
	logEvent('Checkout failed', {
		URL: formatLink(formatURL(window.location.href)),
		message: message,
	})
}

export function emailCapShown() {
	try {
		const properties = {
			emailCapType: `Blog`,
			location: 'React Modal',
			captureLocation: formatURL(window.location.href),
		}
		logEvent('Blog - Email cap display', properties)
	} catch (e) {
		console.log(e)
	}
}

export const amSelectPaymentMethod = (method: string) => {
	logEvent('Checkout - Payment Select', {
		URL: formatLink(formatURL(window.location.href)),
		method: method,
	})
}

export const amInitiatedPayment = (method: string) => {
	logEvent('Checkout - Payment Initiated', {
		URL: formatLink(formatURL(window.location.href)),
		method: method,
	})
}

export const amCompletePaymentMethod = (method: string) => {
	logEvent('Checkout - Payment Complete', {
		URL: formatLink(formatURL(window.location.href)),
		method: method,
	})
}

export const amCheckoutError = (error: string, errorDetails: any, customerID: string, data: any) => {
	logEvent('Checkout - Error', {
		URL: formatLink(formatURL(window.location.href)),
		error,
		errorDetails,
		customerID,
		data,
	})
}

export const amEnterPromoCode = (code: string) => {
	logEvent('Checkout - Enter Promo Code', {
		URL: formatLink(formatURL(window.location.href)),
		code,
	})
}

export const amExitIntentModalShown = () => {
	logEvent('Exit Intent Modal Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amExitIntentEmailCapture = (email: string) => {
	logEvent('Exit Intent Email Capture', {
		URL: formatLink(formatURL(window.location.href)),
		email,
	})
}

export const amExitIntentSMSCapture = (sms: string) => {
	logEvent('Exit Intent SMS Capture', {
		URL: formatLink(formatURL(window.location.href)),
		sms,
	})
}

export const amViewedStory = (story: number) => {
	logEvent('Viewed Story', {
		URL: formatLink(formatURL(window.location.href)),
		story,
	})
}

export const amGoToMemberWebviewCheckout = () => {
	logEvent('Go to member webview checkout', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCompleteWebviewPurchase = (orderID: string) => {
	logEvent('Complete webview purchase', {
		URL: formatLink(formatURL(window.location.href)),
		orderID,
	})
}

export const amShowSubscriptionPortalLogin = (data: any) => {
	logEvent('Subscription portal - login show', {
		URL: formatLink(formatURL(window.location.href)),
		...data,
	})
}

export const amShowSubscriptionPortalLoginClick = () => {
	logEvent('Subscription portal - login click', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscriptionPortalLoginSuccess = () => {
	logEvent('Subscription portal - login successful', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscriptionPortalRedirectPaymentMethod = (email: string) => {
	logEvent('Subscription portal - redirect to payment method', {
		URL: formatLink(formatURL(window.location.href)),
		email,
	})
}

export const amSubscriptionPortalDataLoaded = (email: string) => {
	logEvent('Subscription portal - data loaded', {
		URL: formatLink(formatURL(window.location.href)),
		email,
	})
}

export const amSubscriptionPortalPaymentMethodsDataLoaded = () => {
	logEvent('Subscription portal - payment methods loaded', {
		email: sessionStorage.getItem('customerEmail'),
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscriptionPortalAutopilotCancelled = (plan: string) => {
	logEvent('Subscription portal - autopilot cancelled', {
		email: sessionStorage.getItem('customerEmail'),
		URL: formatLink(formatURL(window.location.href)),
		plan,
	})
}

export const amSubscriptionPortalAutopilotPlanClicked = (plan: string) => {
	logEvent('Subscription portal - autopilot plan selected', {
		email: sessionStorage.getItem('customerEmail'),
		URL: formatLink(formatURL(window.location.href)),
		plan,
	})
}

export const amSubscriptionPortalPaymentMethodDefault = (isDefaultCard: boolean) => {
	logEvent('Subscription portal - payment method set default: ' + isDefaultCard, {
		email: sessionStorage.getItem('customerEmail'),
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscriptionPortalSubmitCardInfo = (email: string) => {
	logEvent('Subscription portal - added new credit card', {
		email: email,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEcapSubmitError = (error: string) => {
	logEvent('Email Cap - submit error', {
		URL: formatLink(formatURL(window.location.href)),
		error,
	})
}

export const amSwipeImageCarousel = (direction: string, id: string) => {
	logEvent('Swipe Image Carousel', {
		direction,
		id,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amChangeSlideImageCarousel = (id: string, index: number) => {
	logEvent('Carousel change slide', {
		id,
		slide: index,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSeenProductBreakdown = () => {
	logEvent('Product Breakdown - Seen', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTrackBaseSelected = () => {
	logEvent('Base upgrade selected', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTrackBaseAddedToCheckout = () => {
	logEvent('Base upgrade added to checkout', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickPromoBar = () => {
	logEvent('Promo Bar Checkout Click', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amWebShare = (type: string, ageRange: string, avatar: string) => {
	logEvent('Web Share', {
		type,
		ageRange,
		avatar,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amShareViaApp = (type: string, ageRange: string, avatar: string) => {
	logEvent('App Share', {
		type,
		ageRange,
		avatar,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEOYSeek = (type: 'forward' | 'backward') => {
	logEvent('EOY Seek', {
		type,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEOYLabelView = (label: string) => {
	logEvent('EOY Label View', {
		label,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEOYLoadData = () => {
	logEvent('EOY Load Data', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEOYLoadDataError = () => {
	logEvent('EOY Load Data Error', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEOYLoadDataNotEnoughData = () => {
	logEvent('EOY Load Data Not Enough Data', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amShowAddressValidationModal = (data: AddressVerificationData) => {
	logEvent('Show Address Validation Modal', {
		URL: formatLink(formatURL(window.location.href)),
		verdict: data.verdict.action,
		data,
	})
}

export const amCheckoutWithFragile = ({ size, currentPromoCode }: { size: string; currentPromoCode: string }) => {
	logEvent('Checkout with fragile', {
		URL: formatLink(formatURL(window.location.href)),
		size,
		currentPromoCode,
	})
}

export const amShownRentalExperience = () => {
	logEvent('Shown Rental Experience', {
		URL: formatLink(formatURL(window.location.href)),
	})
}
